import { Box } from "@material-ui/core";
import { useStyles } from "./styles";
import { FC } from "react";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import Loader from "router/components/Loader";
import { AmznScoreTable } from "components/tables/AmznScoreTables/AmznScoreTable";
interface PropsType {}

const AmznScoreboardPage: FC<PropsType> = () => {
  const classes = useStyles();

  const { loadingData } = useTypeSelectors((state) => state.actTable);

  return (
    <Box data-testid="testAmznScoreboardPage" className={classes.root}>
      <Loader isLoading={loadingData} />
      <AmznScoreTable />
    </Box>
  );
};

export default AmznScoreboardPage;
