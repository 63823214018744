import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { width: "100%", padding: theme.spacing(0.5), boxSizing: "border-box" },
  head: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: { display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" },
}));
