import { Box, Typography } from "@material-ui/core";
import { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";
import { DashboardRecord } from "types/DashboardRecord";

export type TotalScoreType = "Fair" | "Great" | "Fantastic" | "Fantastic Plus" | "Unknown";

interface TotalScoreProps {
  score: TotalScoreType;
}

export const TotalScore: FC<TotalScoreProps> = ({ score }) => {
  const classes = useStyles();
  return (
    <Box data-testid="testTotalScore" className={classes.date}>
      <Typography style={{ marginRight: "5px" }}>Total Score:</Typography>
      <Typography style={{ color: getColorIndicator(score), fontWeight: 700 }}>{score}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  date: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
  },
}));

export const calculateTotalScore = (list: DashboardRecord[]) => {
  let result: any = "Unknown";
  if (!list.length) return result;
  else {
    const scores: {[key: string]: number} = { Unknown: 0 };
    list.forEach((el) => {
      if (el?.report?.week_total_score) {
        if (scores[el.report.week_total_score]) {
          scores[el.report.week_total_score] = scores[el.report.week_total_score] + 1;
        } else {
          scores[el.report.week_total_score] = 1;
        }
      }
    });
    Object.keys(scores).forEach((key) => {
      if (scores[key] > scores[result]) result = key;
    });
    return result;
  }
};

const getColorIndicator = (totalScore: TotalScoreType) => {
  switch (totalScore) {
    case "Fair":
      return Colors.RED;
    case "Great":
      return Colors.YELLOW;
    case "Fantastic":
      return Colors.GREEN;
    case "Fantastic Plus":
      return Colors.BLUE;
    default:
      return "currentColor";
  }
};
