import { FC, ReactNode, useState } from "react";
import { ScorecardModals } from "components/modals/ScorecardModals";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { IconOpenButton } from "./Buttons";

interface ScorecardOpenButtonProps {
  children?: ReactNode;
}

export const ScorecardOpenButton: FC<ScorecardOpenButtonProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <IconOpenButton
          Icon={FullscreenIcon}
          onClick={onOpen}
      />
      <ScorecardModals open={open} onClose={onClose} children={children} />
    </>
  );
};
