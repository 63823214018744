import { ReducersInterfaceAction } from "types/Reducers";
import { WeightDataConstants } from "../constants/constants";
import { WeightsState } from "../types/Weights";
import { initialWeightsState } from "store/initialStates";

export const weightsDataReducer = (
  state = initialWeightsState,
  action: ReducersInterfaceAction,
): WeightsState => {
  switch (action.type) {
    case WeightDataConstants.WEIGHT_DATA_LOADING:
      return {
        ...state,
        weightsLoading: true,
      };
    case WeightDataConstants.WEIGHT_DATA_SUCCESS:
      return {
        ...state,
        weightsLoading: false,
        weightsData: action.payload,
      };
    case WeightDataConstants.WEIGHT_DATA_ERROR:
      return {
        ...state,
        weightsLoading: false,
      };
    default:
      return state;
  }
};
