import { makeStyles } from "@material-ui/core/styles";

export const useDialogStyles = makeStyles((theme) => ({
  dialogActions: {
    "&.MuiDialogActions-root": {
      padding: "32px 24px",
      gap: "20px",
    },
  },
  actionButton: {
    padding: theme.spacing(1.5, 2),
    borderRadius: 100,
    width: "50%",
    fontSize: 16,
  },
}));
