import toast from "react-hot-toast";
import { Colors } from "../constants/constants";
import theme from "../theme";

export const customToast = (type: string, message: string) => {
  if (type === "error") {
    toast.error(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        background: Colors.RED,
        color: Colors.WHITE,
        fontWeight: 700,
      },
      iconTheme: {
        primary: "#000",
        secondary: Colors.WHITE,
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  }
  if (type === "success") {
    toast.success(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        background: Colors.SUCCESS_TOAST_GREEN,
        color: Colors.WHITE,
        fontWeight: 700,
      },
      iconTheme: {
        primary: "#000",
        secondary: Colors.WHITE,
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  }
  if (type === "loading") {
    toast.loading(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        background: Colors.WHITE,
        fontWeight: 700,
      },
      iconTheme: {
        primary: "#000",
        secondary: Colors.WHITE,
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  }
  if (type === "warning") {
    toast.error(message, {
      duration: 4000,
      position: "bottom-right",
      style: {
        background: theme.palette.warning.main,
        color: Colors.WHITE,
        fontWeight: 700,
      },
      iconTheme: {
        primary: "#000",
        secondary: Colors.WHITE,
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  }
};
