import { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

interface PageWrapperProps {
  children: ReactNode;
}

export const PageWrapper: FC<PageWrapperProps> = ({ children }) => {
  const classes = useStyles();
  return <Box data-testid="testPageWrapper" className={classes.root}>{children}</Box>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxSizing: "border-box",
    height: "calc(100vh - 170px)",
  },
}));
