import api, { apiErrorHandler } from "api";
import { Dispatch } from "redux";
import { DashboardConstants } from "constants/constants";
import { customToast } from "hooks/toast";
import { DashboardSettingsType } from "types/DashboardRecord";
import { RootState } from "reducers/rootReducer";

export const DashboardDataListRequest =
  (
    page: number,
    page_size: number,
    ordering: string,
    station: number | string,
    week?: number,
    year?: number,
    search?: string
  ) =>
  async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: DashboardConstants.DASHBOARD_ERROR });
    dispatch({ type: DashboardConstants.DASHBOARD_LOADING });
    api
      .getDashboardInfo({ page, page_size, ordering, station, week, year, search })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: DashboardConstants.DASHBOARD_DATA_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const handleChangeDashboardModalState = () => async (dispatch: Dispatch) => {
  dispatch({ type: DashboardConstants.DASHBOARD_MODAL_STATE });
};

export const DashboardSendMessage = () => async (dispatch: Dispatch) => {
  const onError = () => dispatch({ type: DashboardConstants.DASHBOARD_ERROR });
  api
    .sendNotificationDrivers()
    .then((res) => {
      if (res.data) {
        customToast("success", "Message was sended!");
        dispatch({
          type: DashboardConstants.DASHBOARD_MESSAGE_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(apiErrorHandler(onError));
};

export const DashboardUpdateSettings = (data: DashboardSettingsType) => (dispatch: Dispatch) => {
  dispatch({
    type: DashboardConstants.DASHBOARD_UPDATE_SETTINGS,
    payload: data,
  });
};

export const DashboardUpdateDateSettings = (data: {
  week?: string;
  month?: string;
  year?: string;
  day?: string;
}) => (dispatch: Dispatch, getStore: () => RootState) => {
  const { dashboardScoreSettings } = getStore()?.dashboard;
  dispatch({
    type: DashboardConstants.DASHBOARD_UPDATE_SETTINGS,
    payload: {labelDate: { ...dashboardScoreSettings?.labelDate, ...data}},
  });
};

export const DashboardChangeRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: DashboardConstants.DASHBOARD_CHANGE_ROWS_PER_PAGE,
    payload: data,
  });
};
