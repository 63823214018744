import { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { tableRowsCreate } from "components/tables/tableRows";
import { ReportRow } from "components/tables/ReportTables/ReportRow";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { Box, Typography } from "@material-ui/core";
import { useReportStyles } from "./styles";
import { getFilteredFormData } from "utils/filters";
import { useDispatch } from "react-redux";
import { removeReportRecordAction, updateReportRecordAction } from "actions/daDailyReportsAction";
import { ReportRecord } from "types/Report";
import { Colors } from "constants/constants";

interface PropsType {
  searchValue: string;
}

const sortByCreatedAt = (a: ReportRecord, b: ReportRecord) => (a.created < b.created ? -1 : 1);

export const ReportTable = ({ searchValue }: PropsType) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const { currentReport, reportListLoading } = useTypeSelectors((state) => state.dasBoard);
  const [filteredData, setFilteredData] = useState<ReportRecord[]>([]);

  const handleDeleteRow = (recordId: number) => {
    if (recordId) {
      dispatch(removeReportRecordAction(recordId));
    }
  };

  const handleUpdateRow = (recordId: number, data: ReportRecord) => {
    if (recordId) {
      dispatch(updateReportRecordAction(recordId, data));
    }
  };

  useEffect(() => {
    setFilteredData(getFilteredFormData({ searchValue, currentReport }));
  }, [currentReport, searchValue]);

  if (reportListLoading) return null;
  return (
    <Box className={classes.root} id="App">
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableCell
              key={"delete"}
              className={`${classes.tableCellSmall}`}
              style={{ width: 50 }}
            />
            <TableCell
              key={"id"}
              className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}
              style={{ width: 50 }}
            >
              <Typography className={classes.fontWeight}>#</Typography>
            </TableCell>
            {tableRowsCreate.slice(2, tableRowsCreate.length).map((TableRows) =>
              TableRows.field === "big" ? (
                <TableCell
                  key={TableRows.id}
                  className={`${classes.tableCellAlign}
                    ${
                      TableRows.id === "DA 1"
                        ? classes.tableCellBigSticky
                        : TableRows.id === "Comment"
                          ? classes.tableCellComment
                          : classes.tableCellBig
                    }`}
                >
                  <Typography className={classes.fontWeight}>{TableRows.label}</Typography>
                </TableCell>
              ) : (
                <TableCell
                  key={TableRows.id}
                  className={`${classes.tableCellSmall} ${classes.tableCellAlign}`}
                  style={{
                    ...(TableRows.id === "COD" && {
                      background: "#e0e0e0",
                      borderLeft: `1px solid ${Colors.MAIN}`,
                    }),
                  }}
                >
                  <Typography className={classes.fontWeight}>{TableRows.label}</Typography>
                </TableCell>
              ),
            )}
          </TableHead>
          <TableBody className={classes.body}>
            {searchValue.length
              ? filteredData
                  .sort(sortByCreatedAt)
                  .map((row: ReportRecord, index: number) => (
                    <ReportRow
                      key={row.id}
                      reportRecord={row}
                      index={currentReport.findIndex((el) => row.id === el.id)}
                      onDelete={handleDeleteRow}
                      onUpdate={handleUpdateRow}
                    />
                  ))
              : currentReport
                  .sort(sortByCreatedAt)
                  .map((row: ReportRecord, index: number) => (
                    <ReportRow
                      key={row.id}
                      reportRecord={row}
                      index={index}
                      onDelete={handleDeleteRow}
                      onUpdate={handleUpdateRow}
                    />
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
