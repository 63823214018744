import { ReducersInterfaceAction } from "types/Reducers";
import { AmazonConstants } from "../constants/constants";
import { AmazonState } from "../types/AmazonRecord";
import { initialAmazonState } from "store/initialStates";

export const amazonReportsReducer = (
  state = initialAmazonState,
  action: ReducersInterfaceAction,
): AmazonState => {
  switch (action.type) {
    case AmazonConstants.AMAZON_HISTORY_LOADING:
      return {
        ...state,
        amazonHistoryLoading: true,
      };
    case AmazonConstants.CSV_HISTORY_LOADING:
      return {
        ...state,
        csvHistoryLoading: true,
      };
    case AmazonConstants.AMAZON_HISTORY_SUCCESS:
      return {
        ...state,
        amazonHistoryLoading: false,
        amazonHistoryDataList: action.payload.results,
        importHistoryDataListCount: action.payload.count,
      };
    case AmazonConstants.AMAZON_HISTORY_ERROR:
      return {
        ...state,
        amazonHistoryLoading: false,
      };
    case AmazonConstants.CSV_HISTORY_SUCCESS:
      return {
        ...state,
        csvHistoryLoading: false,
        csvHistoryDataList: action.payload.results,
        importHistoryDataListCount: action.payload.count,
      };
    case AmazonConstants.CSV_HISTORY_ERROR:
      return {
        ...state,
        csvHistoryLoading: false,
      };
    case AmazonConstants.IMPORT_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        importPDFTableRowsPerPage: action.payload,
      };
    case AmazonConstants.IMPORT_PAGE_TYPE:
      return {
        ...state,
        isAmazonPage: action.payload,
      };
    default:
      return state;
  }
};
