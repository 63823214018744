import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import Person from "@material-ui/icons/Person";
import ReplayIcon from "@material-ui/icons/Replay";
import ShopIcon from "@material-ui/icons/Shop";
import { DeliveredReturnedType, RoutesActiveDaysType } from "types/DashboardRecord";
import { useTypeSelectors } from "./useTypeSelectors";

interface getDashboardScoresType {
  defaultStation: string | undefined;
  defaultCsvDeliveredData: DeliveredReturnedType | null;
  defaultDeliveredData: DeliveredReturnedType | null;
  defaultRoutesData: RoutesActiveDaysType | null;
}

export const useDashboardScores = ({
  defaultStation,
  defaultCsvDeliveredData,
  defaultDeliveredData,
  defaultRoutesData,
}: getDashboardScoresType) => {
  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);

  const conditionLabelDateDisplay =
    dashboardScoreSettings?.currentDateTypeLabelDateType === "week"
      ? `Week ${dashboardScoreSettings?.labelDate?.week}`
      : dashboardScoreSettings?.currentDateTypeLabelDateType === "day"
        ? `${dashboardScoreSettings?.labelDate?.day}`
        : dashboardScoreSettings?.currentDateTypeLabelDateType === "month"
          ? `Month ${dashboardScoreSettings?.labelDate?.month}`
          : `Year ${dashboardScoreSettings?.labelDate?.year}`;

  const mockedInfoData: any = [
    {
      title: "# Of Routes",
      Icon: DirectionsCarIcon,
      currentValue: defaultRoutesData?.current,
      pastValue: defaultRoutesData?.prev,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of deliveries",
      Icon: ShopIcon,
      currentValue:
        defaultStation === "1"
          ? defaultCsvDeliveredData?.actual?.delivered
          : defaultDeliveredData?.actual?.delivered,
      pastValue:
        defaultStation === "1"
          ? defaultCsvDeliveredData?.prev?.delivered
          : defaultDeliveredData?.prev?.delivered,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of returns",
      Icon: ReplayIcon,
      currentValue:
        defaultStation === "1"
          ? defaultCsvDeliveredData?.actual?.returned
          : defaultDeliveredData?.actual?.returned,
      pastValue:
        defaultStation === "1"
          ? defaultCsvDeliveredData?.prev?.returned
          : defaultDeliveredData?.prev?.returned,
      currentDate: conditionLabelDateDisplay,
      ...(defaultStation === "1" && {
        returnDetails: {
          business_closed: defaultCsvDeliveredData?.actual?.business_closed,
          unsafe_due_to_dog: defaultCsvDeliveredData?.actual?.unsafe_due_to_dog,
          unable_to_locate: defaultCsvDeliveredData?.actual?.unable_to_locate,
          unable_to_access: defaultCsvDeliveredData?.actual?.unable_to_access,
          out_of_drive_time: defaultCsvDeliveredData?.actual?.out_of_drive_time,
          other: defaultCsvDeliveredData?.actual?.other,
          no_secure_location: defaultCsvDeliveredData?.actual?.no_secure_location,
          customer_unavailable: defaultCsvDeliveredData?.actual?.customer_unavailable,
        },
      }),
    },
    {
      title: "Number of Standard",
      Icon: Person,
      currentValue: defaultDeliveredData?.actual?.number_of_standart,
      pastValue: defaultDeliveredData?.prev?.number_of_standart,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of Rescue",
      Icon: Person,
      currentValue: defaultDeliveredData?.actual?.number_of_rescue,
      pastValue: defaultDeliveredData?.prev?.number_of_rescue,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of Standby",
      Icon: Person,
      currentValue: defaultDeliveredData?.actual?.number_of_standby,
      pastValue: defaultDeliveredData?.prev?.number_of_standby,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of Training",
      Icon: Person,
      currentValue: defaultDeliveredData?.actual?.number_of_training,
      pastValue: defaultDeliveredData?.prev?.number_of_training,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of Quality",
      Icon: Person,
      currentValue: defaultDeliveredData?.actual?.number_of_quality,
      pastValue: defaultDeliveredData?.prev?.number_of_quality,
      currentDate: conditionLabelDateDisplay,
    },
    {
      title: "Number of Special",
      Icon: Person,
      currentValue: defaultDeliveredData?.actual?.number_of_special,
      pastValue: defaultDeliveredData?.prev?.number_of_special,
      currentDate: conditionLabelDateDisplay,
    },
  ];

  return {
    mockedInfoData,
  };
};
