import { FleetSettingsDataConstants } from "constants/constants";
import { initialFleetSettingsState } from "store/initialStates";
import { FleetSettingsState } from "types/FleetSettings";
import { ReducersInterfaceAction } from "types/Reducers";

export const fleetSettingsReducer = (
  state = initialFleetSettingsState,
  action: ReducersInterfaceAction,
): FleetSettingsState => {
  switch (action.type) {
    case FleetSettingsDataConstants.FLEET_SETTINGS_LOADING:
      return {
        ...state,
        isFleetSettingsPending: true,
      };
    case FleetSettingsDataConstants.FLEET_SETTINGS_DATA:
      return {
        ...state,
        isFleetSettingsPending: false,
        reportListCount: action.payload.count,
        fleetSettingsData: action.payload.results,
      };
    case FleetSettingsDataConstants.FLEET_SETTINGS_CREATE:
    case FleetSettingsDataConstants.FLEET_SETTINGS_UPDATE_STATUS:
      return {
        ...state,
        isFleetSettingsPending: false,
        fleetSettingsData: action.payload,
      };
    case FleetSettingsDataConstants.FLEET_SETTINGS_ERROR:
      return {
        ...state,
        isFleetSettingsPending: false,
      };
    default:
      return state;
  }
};
