import api, { apiErrorHandler } from "api";
import {
  AuthConstants,
  DasBoardConstants,
  DashboardConstants,
  WeightDataConstants,
} from "constants/constants";
import { localUserService } from "utils/localStorage";
import { Dispatch } from "redux";
import { WeightParams } from "api/types";
import { RootState } from "reducers/rootReducer";

export const updateWeightsAction =
  (data: WeightParams) => (dispatch: Dispatch, getState: () => RootState) => {
    const { weightsData } = getState().weightsData;
    api
      .updateWeights(data)
      .then(() => {
        dispatch({
          type: WeightDataConstants.WEIGHT_DATA_SUCCESS,
          payload: {
            ...weightsData,
            display_options: {
              current_week_tier: data.CURRENT_WEEK_TIER_VISIBLE,
              average_week6_tier: data.AVERAGE_WEEK6_TIER_VISIBLE,
              tenured: data.TENURED_VISIBLE,
              cc: data.CC_VISIBLE,
              dar: data.DAR_VISIBLE,
              dcr: data.DCR_VISIBLE,
              morning_checklist: data.MC_VISIBLE,
              ontime_attendance: data.OA_VISIBLE,
              pod: data.POD_VISIBLE,
              sc: data.SC_VISIBLE,
              rescue: data.RESCUES_VISIBLE,
              ced: data.CED_VISIBLE,
              high_performer_in_weeks: data.HIGH_PERFORMER_IN_WEEKS_VISIBLE,
            },
            titles: {
              name: data.NAME_TITLE,
              transporter_id: data.TRANSPORTER_ID_TITLE,
              points_total: data.SOPC_TITLE,
              quadrant: data.QUADRANT_TITLE,
              current_week_tier: data.CURRENT_WEEK_TIER_TITLE,
              average_week6_tier: data.AVERAGE_WEEK6_TIER_TITLE,
              tenured: data.TENURED_TITLE,
              dcr: data.DCR_TITLE,
              dar: data.DAR_TITLE,
              pod: data.POD_TITLE,
              cc: data.CC_TITLE,
              sc: data.SC_TITLE,
              ced: data.CED_TITLE,
              high_performer_in_weeks: data.HIGH_PERFORMER_IN_WEEKS_TITLE,
              rescue: data.RESCUES_TITLE,
              morning_checklist: data.MC_TITLE,
              ontime_attendance: data.OA_TITLE,
              message: data.MESSAGE_TITLE,
            },
          },
        });
      })
      .catch(
        apiErrorHandler(() => {
          dispatch({ type: WeightDataConstants.WEIGHT_DATA_ERROR });
        })
      );
  };

export const initializeApp = () => async (dispatch: Dispatch) => {
  dispatch({ type: AuthConstants.USER_DATA_LOADING });
  dispatch({ type: DashboardConstants.DASHBOARD_DATE_LOADING });
  dispatch({ type: WeightDataConstants.WEIGHT_DATA_LOADING });
  dispatch({ type: DasBoardConstants.DAS_FLEET_SIZE_PENDING });
  dispatch({ type: DashboardConstants.DASHBOARD_INITIALIZE_LABEL_DATE_TYPE });
  await api
    .getCurrentUser()
    .then((res) => {
      if (res.data) {
        localUserService.setPermissions(res.data?.role || 1);
        dispatch({
          type: AuthConstants.USER_DATA_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(
      apiErrorHandler(() => {
        dispatch({ type: AuthConstants.USER_DATA_ERROR });
      })
    );

  await api
    .getDashboardWeek()
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DashboardConstants.DASHBOARD_DATE_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(apiErrorHandler(() => dispatch({ type: DashboardConstants.DASHBOARD_DATE_ERROR })));

  await api
    .getWeights()
    .then((res) => {
      if (res.data) {
        dispatch({
          type: WeightDataConstants.WEIGHT_DATA_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(apiErrorHandler(() => dispatch({ type: WeightDataConstants.WEIGHT_DATA_ERROR })));

  await api
    .getFleetSizes()
    .then((res) => {
      if (res.data) {
        dispatch({
          type: DasBoardConstants.DAS_FLEET_SIZE_SUCCESS,
          payload: res.data?.results,
        });
      }
    })
    .catch(apiErrorHandler(() => dispatch({ type: DasBoardConstants.DAS_FLEET_SIZE_ERROR })));
};
