import { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Colors, ScorecardConstants } from "constants/constants";
import { handleGetReturned } from "../requests";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { MonthSelect } from "components/elements/MonthSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { YearSelect } from "components/elements/YearSelect";
import { PeriodSelect } from "components/elements/PeriodSelect";
import { StationSelect } from "components/elements/StationSelect";
import { SearchTypeSelect } from "components/elements/SearchTypeSelect";
import { useStyles } from "./styles";
import { ScorecardOpenButton } from "components/buttons/ScorecardOpenButton";
import { ScorecardCompareButton } from "components/buttons/ScorecardCompareButton";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useDispatch } from "react-redux";
import {
  ScorecardExternalSetDate,
  ScorecardSetSelectType,
  ScorecardSetStation,
} from "actions/scorecardActions";
import { DatePickWithArrow } from "components/elements/DatePickWithArrow";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const Returned = ({ withModal = true, withCompare = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { externalSettingActive, externalSettings, returnSettings } = useTypeSelectors(
    (state) => state.scoreCardSettings
  );
  const defaultStationCondition = userData?.station_spots.some(
    (el: { station: { title: string; id: number } }) => el.station.id === 1
  )
    ? 1
    : userData?.station_spots[0]?.station?.id;

  const [compareStation, setCompareStation] = useState(`${defaultStationCondition}`);
  const [requestData, setRequestData] = useState({});

  const handleChangeStation = (data: string) =>
    !withCompare
      ? setCompareStation(data)
      : dispatch(ScorecardSetStation(data, ScorecardConstants.RETURN_SET_CURRENT_STATION));

  const handleChangeSelectType = (select: string) =>
    dispatch(ScorecardSetSelectType(select, ScorecardConstants.RETURN_SET_SELECT_TYPE));
  const handleChangeWeek = (week: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...returnSettings?.date, week },
        ScorecardConstants.RETURN_SET_DATE
      )
    );
  const handleChangeMonth = (month: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...returnSettings?.date, month },
        ScorecardConstants.RETURN_SET_DATE
      )
    );
  const handleChangeYear = (year: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...returnSettings?.date, year },
        ScorecardConstants.RETURN_SET_DATE
      )
    );
  const handleChangeStartDate = (startDate: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...returnSettings?.date, startDate: startDate },
        ScorecardConstants.RETURN_SET_DATE
      )
    );
  const handleChangeEndDate = (endDate: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...returnSettings?.date, endDate: endDate },
        ScorecardConstants.RETURN_SET_DATE
      )
    );

  const handleChangeDayDate = (date: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...returnSettings?.date, day: date },
        ScorecardConstants.RETURN_SET_DATE
      )
    );

  const {
    week,
    month,
    year,
    start_date,
    end_date,
    selectType,
    date,
    onTypeChange,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onYearReduce,
    onYearIncrease,
    dateConditionYear,
    onStartDateChange,
    onEndDateChange,
    onDateChange,
    onDateChangeByDay,
  } = useDateWeekSelector({
    defaultWeek: returnSettings?.date?.week,
    defaultMonth: returnSettings?.date?.month,
    defaultYear: returnSettings?.date?.year,
    defaultSelectType: returnSettings?.selectType,
    defaultDateEnd: returnSettings?.date?.endDate,
    defaultDateStart: returnSettings?.date?.startDate,
    onSelectTypeSet: handleChangeSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
    onDateStartSet: handleChangeStartDate,
    onDateEndSet: handleChangeEndDate,
    onDateSet: handleChangeDayDate,
  });

  const conditionLocalStation = !withCompare ? compareStation : returnSettings?.station;

  useEffect(() => {
    if (
      (conditionLocalStation && (month || week || year || (end_date && start_date) || date)) ||
      (externalSettings?.station && externalSettings?.selectType && externalSettings?.date)
    ) {
      const params = externalSettingActive
        ? {
            station: externalSettings?.station,
            ...(externalSettings?.selectType === "month" && {
              month: +externalSettings?.date?.month,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "week" && {
              week: +externalSettings?.date?.week,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "day" && { day: externalSettings?.date?.day }),
          }
        : {
            station: conditionLocalStation,
            ...(selectType === "month" && { year, month }),
            ...(selectType === "week" && { year, week }),
            ...(selectType === "annual" && { year }),
            ...(selectType === "period" && { start_date, end_date }),
            ...(selectType === "day" && { day: date }),
          };
      handleGetReturned(params, setRequestData);
    }
  }, [
    returnSettings?.station,
    month,
    week,
    selectType,
    year,
    end_date,
    start_date,
    date,
    externalSettingActive,
    externalSettings?.station,
    externalSettings?.selectType,
    externalSettings?.date,
    conditionLocalStation,
  ]);

  const result = Object.entries(requestData);
  const labels = result
    .filter((el) => el[0] !== "delivered" && el[0] !== "percent" && el[0] !== "total_returned")
    .map((el) => el[0]);

  const totalReturn = result.filter((el) => el[0] === "total_returned")?.[0]?.[1];
  const percentReturn = result.filter((el) => el[0] === "percent")?.[0]?.[1];
  const percentCondition = typeof percentReturn === "number" ? percentReturn.toFixed(2) : 0;

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Counter",
        data: result
          .filter(
            (el) => el[0] !== "delivered" && el[0] !== "percent" && el[0] !== "total_returned"
          )
          .map((el) => (el[1] === null ? 0 : el[1])),
        backgroundColor: [
          Colors.PASTEL_BLUE,
          Colors.PASTEL_GREEN,
          Colors.PASTEL_ORANGE,
          Colors.PASTEL_PINK,
          Colors.PASTEL_RED,
          Colors.PASTEL_VIOLET,
          Colors.PASTEL_YELLOW,
          Colors.PASTEL_TURQUOISE,
          Colors.PASTEL_SAND,
          Colors.PASTEL_PINE_TREE,
        ],
        minBarLength: 3,
        barThickness: 15,
        borderRadius: 25,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: false,
          text: "Value",
        },
        min: 0,
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
      x: {
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: `Total return ${totalReturn} | % of return ${percentCondition}%`,
      },
    },
  };

  return (
    <Box className={classes.root}>
      {!externalSettingActive && (
        <Box className={classes.head}>
          <Box className={classes.box}>
            <StationSelect
              value={!withCompare ? compareStation : returnSettings?.station}
              onChange={handleChangeStation}
              stations={userData?.station_spots}
            />
            <SearchTypeSelect
              value={selectType}
              onChange={onTypeChange}
              options={[
                { id: "week", title: "Week" },
                { id: "month", title: "Month" },
                { id: "annual", title: "Annual" },
                { id: "period", title: "Period" },
                { id: "day", title: "Day" },
              ]}
            />
            {selectType === "month" && (
              <MonthSelect
                month={month}
                year={year}
                onNext={onMonthIncrease}
                onPrev={onMonthReduce}
                disabled={dateConditionMonth()}
              />
            )}
            {selectType === "week" && (
              <WeekSelect
                week={week}
                year={year}
                onNext={onWeekIncrease}
                onPrev={onWeekReduce}
                disabled={dateCondition()}
              />
            )}
            {selectType === "annual" && (
              <YearSelect
                year={year}
                onNext={onYearIncrease}
                onPrev={onYearReduce}
                disabled={dateConditionYear()}
              />
            )}
            {selectType === "period" && (
              <PeriodSelect
                startDate={start_date}
                endDate={end_date}
                onStartDateChange={onStartDateChange}
                onEndDateChange={onEndDateChange}
              />
            )}
            {selectType === "day" && (
              <DatePickWithArrow
                value={date}
                onChange={onDateChange}
                changeDay={onDateChangeByDay}
                displayWeek
              />
            )}
          </Box>
          <Box>
            {withCompare && (
              <ScorecardCompareButton
                children={<Returned withModal={false} withCompare={false} />}
                children2={<Returned withModal={false} withCompare={false} />}
              />
            )}
            {withModal && <ScorecardOpenButton children={<Returned withModal={false} />} />}
          </Box>
        </Box>
      )}
      <Typography variant="body2" style={{ width: "100%", textAlign: "center" }}>
        Total return <b>{totalReturn as string}</b> | % of return <b>{percentCondition}%</b>
      </Typography>
      <Bar options={options} data={dataChart} />
    </Box>
  );
};
