import { DrawerComponent } from "components/DrawerComponent";
import { Redirect, Route } from "react-router";
import { localUserService } from "utils/localStorage";

const RouteWithBars = ({
  component: Component,
  permission = 1,
  label,
  permissionKey,
  ...rest
}: any) => {
  const token = localUserService.getAccess();
  const acceptPermission = permissionKey.some((el: number) => el === permission);

  return (
    <Route
      {...rest}
      render={(props) =>
        token && acceptPermission ? (
          <DrawerComponent children={<Component {...props} />} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default RouteWithBars;
