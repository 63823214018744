import api, { apiErrorHandler } from "api";
import { Dispatch } from "redux";
import { AmazonConstants } from "constants/constants";

export const getAmazonHistoryAction =
  (page: number, page_size: number) => async (dispatch: Dispatch) => {
    dispatch({ type: AmazonConstants.AMAZON_HISTORY_LOADING });
    api
      .getAmazonHistory({ page, page_size })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: AmazonConstants.AMAZON_HISTORY_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch(
        apiErrorHandler(() => {
          dispatch({ type: AmazonConstants.AMAZON_HISTORY_ERROR });
        })
      );
  };

export const getCSVHistoryAction =
  (page: number, page_size: number) => async (dispatch: Dispatch) => {
    dispatch({ type: AmazonConstants.CSV_HISTORY_LOADING });
    api
      .getCSVHistory({ page, page_size })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: AmazonConstants.CSV_HISTORY_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch(
        apiErrorHandler(() => {
          dispatch({ type: AmazonConstants.CSV_HISTORY_ERROR });
        })
      );
  };

export const ImportChangeRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: AmazonConstants.IMPORT_TABLE_ROWS_PER_PAGE,
    payload: data,
  });
};

export const ImportPageChangeType = (data: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: AmazonConstants.IMPORT_PAGE_TYPE,
    payload: data,
  });
};
