import { FC, CSSProperties } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface InfoBoxProps {
  label: string;
  value: string | number;
  style?: CSSProperties;
  horizontal?: boolean;
}

export const InfoBox: FC<InfoBoxProps> = ({ label, value, style, horizontal }) => {
  const classes = useStyles();
  return horizontal ? (
    <Box data-testid="testInfoBox" className={classes.wrapper} style={style}>
      <Typography variant={"body1"} className={classes.labelHoriz}>
        <b>{label}:</b>
      </Typography>
      <Typography variant={"body1"} className={classes.valueHoriz}>
        {value || "No data"}
      </Typography>
    </Box>
  ) : (
    <Box style={style}>
      <Typography variant={"body1"} className={classes.label}>
        <b>{label}</b>
      </Typography>
      <Typography variant={"body2"} className={classes.value}>
        {value || "No data"}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  label: {
    display: "block",
    paddingBottom: 2,
    color: theme.palette.primary.main,
  },
  value: {
    display: "block",
    color: theme.palette.primary.light,
  },
  labelHoriz: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
  valueHoriz: {
    color: theme.palette.primary.light,
  },
}));
