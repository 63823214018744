import { FC, useState } from "react";
import { Badge, ButtonProps } from "@material-ui/core";
import { ReportFiltersDrawer } from "components/drawers/ReportFiltersDrawer";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { OpenButton } from "./Buttons";

interface ReportFiltersButtonProps extends ButtonProps {}

export const ReportFiltersButton: FC<ReportFiltersButtonProps> = (props) => {
  const classes = useStyles();
  const { reportListFilters } = useTypeSelectors((state) => state.dasBoard);
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const selectFilterCondition =
    Boolean(reportListFilters?.dateCreate) ||
    Boolean(reportListFilters?.dateFrom) ||
    Boolean(reportListFilters?.dateMonth) ||
    Boolean(reportListFilters?.dateTo) ||
    Boolean(reportListFilters?.dateYear) ||
    Boolean(reportListFilters?.driver) ||
    Boolean(reportListFilters?.shiftDate) ||
    Boolean(reportListFilters?.stationFilter) ||
    Boolean(reportListFilters?.userCreate) ||
    Boolean(reportListFilters?.userEdit) ||
    Boolean(reportListFilters?.week_number) ||
    Boolean(reportListFilters?.missing) ||
    Boolean(reportListFilters?.cod) ||
    Boolean(reportListFilters?.country) ||
    Boolean(reportListFilters?.fleet_group) ||
    Boolean(reportListFilters?.fleet_size) ||
    Boolean(reportListFilters?.fleet_number);

  const selectFilterBadge =
    Number(Boolean(reportListFilters?.dateCreate)) +
    Number(Boolean(reportListFilters?.dateFrom)) +
    Number(Boolean(reportListFilters?.dateMonth)) +
    Number(Boolean(reportListFilters?.dateTo)) +
    Number(Boolean(reportListFilters?.dateYear)) +
    Number(Boolean(reportListFilters?.driver)) +
    Number(Boolean(reportListFilters?.shiftDate)) +
    Number(Boolean(reportListFilters?.stationFilter)) +
    Number(Boolean(reportListFilters?.userCreate)) +
    Number(Boolean(reportListFilters?.userEdit)) +
    Number(Boolean(reportListFilters?.week_number)) +
    Number(Boolean(reportListFilters?.missing)) +
    Number(Boolean(reportListFilters?.cod)) +
    Number(Boolean(reportListFilters?.country)) +
    Number(Boolean(reportListFilters?.fleet_group)) +
    Number(Boolean(reportListFilters?.fleet_size)) +
    Number(Boolean(reportListFilters?.fleet_number));

  return (
    <>
      <Badge
        className={classes.badge}
        badgeContent={selectFilterBadge}
        color="error"
      >
        <OpenButton
          buttonText={"Filters"}
          variant={"outlined"}
          className={classes.buttonClass}
          color={selectFilterCondition ? "secondary" : "primary"}
          onClick={onOpen}
          props={props}
        />
      </Badge>
      <ReportFiltersDrawer open={open} onClose={onClose} />
    </>
  );
};
