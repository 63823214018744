import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Colors } from "constants/constants";
import { FC } from "react";

interface UtilizationVanTableMonthProps {
  data: {
    number: string;
    total_in_weeks: number;
    total_in_month: number;
    type: number;
    weeks: {
      counter: number;
      week: number;
    }[];
  }[];
  date: string;
  station: string | undefined;
}

export const UtilizationVanTableMonth: FC<UtilizationVanTableMonthProps> = ({
  data,
  date,
  station,
}) => {
  return (
    <TableContainer style={{ maxHeight: "90vh", boxSizing: "border-box", padding: "0 10px" }}>
      <Table stickyHeader id="fleetPdf">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={4}>
              <Typography style={{ fontWeight: 600 }}>{station}</Typography>
            </TableCell>
            <TableCell align="right" colSpan={4}>
              <Typography style={{ fontWeight: 600 }}>Month {date}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography style={{ fontWeight: 600, textAlign: "center" }}>Type</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ fontWeight: 600, textAlign: "center" }}>Van</Typography>
            </TableCell>
            {data[0]?.weeks
              ? data[0]?.weeks.map((el) => (
                  <TableCell>
                    <Typography style={{ fontWeight: 600, textAlign: "center" }}>
                      Week {el?.week}
                    </Typography>
                  </TableCell>
                ))
              : []}
            <TableCell>
              <Typography style={{ fontWeight: 600, textAlign: "center" }}>Total</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data
            ? data.map(
                (el) =>
                  el?.total_in_month > 0 && (
                    <TableRow
                      style={{
                        background:
                          el?.total_in_month >= 20 ? Colors.PASTEL_RED : Colors.PASTEL_BLUE,
                        color: "#fff",
                      }}
                    >
                      <TableCell
                        style={{
                          background:
                            Number(el?.type) === 1
                              ? Colors.BLUE
                              : Number(el?.type) === 2
                                ? Colors.PASTEL_PINE_TREE
                                : Colors.PASTEL_YELLOW,
                        }}
                      >
                        <Typography style={{ textAlign: "center", color: "#fff" }}>
                          {Number(el?.type) === 1 ? "O" : Number(el?.type) === 2 ? "R" : "U"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ color: "#fff", textAlign: "center" }}>
                          {el?.number}
                        </Typography>
                      </TableCell>
                      {el?.weeks
                        ? el?.weeks.map((el) => (
                            <TableCell>
                              <Typography style={{ textAlign: "center", color: "#fff" }}>
                                {el?.counter}
                              </Typography>
                            </TableCell>
                          ))
                        : []}
                      <TableCell>
                        <Typography style={{ textAlign: "center", color: "#fff" }}>
                          {el?.total_in_month}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ),
              )
            : []}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
