import { useState, useEffect, FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { Colors } from "constants/constants";
import { DashboardMainChartType } from "types/DashboardRecord";
import { handleGetStatuses } from "pages/Scorecard/requests";
import { useTypeSelectors } from "hooks/useTypeSelectors";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface TotalWeekData {
  date: string;
  status: string;
  week: string;
  year: string;
}

export const TotalWeeklyChart: FC<DashboardMainChartType> = ({ station }) => {
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  useEffect(() => {
    if (selectType !== "daily") {
      const params = {
        station,
        ...(selectType === "month" && { month: +date?.month, year: +date?.year }),
        ...(selectType === "week" && { week: +date?.week, year: +date?.year }),
        ...(selectType === "annual" && { year: +date?.year }),
      };

      handleGetStatuses(params, setRequestData, setLoading);
    }
  }, [station, date?.year, date?.month, date?.week, selectType]);

  const labels = requestData.map((el: TotalWeekData) => el.week);

  const dataChart = {
    labels,
    datasets:
      selectType !== "daily"
        ? [
            {
              label: "",
              data: requestData.map((el: TotalWeekData, index: number) =>
                el.week || `${el.week} ${el.year}` === labels[index]
                  ? handleConditionMaxValueChart(el.status)
                  : null,
              ),
              backgroundColor: requestData.map((el: TotalWeekData, index: number) =>
                el.week || `${el.week} ${el.year}` === labels[index]
                  ? handleConditionMaxColorChart(el.status)
                  : "#fff",
              ),
              minBarLength: 3,
              barThickness: 15,
              borderRadius: 25,
            },
          ]
        : [],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!loading && (
        //@ts-ignore
        <Bar options={options} data={dataChart} />
      )}
    </Box>
  );
};

const options = {
  responsive: true,
  scales: {
    y: {
      title: {
        display: false,
        text: "Value",
      },
      min: 0,
      max: 5,
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
        stepSize: 1,
        //@ts-ignore
        callback: (value: number) => {
          if (value === 0) return "";
          if (value === 1) return "Poor";
          if (value === 2) return "Fair";
          if (value === 3) return "Great";
          if (value === 4) return "Fantastic";
          if (value === 5) return "Fantastic Plus";
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: true,
      text: "Performance Scorecard",
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          let label = "";
          if (context.parsed.y) {
            if (context.parsed.y === 0) return "";
            if (context.parsed.y === 1) return "Poor";
            if (context.parsed.y === 2) return "Fair";
            if (context.parsed.y === 3) return "Great";
            if (context.parsed.y === 4) return "Fantastic";
            if (context.parsed.y === 5) return "Fantastic Plus";
          }
          return label;
        },
      },
    },
  },
};

const handleConditionMaxValueChart = (value: string) => {
  if (value === "Fantastic Plus") return 5;
  if (value === "Fantastic") return 4;
  if (value === "Great") return 3;
  if (value === "Fair") return 2;
  if (value === "Poor") return 1;
};

const handleConditionMaxColorChart = (value: string) => {
  if (value === "Fantastic Plus") return Colors.PASTEL_BLUE;
  if (value === "Fantastic") return Colors.PASTEL_GREEN;
  if (value === "Great") return Colors.PASTEL_YELLOW;
  if (value === "Fair") return Colors.PASTEL_ORANGE;
  if (value === "Poor") return Colors.PASTEL_RED;
};
