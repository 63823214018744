import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
    borderBottom: "none",
  },
  filter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    flexWrap: "wrap",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
    "@media (max-width: 700px)": {
      paddingTop: theme.spacing(2),
      gap: 10,
      flexDirection: "column-reverse",
    }
  },
  selectors: {
    display:"flex",
    width:400,
    height: 50,
    marginRight: 10,
    marginLeft: 10,
  },
  tableRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxHeight: "calc(100vh - 180px)",
  },
  tableContainer: {
    boxShadow: "none",
    borderRadius: 15,
    border: "none",
  },
  tableBoldBorderCell: {
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
    fontWeight: 800,
  },
  leftBoldBorderCell: {
    borderLeft: `1px solid ${theme.palette.primary.dark}`,
  },
  rightBoldBorderCell: {
    borderRight: `1px solid ${theme.palette.primary.dark}`,
  },
  emptyTable: {
    marginTop: theme.spacing(2),
    alignSelf: "center",
  },
  tableCell: {
    cursor: "default",
    textAlign: "center",
    padding: theme.spacing(1.5),
  },
  tableDarkCell: {
    color: Colors.WHITE,
    cursor: "default",
    textAlign: "center",
  },
  smallTableFont: {
    fontSize: "10px",
  },
  button: {
    marginRight: theme.spacing(1),
    textTransform: "none",
  },
  tableCellWeekName: {
    cursor: "default",
    textAlign: "center",
    background: theme.palette.primary.light,
    color: Colors.WHITE,
  },
  tableHeadWhiteCell: {
    background: Colors.WHITE,
  },
  bottomBoldBorderCell: {
    borderBottom: "3px solid lightGrey",
  },
  greenCell: {
    color: Colors.GREEN,
    background: "#cefad0",
  },
  yellowCell: {
    color: Colors.YELLOW,
    background: "#f7f5bc",
  },
  redCell: {
    color: Colors.RED,
    "MuiAlert-standardError": {
      background: "#ffe3e6",
    }
  },
  greenAlert: {
    background: "#cefad0",
  },
  redAlert: {
    background: "#ffe3e6",
  },
  orangeAlert: {
    background: "#f7f5bc",
  },
  weekSelectorBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableHeadTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
}));
