import { FC, useEffect } from "react";
import { Box, Paper, Grid, FormControlLabel, Switch, Button } from "@material-ui/core";
import { TopTenDaShifts } from "pages/Scorecard/charts/TopTenDaShifts";
import { RolesSplit } from "pages/Scorecard/charts/RolesSplit";
import { Returned } from "pages/Scorecard/charts/Returned";
import { PageWrapper } from "components/elements/PageWrapper";
import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import {
  ScorecardExternalSetDate,
  ScorecardSetSelectType,
  ScorecardSetStation,
  ScorecardExternalSettingsActive,
} from "actions/scorecardActions";
import { StationSelect } from "components/elements/StationSelect";
import { MonthSelect } from "components/elements/MonthSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { ScorecardConstants } from "constants/constants";
import { TopTenVans } from "./charts/TopTenVans";
import { MissingDeliveries } from "./charts/MissingDeliveries";
import { CashOnDelivery } from "./charts/CashOnDelivery";
import { DARWorst } from "./charts/DARWorst";
import { LateAttendance } from "./charts/LateAttendance";
import { LaunchpadSupport } from "./charts/LaunchpadSupport";
import { MorningChecklist } from "./charts/MorningChecklist";
import { Rescue } from "./charts/Rescue";
import { DatePickWithArrow } from "components/elements/DatePickWithArrow";
import { ReturnedDataByDriver } from "./charts/ReturnedDataByDriver";

interface PropsType {}

const Scorecard: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useTypeSelectors((state) => state.auth);
  const { externalSettingActive, externalSettings } = useTypeSelectors(
    (state) => state.scoreCardSettings,
  );
  const defaultStationCondition = userData?.station_spots.some(
    (el: { station: { title: string; id: number } }) => el.station.id === 1,
  )
    ? 1
    : userData?.station_spots[0]?.station?.id;
  const handleChangeExternalStation = (data: string) =>
    dispatch(ScorecardSetStation(data, ScorecardConstants.EXTERNAL_SET_CURRENT_STATION));
  const handleChangeExternalSelectType = (select: string) =>
    dispatch(ScorecardSetSelectType(select, ScorecardConstants.EXTERNAL_SET_SELECT_TYPE));

  const handleChangeExternalWeek = (week: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...externalSettings?.date, week },
        ScorecardConstants.EXTERNAL_SET_DATE,
      ),
    );
  const handleChangeExternalMonth = (month: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...externalSettings?.date, month },
        ScorecardConstants.EXTERNAL_SET_DATE,
      ),
    );
  const handleChangeExternalYear = (year: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...externalSettings?.date, year },
        ScorecardConstants.EXTERNAL_SET_DATE,
      ),
    );

  const handleChangeExternalDay = (date: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...externalSettings?.date, day: date },
        ScorecardConstants.EXTERNAL_SET_DATE,
      ),
    );

  const {
    week,
    month,
    year,
    date,
    selectType,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onTypeExternalChange,
    onDateChange,
    onDateChangeByDay,
  } = useDateWeekSelector({
    defaultWeek: externalSettings?.date?.week,
    defaultMonth: externalSettings?.date?.month,
    defaultYear: externalSettings?.date?.year,
    defaultDate: externalSettings?.date?.day,
    defaultSelectType: externalSettings?.selectType,
    onSelectTypeSet: handleChangeExternalSelectType,
    onWeekSet: handleChangeExternalWeek,
    onMonthSet: handleChangeExternalMonth,
    onYearSet: handleChangeExternalYear,
    onDateSet: handleChangeExternalDay,
  });

  useEffect(() => {
    if (externalSettingActive) {
      dispatch(
        ScorecardSetStation(
          String(defaultStationCondition),
          ScorecardConstants.EXTERNAL_SET_CURRENT_STATION,
        ),
      );
    } else {
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.DA_SCORE_TREND_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.RETURN_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.ROLES_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.TOP_TEN_DAS_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.TOP_TEN_SHIFTS_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.VANS_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.TOTAL_SCOREBOARD_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.MISSING_DELIVERIES_SET_CURRENT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.LATE_ATTENDANCE_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.LAUNCHPAD_SUPPORT_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.MORNING_CHECKLIST_STATION,
        ),
      );
      dispatch(
        ScorecardSetStation(`${defaultStationCondition}`, ScorecardConstants.RESCUE_STATION),
      );
      dispatch(
        ScorecardSetStation(
          `${defaultStationCondition}`,
          ScorecardConstants.RETURNED_DATA_BY_DRIVER_STATION,
        ),
      );
    }
  }, [defaultStationCondition, dispatch]);

  return (
    <PageWrapper data-testid="testScorecard">
      <Box className={classes.titleBox}>
        <Box className={classes.titleSettings}>
          {externalSettingActive && (
            <Box className={classes.titleSettings}>
              {selectType === "month" && (
                <MonthSelect
                  month={month}
                  year={year}
                  onNext={onMonthIncrease}
                  onPrev={onMonthReduce}
                  disabled={dateConditionMonth()}
                />
              )}
              {selectType === "week" && (
                <WeekSelect
                  week={week}
                  year={year}
                  onNext={onWeekIncrease}
                  onPrev={onWeekReduce}
                  disabled={dateCondition()}
                />
              )}
              {selectType === "day" && (
                <DatePickWithArrow
                  value={date}
                  onChange={onDateChange}
                  changeDay={onDateChangeByDay}
                  displayWeek
                />
              )}
              <Box>
                <Button
                  variant={selectType === "month" ? "contained" : "outlined"}
                  className={classes.leftButton}
                  onClick={(e) => onTypeExternalChange("month")}
                >
                  Monthly
                </Button>
                <Button
                  variant={selectType === "week" ? "contained" : "outlined"}
                  className={classes.midButton}
                  onClick={() => onTypeExternalChange("week")}
                >
                  Weekly
                </Button>
                <Button
                  variant={selectType === "day" ? "contained" : "outlined"}
                  className={classes.rightButton}
                  onClick={() => onTypeExternalChange("day")}
                >
                  Daily
                </Button>
              </Box>
              <StationSelect
                value={externalSettings?.station}
                onChange={handleChangeExternalStation}
                stations={userData?.station_spots}
              />
            </Box>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={externalSettingActive}
                onChange={() =>
                  dispatch(
                    ScorecardExternalSettingsActive(
                      !externalSettingActive,
                      `${defaultStationCondition}`,
                    ),
                  )
                }
                name="parentSettings"
                color="primary"
              />
            }
            label="External setting"
          />
        </Box>
      </Box>

      <Box className={classes.mainChartBox}>
        <Grid container spacing={2}>
          {SCORE_CHARTS.map(({ Children }) => (
            <Grid item sm={12} lg={6} xl={4}>
              <Paper className={classes.paper}>
                <Children />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default Scorecard;

const SCORE_CHARTS = [
  { Children: RolesSplit },
  { Children: TopTenDaShifts },
  { Children: Returned },
  { Children: TopTenVans },
  { Children: MissingDeliveries },
  { Children: CashOnDelivery },
  { Children: DARWorst },
  { Children: LateAttendance },
  { Children: LaunchpadSupport },
  { Children: MorningChecklist },
  { Children: Rescue },
  { Children: ReturnedDataByDriver },
];
