import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CSVRecord, AmazonRecord } from "types/AmazonRecord";
import { inputHistoryTableList } from "components/tables/tableRows";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
} from "@material-ui/core";
import { EMPTY } from "constants/constants";
import {
  ImportChangeRowsPerPage,
  ImportPageChangeType,
  getAmazonHistoryAction,
  getCSVHistoryAction,
} from "actions/amazonReportsActions";
import { useStyles } from "./styles";
import FileImportTableRow from "./FileImportTableRow";
import { ImportFileButton } from "components/buttons/ImportFileButton";

interface AmazonImportTableProps {}

export const AmazonImportTable: FC<AmazonImportTableProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    amazonHistoryDataList,
    amazonHistoryLoading,
    csvHistoryDataList,
    importHistoryDataListCount,
    importPDFTableRowsPerPage,
    isAmazonPage,
  } = useTypeSelectors((state) => state.amazonReports);

  const [page, setPage] = useState(0);

  const handleChangePagination = (event: unknown, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(ImportChangeRowsPerPage(+event.target.value));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getAmazonHistoryAction(page + 1, importPDFTableRowsPerPage));
    dispatch(getCSVHistoryAction(page + 1, importPDFTableRowsPerPage));
  }, [importPDFTableRowsPerPage, page, dispatch]);

  return (
    <Box className={classes.root}>
      <Paper className={classes.filter}>
        <Box className={classes.buttonBox}>
          <ImportFileButton />
          <Box className={classes.buttons}>
            <Button
              variant={isAmazonPage ? "contained" : "outlined"}
              className={classes.leftButton}
              onClick={() => dispatch(ImportPageChangeType(true))}
            >
              Pdf Table
            </Button>
            <Button
              variant={!isAmazonPage ? "contained" : "outlined"}
              className={classes.rightButton}
              onClick={() => dispatch(ImportPageChangeType(false))}
            >
              Csv Table
            </Button>
          </Box>
        </Box>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={importHistoryDataListCount}
          rowsPerPage={importPDFTableRowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePagination}
        />
      </Paper>
      <Box className={classes.body}>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {inputHistoryTableList.map((TableRows) => (
                  <TableCell
                    className={`${classes.tableCell} ${classes.tableHeadCell}`}
                    key={TableRows.id}
                  >
                    <Typography className={classes.tableHeadTypography} variant="body2">
                      {TableRows.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isAmazonPage ? (
              <TableBody>
                {amazonHistoryDataList.length ? (
                  amazonHistoryDataList.map((row: AmazonRecord) => (
                    <FileImportTableRow row={row} key={row.id} fileName={row.pdf_file_name} />
                  ))
                ) : (
                  <TableCell className={`${classes.tableCell}`}>
                    <Typography>Empty</Typography>
                  </TableCell>
                )}
              </TableBody>
            ) : (
              <TableBody>
                {csvHistoryDataList.length ? (
                  csvHistoryDataList.map((row: CSVRecord) => (
                    <FileImportTableRow row={row} key={row.id} fileName={row.csv_file_name} />
                  ))
                ) : (
                  <TableCell className={`${classes.tableCell}`}>
                    <Typography>Empty</Typography>
                  </TableCell>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!amazonHistoryLoading && !amazonHistoryDataList.length ? (
          <Typography variant="h5" className={classes.emptyTable}>
            {EMPTY}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
