import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Box } from "@material-ui/core";
import { Auth } from "./auth/auth";
import LogoSvg from "icon/LogoSvg";
import { Colors } from "constants/constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    background: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuIcon: {
    color: "rgba(0, 0, 0, 0.5)",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  logoContainer: {
    display: "flex",
  },
}));

export const AppBarComponent = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Box className={classes.contentContainer}>
          <Box className={classes.logoContainer}>
            <LogoSvg color={Colors.WHITE} width={"120"} height={"50"} />
          </Box>
          <Auth />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
