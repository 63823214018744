import {
    TableRow,
    TableCell,
    Typography,
} from "@material-ui/core";
import { getIkeaCheckAverageScoreData } from "actions/ikeaCheckActions";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IkeaCheckAverageScoreData } from "types/IkeaCheck";
import { useStyles } from "./styles";
import { formatNumberType } from "utils/formatNUmber";

interface IkeaCheckAverageScoreRowProps {
    data:IkeaCheckAverageScoreData | null,
    loadingData:boolean,
}

export const IkeaCheckAverageScoreRow:FC<IkeaCheckAverageScoreRowProps> = ({data, loadingData}) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getIkeaCheckAverageScoreData())
    },[loadingData, dispatch])
    
    return ( 
        <TableRow hover>
            <TableCell
                colSpan={2}
                className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}
            >
                Average
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.dcr)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.dcr_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.cc)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.cc_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.oa)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.oa_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.mc)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.mc_da_off_target))}</Typography>
            </TableCell>
            <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.infraction)}</Typography>
            </TableCell>
            <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.reliability_score)}</Typography>
            </TableCell>
            <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.tenured_da)}</Typography>
            </TableCell>
            <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.employee_satisfaction_survey)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell}`}></TableCell>
        </TableRow>
    )
}