import { FC, ReactNode, useState } from "react";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { ScorecardCompareModals } from "components/modals/ScorecardCompareModals";
import { IconOpenButton } from "./Buttons";

interface ScorecardCompareButtonProps {
  children?: ReactNode;
  children2?: ReactNode;
}

export const ScorecardCompareButton: FC<ScorecardCompareButtonProps> = ({
  children,
  children2,
}) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <IconOpenButton
          Icon={CompareArrowsIcon}
          onClick={onOpen}
      />
      <ScorecardCompareModals
        open={open}
        onClose={onClose}
        children={children}
        children2={children2}
      />
    </>
  );
};
