import { initialActState } from "store/initialStates";
import { ActState } from "types/Act";
import { ReducersInterfaceAction } from "types/Reducers";
import { ActTableConstants } from "constants/constants";

export const actReducer = (state = initialActState, action: ReducersInterfaceAction): ActState => {
  switch (action.type) {
    case ActTableConstants.ACT_TABLE_GET_INFO_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload?.results,
        dataListCount: action.payload?.count,
      };
    case ActTableConstants.ACT_TABLE_GET_INFO_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case ActTableConstants.ACT_TABLE_GET_INFO_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case ActTableConstants.ACT_TABLE_UPDATE_INFO_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload,
      };
    case ActTableConstants.ACT_TABLE_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        dataTableRowsPerPage: action.payload,
      };
    case ActTableConstants.SET_ACT_TABLE_FILTERS:
      return {
        ...state,
        reportListFilters: { ...state.reportListFilters, ...action.payload },
      };
    default:
      return state;
  }
};
