import {
  makeStyles,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Drawer,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { FC, useState } from "react";
import { ModalWindow } from "components/templateComponents/modalWindow";
import { useDialogStyles } from "components/modals/styles";
import { TabWrapper } from "components/elements/TabPanel";
import { pdfUploadButtons, csvUploadButtons, xlsxUploadButtons } from "./buttonLists";

interface ImportFileDrawerProps {
  open: boolean;
  onClose: () => void;
}

interface BtnListItem {
  modalName: string;
  modalTitle: string;
  modalDescription: string;
  component: JSX.Element;
}

interface BtnListComponentProps {
  list: BtnListItem[];
  className: string;
}

export const ImportFileDrawer: FC<ImportFileDrawerProps> = ({ open, onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const dialogClasses = useDialogStyles();
  const classes = useFiltersStyles();

  return (
    <Drawer
      data-testid="importFileDrawer"
      anchor="right"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { maxWidth: 400 } }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography style={{ textAlign: "center", fontWeight: 700 }}>Select upload type</Typography>
        <Typography style={{ textAlign: "center" }}>
          (Available formats: .pdf, .csv, .xlsx)
        </Typography>
      </DialogTitle>
      <TabWrapper
        value={tabIndex}
        onChange={setTabIndex}
        tabs={[
          {
            label: "Upload pdf file",
            component: <BtnListComponent list={pdfUploadButtons} className={classes.btnList} />,
          },
          {
            label: "Upload csv file",
            component: <BtnListComponent list={csvUploadButtons} className={classes.btnList} />,
          },
          {
            label: "Upload xlsx file",
            component: <BtnListComponent list={xlsxUploadButtons} className={classes.btnList} />,
          },
        ]}
      />
      <DialogContent></DialogContent>
      <DialogActions style={{ padding: "32px 24px", gap: "20px" }}>
        <Button
          data-testid="importFileDrawerCancelButton"
          className={dialogClasses.actionButton}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Drawer>
  );
};

export const BtnListComponent = ({ list, className }: BtnListComponentProps) => {
  return (
    <Box className={className} data-testid="importBtnListComponent">
      {list?.map((item: BtnListItem) => (
        <ModalWindow
          modal_name={item.modalName}
          modalTitle={item.modalTitle}
          modalDescription={item.modalDescription}
        >
          {item.component}
        </ModalWindow>
      ))}
    </Box>
  );
};

const useFiltersStyles = makeStyles((theme) => ({
  btnList: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(3),
  },
}));
