import { useState, useEffect, FC } from "react";
import { Colors } from "constants/constants";
import { Box } from "@material-ui/core";
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from "chart.js";
import { Bubble } from "react-chartjs-2";
import { handleGetPackages } from "pages/Scorecard/requests";
import { DashboardMainChartType } from "types/DashboardRecord";
import { useTypeSelectors } from "hooks/useTypeSelectors";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

interface Element {
  road_runner_score: number;
  team_player_score: number;
  driver: string;
  delivered: { delivered__avg: number; delivered__sum: number };
}

export const ScoreTrendChart: FC<DashboardMainChartType> = ({ station }) => {
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);

  const { weightsData } = useTypeSelectors((state) => state.weightsData);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;
  const teamPlayerValueLineDraw = weightsData?.team_player?.team_player_min;
  const roadRunnerValueLineDraw = weightsData?.on_road?.road_runner_min;

  useEffect(() => {
    if (selectType !== "daily") {
      const params = {
        station,
        year: +date?.year,
        ...(selectType === "month" ? { month: +date?.month } : { week: +date?.week }),
      };

      handleGetPackages(params, setRequestData, setLoading);
    }
  }, [station, date?.year, date?.month, date?.week, selectType]);

  const dataChart = {
    datasets: !loading
      ? [
          ...requestData.map((el: Element) => ({
            label: `${el.driver}, Team player score: ${el.team_player_score}, Road runner score: ${el.road_runner_score}, Delivered Avg: ${el.delivered.delivered__avg}, Delivered Sum: ${el.delivered.delivered__sum}`,
            data: [
              {
                x: el.team_player_score,
                y: el.road_runner_score,
                r: el.delivered.delivered__avg / 50,
              },
            ],
            backgroundColor: "rgb(64, 132, 201, 0.3)",
          })),
        ]
      : [],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Road runner score",
        },
        min: -10,
        max: 120,
        beginAtZero: true,
        ticks: {
          color: `${Colors.MAIN}`,
          stepSize: 10,
          font: {
            weight: "700",
          },
        },
        grid: {
          //@ts-ignore
          color: (context) => {
            if (context.tick.value === roadRunnerValueLineDraw) return `${Colors.PASTEL_GREEN}`;
            else return "lightgrey";
          },
          borderColor: "transparent",
          tickColor: "transparent",
        },
      },
      x: {
        title: {
          display: true,
          text: "Team player score",
        },
        ticks: {
          color: `${Colors.MAIN}`,
          stepSize: 10,
          font: {
            weight: "700",
          },
        },
        grid: {
          //@ts-ignore
          color: (context) => {
            if (context.tick.value === teamPlayerValueLineDraw) return `${Colors.PASTEL_GREEN}`;
            else return "lightgrey";
          },
          borderColor: "transparent",
          tickColor: "transparent",
        },
        min: -10,
        max: 110,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: true,
        text: "DA Score trend",
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            return label;
          },
        },
      },
    },
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Bubble options={options} data={selectType !== "daily" ? dataChart : { datasets: [] }} />
    </Box>
  );
};
