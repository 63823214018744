import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: "10px",
  },
  body: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: "10px",
  },
  pageTitle: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
  },
  infoBox: {
    boxSizing: "border-box",
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
    height: "100%",
  },
  accountInfoBox: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    marginBottom: theme.spacing(2),
  },
  account: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: "35px",
    fontWeight: 700,
    color: Colors.MAIN,
    background: Colors.GRAY,
  },
  avatarIcon: {
    fontSize: 45,
  },
  tableBody: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: "10px",
  },
  dataBoxString: {
    display: "flex",
    flexDirection: "row",
  },
  textFIeld: {
    background: "#fff",
    color: "#205885",
    width: "100%",
  },
  buttonBox: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    fontSize: 16,
    width: "200px",
  },
}));
