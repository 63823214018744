import { CheckTableConstants } from "constants/constants";
import { initialCheckState } from "store/initialStates";
import { CheckState } from "types/Check";
import { ReducersInterfaceAction } from "types/Reducers";

export const checkReducer = (
  state = initialCheckState,
  action: ReducersInterfaceAction,
): CheckState => {
  switch (action.type) {
    case CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload?.results,
        dataListCount: action.payload?.count,
      };
    case CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case CheckTableConstants.CHECK_TABLE_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        dataTableRowsPerPage: action.payload,
      };

    case CheckTableConstants.CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING:
      return {
        ...state,
        currentWeekLoading: true,
      };
    case CheckTableConstants.CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS:
      return {
        ...state,
        currentWeekLoading: false,
        currentWeekData: action.payload,
      };
    case CheckTableConstants.CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR:
      return {
        ...state,
        currentWeekLoading: false,
      };
    case CheckTableConstants.CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_LOADING:
      return {
        ...state,
        uploadFileLoading: true,
      };
    case CheckTableConstants.CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_SUCCESS:
    case CheckTableConstants.CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_ERROR:
      return {
        ...state,
        uploadFileLoading: false,
      };
    case CheckTableConstants.CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING:
      return {
        ...state,
        changeDataLoading: true,
      };
    case CheckTableConstants.CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS:
      return {
        ...state,
        changeDataLoading: false,
        currentWeekData: action.payload,
      };
    case CheckTableConstants.CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR:
      return {
        ...state,
        changeDataLoading: false,
      };
    case CheckTableConstants.CHECK_TABLE_UPDATE_CAS_ES:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload,
      };
    case CheckTableConstants.UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA:
      return {
        ...state,
        avergeScoreData: action.payload,
      };
    case CheckTableConstants.SET_CHECK_TABLE_FILTERS:
      return {
        ...state,
        reportListFilters: { ...state.reportListFilters, ...action.payload },
      };
    default:
      return state;
  }
};
