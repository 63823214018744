import { ChangeEvent, FC } from "react";
import { Box, Typography, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export type Option = {
  id: string;
  title: string;
};

interface SearchTypeSelectProps {
  value: string | number;
  onChange: (event: ChangeEvent<{value: unknown;}>) => void;
  options: Option[];
  disabled?: boolean;
}

export const SearchTypeSelect: FC<SearchTypeSelectProps> = ({
  value,
  onChange,
  options,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.dateBox}>
      <Select
        value={value}
        onChange={onChange}
        className={classes.select}
        disabled={disabled}
        name="search_type"
      >
        {options.map((el) => (
          <MenuItem value={el.id}>
            <Typography variant="body2">{el.title}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  dateBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    display: "flex",
    minWidth: "100px",
    margin: theme.spacing(1),
  },
}));
