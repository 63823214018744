import { FC } from "react";
import { AmazonImportTable } from "components/tables/AmazonTable/AmazonImportTable";
import { PageWrapper } from "components/elements/PageWrapper";

interface PropsType {}

const AmazonImport: FC<PropsType> = () => {
  return (
    <PageWrapper>
      <AmazonImportTable />
    </PageWrapper>
  );
};

export default AmazonImport;
