import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { checkTableWeekFullInfo } from "actions/checkActions";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { useNav } from "hooks/useNav";
import { TABLE_HEADERS_COLUMN, WEEK_TABLE_HEADERS_COLUMN } from "./checkTable";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { CheckWeekPageRow } from "./CheckWeekPageRow";
import { CheckCurrentDataWeekRow, DailyCheck, GlobalTarget } from "types/Check";
import Loader from "router/components/Loader";

interface PropsType {}

const CheckWeekPage: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useNav().pageParams;
  const { viewCheck } = useNav();

  const { currentWeekData, currentWeekLoading } = useTypeSelectors((state) => state.checkTable);

  const dailyArray = currentWeekData?.daily_checks ? currentWeekData?.daily_checks : [];

  useEffect(() => {
    if (id) {
      dispatch(checkTableWeekFullInfo(id));
    }
  }, [id, dispatch]);
  
  if (!id) return null;

  return (
    <Box data-testid="testCheckWeekPage" className={classes.root}>
      <Paper className={classes.filter}>
        <Button variant="contained" color="inherit" className={classes.button} onClick={viewCheck}>
          Back to Check list
        </Button>
      </Paper>

      <Loader isLoading={currentWeekLoading} />
      <TableContainer
        className={classes.tableContainer}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={2}
                className={classes.tableCell}
              >
                <Typography style={{ fontWeight: 700 }}>
                  Week: {currentWeekData?.week_number ? currentWeekData?.week_number + 1 : 0}.{currentWeekData?.year}
                </Typography>
              </TableCell>
              {WEEK_TABLE_HEADERS_COLUMN.map((el) => (
                <TableCell
                  colSpan={2}
                  className={classes.tableCell}
                >
                  <Typography style={{ fontWeight: 700 }}>{el.label}{getCheckDayDate(currentWeekData, el.label)}</Typography>
                </TableCell>
              ))}
              <TableCell
                colSpan={2}
                className={classes.tableCellWeekName}
              >
                <Typography style={{ fontWeight: 700 }}>Total</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} style={{ textAlign: "center" }}>
                <Typography className={classes.smallTableFont}>Success Measure</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} style={{ textAlign: "end" }}>
                <Typography className={classes.smallTableFont}>Target</Typography>
              </TableCell>
              {WEEK_TABLE_HEADERS_COLUMN.map((el) => (
                <>
                  <TableCell
                    colSpan={1}
                    className={
                      el?.label === "Week"
                        ? `${classes.tableCellWeekName} ${classes.tableCell}`
                        : `${classes.tableCell} ${classes.tableCellPaddingLeft}`
                    }
                    style={{ borderLeft: "1px solid lightGrey" }}
                  >
                    <Typography className={classes.smallTableFont}>Score</Typography>
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    className={
                      el?.label === "Week"
                        ? `${classes.tableCellWeekName} ${classes.rightBorderCell} ${classes.tableCell}`
                        : `${classes.tableCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`
                    }
                    style={{ borderRight: "1px solid lightGrey" }}
                  >
                    <Typography className={classes.smallTableFont}>DA off target</Typography>
                  </TableCell>
                </>
              ))}
              <TableCell
                style={{ borderLeft: "1px solid lightGrey" }}
                className={`${classes.tableCellWeekName} ${classes.tableCell}`}
              >
                <Typography className={classes.smallTableFont}>Score</Typography>
              </TableCell>
              <TableCell
                className={`${classes.tableCellWeekName} ${classes.rightBorderCell} ${classes.tableCell}`}
                style={{ borderRight: "1px solid lightGrey", textAlign: "end" }}
              >
                <Typography className={classes.smallTableFont}>DA off target</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {TABLE_HEADERS_COLUMN.map((el, i) =>
              i > 0 && i < TABLE_HEADERS_COLUMN.length - 1 ? (
                <CheckWeekPageRow
                  label={el.label}
                  name={el.id}
                  data={dailyArray}
                  weekData={getWeekData(el.id, currentWeekData)}
                  globalTarget={getGlobalTargetValue(el?.id, currentWeekData?.target)}
                />
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CheckWeekPage;

const getCheckDayDate = (currentWeekData: CheckCurrentDataWeekRow | null, dayName:string) => {
  const dt = currentWeekData?.daily_checks.find((item: DailyCheck)=>item?.get_week_day === dayName)?.date
  if (dt) {
    return `, ${("0" + new Date(dt).getDate()).slice(-2)}.${("0" + (new Date(dt).getMonth() + 1)).slice(-2)}`
  }
}

const getWeekData = (key: string, data: CheckCurrentDataWeekRow | null) => {
  switch (key) {
    case "dcr":
      return { score: data?.dcr, target: data?.dcr_da_off_target };
    case "dnr":
      return { score: data?.dnr, target: data?.dnr_da_off_target };
    case "dsb":
      return { score: data?.dsb, target: data?.dsb_da_off_target };
    case "pod":
      return { score: data?.pod, target: data?.pod_da_off_target };
    case "cc":
      return { score: data?.cc, target: data?.cc_da_off_target };
    case "ada":
      return { score: data?.ada, target: data?.ada_da_off_target };
    case "violation":
      return {
        score: data?.violation,
        target: data?.violation_da_off_target,
      };
    case "whc":
      return { score: data?.whc, target: data?.whc_da_off_target };
    case "rd":
      return { score: data?.rescue_given, target: data?.rescue_given_da_off_target };
    case "mc":
      return { score: data?.mc, target: data?.mc_da_off_target };
    case "oa":
      return { score: data?.oa, target: data?.oa_da_off_target };
    case "cas":
      return { score: data?.cas, target: data?.cas_da_off_target };
    case "hp6w":
      return { score: data?.hp6w, target: data?.hp6w_da_off_target };
    case "es":
      return {
        score: data?.employee_satisfaction,
        target: data?.employee_satisfaction_da_off_target,
      };

    default:
      return { score: null, target: null };
  }
};

const getGlobalTargetValue = (key: string, data: GlobalTarget | null | undefined) => {
  switch (key) {
    case "dcr":
      return { name: "dcr", target: data?.dcr };
    case "dnr":
      return { name: "dnr", target: data?.dnr };
    case "dsb":
      return { name: "dsb", target: data?.dsb };
    case "pod":
      return { name: "pod", target: data?.pod };
    case "cc":
      return { name: "cc", target: data?.cc };
    case "ada":
      return { name: "ada", target: data?.ada };
    case "violation":
      return { name: "violation", target: data?.violation };
    case "whc":
      return { name: "whc", target: data?.whc };
    case "rd":
      return { name: "rd", target: data?.rescue_given };
    case "mc":
      return { name: "mc", target: data?.mc };
    case "oa":
      return { name: "oa", target: data?.oa };
    case "cas":
      return { name: "cas", target: data?.cas };
    case "hp6w":
      return { name: "hp6w", target: data?.hp6w };
    case "es":
      return { name: "es", target: data?.employee_satisfaction };

    default:
      return { name: "", target: null };
  }
};
