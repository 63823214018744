import { DailyCheck } from "types/Check";
import { Box, Input, TableCell, Tooltip, Typography } from "@material-ui/core";
import { CheckTableCellPaint } from "components/elements/CheckTableCellPaint";
import Alert, { Color } from "@material-ui/lab/Alert";
import { inputNumberValidate, inputNumberValidateOnPaste } from "utils/formats";
import { useStyles } from "./styles";
import { OnBlurEvent } from "types/general";

export const RenderDayInfo = (
  key: string,
  day: string,
  data: DailyCheck[],
  globalTarget: { name: string; target: string | number | undefined | null },
  handleDataChange: (id: number, name: string, value: number) => void,
  permissionEdit: boolean,
) => {
  const classes = useStyles();
  const params = {
    alertClassName: classes.greenAlert,
    className: classes.greenCell,
    severity: "success",
  };
  const currentData = data.find((el) => el.get_week_day === day);

  switch (key) {
    case "dcr":
      const nameTipDcr =
        currentData?.get_week_day === day && currentData?.dcr_da_off_target_names !== undefined
          ? currentData?.dcr_da_off_target_names
          : [];
      if (
        Number(globalTarget.target) >
        Math.round(Number(currentData?.get_week_day === day ? currentData?.dcr : 0))
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={currentData?.dcr}
              conditionDay={currentData?.get_week_day === day}
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTipDcr?.length < 1}
              title={
                <Box>
                  {nameTipDcr?.length > 0
                    ? nameTipDcr.map((el) => (
                        <Typography variant="body2">
                          {el?.driver__first_name + " " + el?.driver__last_name}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.dcr_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "dnr":
      const nameTipDnr =
        currentData?.get_week_day === day && currentData?.dnr_da_off_target_names !== undefined
          ? currentData?.dnr_da_off_target_names
          : [];
      if (
        Math.round(Number(currentData?.get_week_day === day ? currentData?.dnr : 0)) >
        Number(globalTarget.target)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={currentData?.dnr}
              conditionDay={currentData?.get_week_day === day}
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTipDnr?.length < 1}
              title={
                <Box>
                  {nameTipDnr?.length > 0
                    ? nameTipDnr.map((el) => (
                        <Typography variant="body2">
                          {`${el?.driver__first_name} ${el?.driver__last_name} x${el?.dnr}`}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.dnr_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "dsb":
      const nameTip =
        currentData?.get_week_day === day && currentData?.dsb_da_off_target_names !== undefined
          ? currentData?.dsb_da_off_target_names
          : [];
      if (
        Math.round(Number(currentData?.get_week_day === day ? currentData?.dsb : 0)) >
        Number(globalTarget.target)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            {currentData?.get_week_day === day ? (
              <Alert
                className={params.alertClassName}
                icon={false}
                severity={params.severity as Color}
                style={{
                  padding: "0",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Input
                  defaultValue={Math.round(Number(currentData?.dsb))}
                  name={`dsb`}
                  onBlur={(e: OnBlurEvent) =>
                    handleDataChange(currentData?.id, "dsb", e?.target?.value)
                  }
                  onKeyPress={inputNumberValidate}
                  onPaste={inputNumberValidateOnPaste}
                  inputProps={{
                    style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
                    "data-testid": "renderDayInfoDSBInput",
                  }}
                  disabled={permissionEdit}
                  disableUnderline={permissionEdit}
                />
              </Alert>
            ) : (
              <Typography>0</Typography>
            )}
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTip?.length < 1}
              title={
                <Box>
                  {nameTip?.length > 0
                    ? nameTip.map((el) => (
                        <Typography variant="body2">
                          {`${el?.driver__first_name} ${el?.driver__last_name} x${el?.dsb_count}`}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.dsb_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "pod":
      const nameTipPod =
        currentData?.get_week_day === day && currentData?.pod_da_off_target_names !== undefined
          ? currentData?.pod_da_off_target_names
          : [];
      if (
        Number(globalTarget.target) >
        Math.round(Number(currentData?.get_week_day === day ? currentData?.pod : 0))
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={currentData?.pod}
              conditionDay={currentData?.get_week_day === day}
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTipPod?.length < 1}
              title={
                <Box>
                  {nameTipPod?.length > 0
                    ? nameTipPod.map((el) => (
                        <Typography variant="body2">
                          {el?.driver__first_name + " " + el?.driver__last_name}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.pod_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "violation":
      const nameTipViol =
        currentData?.get_week_day === day &&
        currentData?.violation_da_off_target_names !== undefined
          ? currentData?.violation_da_off_target_names
          : [];
      if (
        Number(globalTarget.target) >
        Number(currentData?.get_week_day === day ? currentData?.violation : 0)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            {currentData?.get_week_day === day ? (
              <Alert
                className={params.alertClassName}
                icon={false}
                severity={params.severity as Color}
                style={{
                  padding: "0",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Input
                  defaultValue={Math.round(Number(currentData?.violation))}
                  name={`violation`}
                  onBlur={(e: OnBlurEvent) =>
                    handleDataChange(currentData?.id, "violation", e?.target?.value)
                  }
                  onKeyPress={inputNumberValidate}
                  onPaste={inputNumberValidateOnPaste}
                  inputProps={{
                    style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
                    "data-testid": "renderDayInfoViolationInput",
                  }}
                  disabled={permissionEdit}
                  disableUnderline={permissionEdit}
                />
              </Alert>
            ) : (
              <Typography>0</Typography>
            )}
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTipViol?.length < 1}
              title={
                <Box>
                  {nameTipViol?.length > 0
                    ? nameTipViol.map((el) => (
                        <Typography variant="body2">
                          {el?.driver__first_name + " " + el?.driver__last_name}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.violation_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "rd":
      const nameTipRd =
        currentData?.get_week_day === day &&
        currentData?.rescue_given_da_off_target_names !== undefined
          ? currentData?.rescue_given_da_off_target_names
          : [];
      if (
        Number(
          currentData?.get_week_day === day && currentData?.rescue_given !== undefined
            ? currentData?.rescue_given
            : 0,
        ) < Number(globalTarget.target)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={currentData?.rescue_given}
              conditionDay={currentData?.get_week_day === day}
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTipRd?.length < 1}
              title={
                <Box>
                  {nameTipRd?.length > 0
                    ? nameTipRd.map((el) => (
                        <Typography variant="body2">
                          {el?.driver__first_name + " " + el?.driver__last_name}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.rescue_given_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "mc":
      const nameTipMc =
        currentData?.get_week_day === day && currentData?.mc_da_off_target_names !== undefined
          ? currentData?.mc_da_off_target_names
          : [];
      if (
        Number(globalTarget.target) >
        Number(currentData?.get_week_day === day ? currentData?.mc : 0)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={currentData?.mc}
              conditionDay={currentData?.get_week_day === day}
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Tooltip
              disableHoverListener={nameTipMc?.length < 1}
              title={
                <Box>
                  {nameTipMc?.length > 0
                    ? nameTipMc.map((el) => (
                        <Typography variant="body2">
                          {el?.driver__first_name + " " + el?.driver__last_name}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.mc_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "oa":
      const nameTipOa =
        currentData?.get_week_day === day && currentData?.oa_da_off_target_names !== undefined
          ? currentData?.oa_da_off_target_names
          : [];
      if (
        Number(globalTarget.target) >
        Number(currentData?.get_week_day === day ? currentData?.oa : 0)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell
            className={`${classes.tableCell} ${classes.bottomBoldBorderCell} ${classes.tableCellPaddingLeft}`}
          >
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={currentData?.oa}
              conditionDay={currentData?.get_week_day === day}
              isNumber
            />
          </TableCell>
          <TableCell
            className={`${classes.tableCell} ${classes.rightBorderCell} ${classes.bottomBoldBorderCell}`}
          >
            <Tooltip
              disableHoverListener={nameTipOa?.length < 1}
              title={
                <Box>
                  {nameTipOa?.length > 0
                    ? nameTipOa.map((el) => (
                        <Typography variant="body2">
                          {el?.driver__first_name + " " + el?.driver__last_name}
                        </Typography>
                      ))
                    : null}
                </Box>
              }
            >
              <Box>
                <CheckTableCellPaint
                  alertStyle={params.severity}
                  alertBackgoundStyle={params.alertClassName}
                  typographyStyle={params.className}
                  value={currentData?.oa_da_off_target}
                  conditionDay={currentData?.get_week_day === day}
                  isNumber
                />
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    case "hp6w":
      return (
        <TableCell className={`${classes.tableCell} ${classes.bottomBoldBorderCell}`} colSpan={2} />
      );
    case "cc":
    case "ada":
    case "whc":
    case "cas":
    case "es":
    default:
      return <TableCell colSpan={2} />;
  }
};
