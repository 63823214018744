export const TABLE_HEADERS_COLUMN = [
  { field: "small", id: "date", label: "Date" },
  { field: "small", id: "dcr", label: "DCR", toolTipLabel: "Delivery Completion Rate" },
  { field: "small", id: "cc", label: "CC", toolTipLabel: "Contact Compliance" },
  { field: "small", id: "oa", label: "OA", toolTipLabel: "Ontime Attendance" },
  { field: "small", id: "mc", label: "MC", toolTipLabel: "Morning Checklist" },
  { field: "small", id: "infraction", label: "Infractions", toolTipLabel: "" },
  { field: "small", id: "reliability_score", label: "RS", toolTipLabel: "Reliability score" },
  { field: "small", id: "tenured_da", label: "Tenured DA", toolTipLabel: "" },
  { field: "small", id: "employee_satisfaction_survey", label: "ESS", toolTipLabel: "Employee Satisfaction Survey" },
  { field: "small", id: "activities", label: "" },
];

export const WEEK_TABLE_HEADERS_COLUMN = [
  { field: "small", id: "Sunday", label: "Sunday" },
  { field: "small", id: "Monday", label: "Monday" },
  { field: "small", id: "Tuesday", label: "Tuesday" },
  { field: "small", id: "Wednesday", label: "Wednesday" },
  { field: "small", id: "Thursday", label: "Thursday" },
  { field: "small", id: "Friday", label: "Friday" },
  { field: "small", id: "Saturday", label: "Saturday" },
];
