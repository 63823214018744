import { useState, useEffect, FC } from "react";
import { Colors } from "constants/constants";
import { Box, Typography, Tooltip as TooltipMui } from "@material-ui/core";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { handleGetVans } from "pages/Scorecard/requests";
import { DashboardMainChartType } from "types/DashboardRecord";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
);

interface Data {
  vans_data: {
    van__number: number;
    counter: number;
    van__van_group__fleet_ownership: number | null;
  }[];
  number_rental_vans: string;
  number_owned_vans: string;
  number_shifts_for_rental_vans: string;
  number_shifts_for_owned_vans: string;
  number_unregister_vans: string;
  unregister_vans: { number: number; counter: number }[];
}

export const VansChart: FC<DashboardMainChartType> = ({ station }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPast, setLoadingPast] = useState(false);
  const [requestData, setRequestData] = useState<Data | null>(null);
  const [requestPastData, setRequestPastData] = useState<Data | null>(null);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  const { pastMonthDate, pastWeekDate } = useDateWeekSelector({
    defaultWeek: date?.week,
    defaultMonth: date?.month,
  });

  const splitMonthDate = pastMonthDate.split(".");
  const splitWeekDate = pastWeekDate.split(".");
  const splitPastMonth = +splitMonthDate[0];
  const splitPastMonthYear = +splitMonthDate[1];
  const splitPastWeek = +splitWeekDate[0];
  const splitPastWeekYear = +splitWeekDate[1];

  useEffect(() => {
    if (selectType !== "daily") {
      const params = {
        station,
        year: +date?.year,
        ...(selectType === "month" ? { month: +date?.month } : { week: +date?.week }),
      };
      const pastParams = {
        station,
        year: selectType === "month" ? splitPastMonthYear : splitPastWeekYear,
        ...(selectType === "month" ? { month: splitPastMonth } : { week: splitPastWeek }),
      };

      handleGetVans(params, setRequestData, setLoading);
      handleGetVans(pastParams, setRequestPastData, setLoadingPast);
    }
  }, [
    station,
    date?.year,
    date?.month,
    date?.week,
    selectType,
    splitPastMonth,
    splitPastMonthYear,
    splitPastWeek,
    splitPastWeekYear,
  ]);

  const checkVanOwner = (val: number | null) => {
    switch (val) {
      case null:
        return Colors.PASTEL_YELLOW;
      case 1:
        return Colors.PASTEL_PINE_TREE;
      case 2:
        return Colors.BLUE;
    }
  };

  const newArrayData =
    requestData?.vans_data && requestData?.vans_data?.length > 20
      ? requestData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < 10 || index >= requestData?.vans_data.length - 11 ? el.van__number : null,
        )
      : requestData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < Math.round(requestData?.vans_data?.length / 2) ||
          index >
            requestData?.vans_data?.length - (Math.round(requestData?.vans_data?.length / 2) + 1)
            ? el.van__number
            : null,
        );

  const newArrayPastData =
    requestPastData?.vans_data && requestPastData?.vans_data?.length > 20
      ? requestPastData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < 10 || index >= requestPastData?.vans_data.length - 11 ? el.van__number : null,
        )
      : requestPastData?.vans_data.filter((el: { van__number: string | number }, index) =>
          index < Math.round(requestPastData?.vans_data?.length / 2) ||
          index >
            requestPastData?.vans_data?.length -
              (Math.round(requestPastData?.vans_data?.length / 2) + 1)
            ? el.van__number
            : null,
        );

  const labels = newArrayData
    ? newArrayData.map((el: { van__number: string | number }) => el.van__number).reverse()
    : [];

  const dataChart = {
    labels,
    datasets:
      selectType !== "daily"
        ? [
            {
              type: "line" as const,
              fill: false,
              label: "Counter",

              data: labels.map((elem) => {
                const newElem: any = newArrayPastData
                  ? newArrayPastData.find(
                      (el: { counter: number; van__number: string | number }) =>
                        elem === el.van__number,
                    )
                  : [];
                return newElem ? newElem?.counter : 0;
              }),
              backgroundColor: Colors.PASTEL_GREEN,
              borderColor: Colors.PASTEL_GREEN,
              tension: 0.4,
            },
            {
              type: "bar" as const,
              label: "Counter",
              data: newArrayData
                ? newArrayData
                    .reverse()
                    .map((el: { counter: number; van__number: string | number }) =>
                      el.counter ? el.counter : 0,
                    )
                : [],
              backgroundColor:
                newArrayData &&
                newArrayData.map(
                  (
                    el: {
                      van__number: string | number;
                      van__van_group__fleet_ownership: number | null;
                    },
                    index,
                  ) =>
                    el.van__number === labels[index]
                      ? checkVanOwner(el.van__van_group__fleet_ownership)
                      : Colors.LIGHT_BLUE,
                ),
              minBarLength: 3,
              barThickness: 15,
              borderRadius: 25,
            },
          ]
        : [],
  };

  let quadrants = {
    id: "quadrants",
    //@ts-ignore
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
        scales: { x, y },
      } = chart;
      let midX = x.getPixelForValue(Math.round(chart.data.labels.length / 2));
      const midY = y.getPixelForValue(0);
      ctx.restore();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, right - midX, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, midY, midX - left, bottom - midY);
      ctx.save();
    },
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: false,
          text: "Value",
        },
        min: 0,
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
      x: {
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: "Fleet Utilization",
      },
      quadrants: {
        topLeft: "rgb(207,226,243, 0.7)",
        topRight: "rgb(255,186,186, 0.3)",
      },
    },
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Typography variant="body2">Fleet Utilization</Typography>
        <Box style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "16px",
                height: "16px",
                background: Colors.PASTEL_YELLOW,
                display: "block",
                borderRadius: "5px",
              }}
            />
            <Typography variant="body2">Unregister</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "16px",
                height: "16px",
                background: Colors.PASTEL_PINE_TREE,
                display: "block",
                borderRadius: "5px",
              }}
            />
            <Typography variant="body2">Rental</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "16px",
                height: "16px",
                background: Colors.BLUE,
                display: "block",
                borderRadius: "5px",
              }}
            />
            <Typography variant="body2">Owned</Typography>
          </Box>
        </Box>
        <TooltipMui
          title={
            <Box>
              <Box style={{ padding: "5px" }}>
                <Typography>
                  Owned Vans: <b>{requestData?.number_owned_vans}</b>
                </Typography>
              </Box>
              <Box style={{ padding: "5px" }}>
                <Typography>
                  Owned Shifts: <b>{requestData?.number_shifts_for_owned_vans}</b>
                </Typography>
              </Box>
              <Box style={{ padding: "5px" }}>
                <Typography>
                  Rental Vans: <b>{requestData?.number_rental_vans}</b>
                </Typography>
              </Box>
              <Box style={{ padding: "5px" }}>
                <Typography>
                  Rental Shifts: <b>{requestData?.number_shifts_for_rental_vans}</b>
                </Typography>
              </Box>
              <Box style={{ padding: "5px" }}>
                <Typography>
                  Unregister Vans: <b>{requestData?.number_unregister_vans}</b>
                </Typography>
              </Box>
            </Box>
          }
        >
          <InfoOutlinedIcon />
        </TooltipMui>
        <TooltipMui
          title={
            <Box>
              <Typography>Unregister Vans List</Typography>
              {requestData?.unregister_vans.map((el) => (
                <Typography>
                  {el?.number}: <b>{el?.counter}</b>
                </Typography>
              ))}
            </Box>
          }
        >
          <LocalShippingOutlinedIcon />
        </TooltipMui>
      </Box>

      {!loading && !loadingPast && (
        //@ts-ignore
        <Chart options={options} data={dataChart} plugins={[quadrants]} />
      )}
      <Box style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "20px",
              height: "20px",
              background: Colors.PASTEL_RED,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Box
            style={{
              width: "20px",
              height: "20px",
              background: Colors.PASTEL_BLUE,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Typography variant="body2">Current data</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "30px",
              height: "10px",
              background: Colors.PASTEL_GREEN,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Typography variant="body2">Past data</Typography>
        </Box>
      </Box>
    </Box>
  );
};
