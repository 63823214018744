import { AuthConstants } from "constants/constants";
import { ReducersInterfaceAction } from "types/Reducers";
import { AuthState } from "types/Auth";
import { initialAuthState } from "store/initialStates";

export const authReducer = (
  state = initialAuthState,
  action: ReducersInterfaceAction,
): AuthState => {
  switch (action.type) {
    case AuthConstants.AUTH_MODAL_STATE:
      return {
        ...state,
        authModalState: !state.authModalState,
        authModalStateLogin: true,
      };
    case AuthConstants.PASSWORD_RESET_PENDING:
    case AuthConstants.AUTH_REFRESH_PENDING:
    case AuthConstants.AUTH_LOGIN_PENDING:
    case AuthConstants.USER_DATA_LOADING:
      return {
        ...state,
        isLoginPending: true,
      };
    case AuthConstants.AUTH_REFRESH_SUCCESS:
    case AuthConstants.AUTH_LOGIN_SUCCESS:
    case AuthConstants.PASSWORD_RESET_SUCCESS:
    case AuthConstants.AUTH_REFRESH_ERROR:
    case AuthConstants.AUTH_LOGIN_ERROR:
    case AuthConstants.AUTH_LOGOUT:
    case AuthConstants.USER_DATA_ERROR:
    case AuthConstants.PASSWORD_RESET_ERROR:
      return {
        ...state,
        isLoginPending: false,
      };
    case AuthConstants.MODAL_CONTAINER:
      return {
        ...state,
        authModalStateLogin: !state.authModalStateLogin,
      };
    case AuthConstants.USER_DATA_SUCCESS:
      return {
        ...state,
        isLoginPending: false,
        userData: action.payload,
      };
    default:
      return state;
  }
};
