export const defaultUserData = {
  autocreated: false,
  date_joined: "",
  date_of_birth: "",
  email: "",
  first_name: "",
  id: 0,
  is_staff: false,
  is_superuser: false,
  last_login: "",
  last_name: "",
  mobile_number: "",
  role: 0,
  role_name: "",
  training_delivered: false,
  transporter_id: "",
  van_group: {
    description: "",
    id: 0,
    name: "",
    supervisor: 0,
  },
  personal_email: "",
  whatsapp_mobile_number: "",
  driver_license: "",
  driver_license_expiry: "",
  knet_user: "",
  agency_name: {
    description: "",
    id: 0,
    name: "",
  },
  station_spots: [],
  can_create_old_shifts: false,
};
