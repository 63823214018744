export const TABLE_HEADERS_COLUMN_ONE = [
  { field: "small", id: "action-date", label: "Action Date", colSpan:2 },
  { field: "small", id: "empty-one", label: "", colSpan:1 },
  { field: "small", id: "empty-two", label: "", colSpan:1 },
  { field: "small", id: "empty-three", label: "", colSpan:1 },
  { field: "small", id: "empty-four", label: "", colSpan:1 },
  { field: "small", id: "empty-five", label: "", colSpan:1 },
  { field: "small", id: "empty-six", label: "", colSpan:1 },
  { field: "small", id: "empty-seven", label: "", colSpan:1 },
  { field: "small", id: "empty-eight", label: "", colSpan:1 },
  { field: "small", id: "empty-nine", label: "", colSpan:1 },
];

export const TABLE_HEADERS_COLUMN_TWO = [
  { field: "small", id: "week_number", label: "Week", hasSortLabel: true },
  { field: "small", id: "date", label: "Shift", hasSortLabel: true },
  { field: "small", id: "off_target_area", label: "Off Target Area", hasSortLabel: true },
  { field: "small", id: "action", label: "Action", hasSortLabel: true },
  { field: "small", id: "driver_fullname", label: "DA Name", hasSortLabel: true },
  { field: "small", id: "transporter_id", label: "Transporter ID", hasSortLabel: true },
  { field: "small", id: "owner", label: "Owner", hasSortLabel: true },
  { field: "small", id: "due_date", label: "Due Date", hasSortLabel: true },
  { field: "small", id: "status", label: "Status", hasSortLabel: true },
  { field: "small", id: "coaching_count", label: "Coaching Count", hasSortLabel: true },
  { field: "small", id: "comments", label: "Comments", hasSortLabel: true },
];