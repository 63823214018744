import {
    TableRow,
    TableCell,
    Typography,
} from "@material-ui/core";
import { getCheckAverageScoreData } from "actions/checkActions";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CheckAverageScoreData } from "types/Check";
import { useStyles } from "./styles";
import { formatNumberType } from "utils/formatNUmber";

interface CheckAverageScoreRowProps {
    data:CheckAverageScoreData | null,
    loadingData: boolean,
}

export const CheckAverageScoreRow:FC<CheckAverageScoreRowProps> = ({data, loadingData}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getCheckAverageScoreData())
    },[loadingData, dispatch])

    return ( 
        <TableRow hover>
            <TableCell
                colSpan={2}
                className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}
            >
                Average
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.dcr)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.dcr_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.dnr)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.dnr_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.dsb)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.dsb_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.pod)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.pod_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.violation)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.violation_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.rescue_given)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.rescue_given_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.mc)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.mc_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.oa)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.oa_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.cc)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.cc_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.ada)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.ada_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.whc)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.whc_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.hp6w)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.hp6w_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.cas)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                    <Typography>{Math.round(Number(data?.cas_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.tableCellPaddingLeft}`}>
                <Typography>{formatNumberType(data?.employee_satisfaction)}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell} ${classes.rightBorderCell}`}>
                <Typography>{Math.round(Number(data?.employee_satisfaction_da_off_target))}</Typography>
            </TableCell>
            <TableCell className={`${classes.tableCell} ${classes.tableBoldBorderCell}`}></TableCell>
        </TableRow>
    )
}