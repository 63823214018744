import { Paper, TablePagination } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useStyles } from "./styles";
import { actTableWeeklyChangeRowsPerPage, getActTableInfo, setActTablePagginationPage } from "actions/actActions";
import { ActFiltersButton } from "components/buttons/ActFiltersButton";

interface PropsType {}

export const ActTableFilter:FC<PropsType> = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { dataTableRowsPerPage, dataListCount, reportListFilters } = useTypeSelectors(
        (state) => state.actTable
    );
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(actTableWeeklyChangeRowsPerPage(+event.target.value));
        dispatch(setActTablePagginationPage(0));
    };

    const handleChangePagination = (event: unknown, value: number) => {
        dispatch(setActTablePagginationPage(value));
    };

    useEffect(() => {
        dispatch(
          getActTableInfo(
            reportListFilters?.pageNumber + 1,
            dataTableRowsPerPage,
            reportListFilters?.action,
            reportListFilters?.owner,
            reportListFilters?.status,
            reportListFilters?.coachingCount,
            reportListFilters?.dueDate,
            reportListFilters?.offTargetArea,
            reportListFilters?.driver,
            reportListFilters?.weekDate,
            reportListFilters?.ordering,
        ));
    }, [
        dataTableRowsPerPage, 
        reportListFilters?.pageNumber,
        reportListFilters,
        dispatch
    ]);

    return (
        <Paper className={classes.filter}>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={dataListCount}
                rowsPerPage={dataTableRowsPerPage}
                page={reportListFilters?.pageNumber}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePagination}
            />
            <ActFiltersButton />
        </Paper>
    )
}