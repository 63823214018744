import { FC, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Collapse, List, Button, Typography } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useLocation } from "react-router";
import { Colors } from "constants/constants";
import { IconElement } from "types/general";

interface AutoSelectProps {
  Icon: IconElement;
  data: {
    text: string;
    subList: SubMenuProps[];
  };
  mainClasses: {[key: string]: string};
  isOptionActive: boolean;
  isMenuOpen: boolean;
}

interface SubMenuProps {
  Icon: IconElement;
  path: string;
  link: () => void;
  text: string;
}

export const CollapseSubMenu: FC<AutoSelectProps> = ({
    Icon,
    data,
    mainClasses,
    isOptionActive,
    isMenuOpen
  }) => {
  const classes = useStyles();
  const params = useLocation();
  const [active, setActive] = useState(false);

  return (
    <>
      <Button
        fullWidth
        color="primary"
        variant={isOptionActive ? "contained" : "text"}
        onClick={()=>setActive(!active)}
        disableElevation
        className={mainClasses.linkMenu}
        style={!isMenuOpen ? { justifyContent: "center" } : {}}
      >
        <Icon style={{ color: "currentColor" }} />
        {isMenuOpen && (
          <Typography variant="body2" style={{ marginLeft: 10 }}>
            {data.text}
          </Typography>
        )}
        {active ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Collapse
        style={{
          width: "100%",
          background: "#0a3e67",
        }}
        in={active}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
        >
          {
            data.subList.map(({Icon, ...el}: SubMenuProps)=>{
              const isActiveSubMenu =
                  params?.pathname === el.path ||
                  params?.pathname.split("/")[1] === el.path.split("/")[1];
              return (
                <Button
                  fullWidth
                  color="primary"
                  variant={isActiveSubMenu ? "contained" : "text"}
                  onClick={el.link}
                  disableElevation
                  className={`${classes.subLinkMenu} ${!isMenuOpen ? "activeSubLink" : ""}`}
                  style={!isMenuOpen ? { justifyContent: "center" } : {}}
                >
                  <Icon style={{ color: "currentColor" }} />
                  {isMenuOpen && (
                    <>
                      <Typography variant="body2" style={{ marginLeft: 10 }}>
                        {el.text}
                      </Typography>
                    </>
                  )}
                </Button>
              )
            })
          }
        </List>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subLinkMenu: {
      borderRadius: 0,
      width: "100%",
      height: 64,
      display: "flex",
      justifyContent: "flex-start",
      padding: theme.spacing(3, 3, 3, 5),
      color: Colors.WHITE,
      "&.MuiButton-contained": {
        background: "#2d72a7"
      },
      "&.MuiButton-text:hover": {
        background: "#072f4e"
      },
      "&.activeSubLink": {
        justifyContent: "center",
        padding: theme.spacing(0),
      }
    },
  })
);