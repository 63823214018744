import { FC } from "react";
import { Box, Typography, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface PeriodSelectProps {
  startDate: string | Date;
  endDate: string | Date;
  onStartDateChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onEndDateChange: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled?: boolean;
}

export const PeriodSelect: FC<PeriodSelectProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Box data-testid="testPeriodSelect" className={classes.dateBox}>
      <TextField
        InputProps={{ inputProps: {  
          "data-testid": "testPeriodSelectInputOne" 
        }}}
        name="start_date"
        type="date"
        className={classes.textField}
        value={startDate}
        onChange={onStartDateChange}
        disabled={disabled}
      />
      <Typography variant="body2">To</Typography>
      <TextField
        InputProps={{ inputProps: {  
          "data-testid": "testPeriodSelectInputTwo" 
        }}}
        name="end_date"
        type="date"
        className={classes.textField}
        value={endDate}
        onChange={onEndDateChange}
        disabled={disabled}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  dateBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 10px",
    justifyContent: "center",
  },
  textField: {
    padding: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
