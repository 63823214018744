import { useState, useEffect, FC } from "react";
import { Colors } from "constants/constants";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { handleGetPerformers } from "pages/Scorecard/requests";
import { DashboardMainChartType } from "types/DashboardRecord";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
);

export const HighPerformersChart: FC<DashboardMainChartType> = ({ station }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPast, setLoadingPast] = useState(false);
  const [requestDataWorst, setRequestDataWorst] = useState([]);
  const [requestDataBest, setRequestDataBest] = useState([]);
  const [requestPastDataWorst, setRequestPastDataWorst] = useState([]);
  const [requestPastDataBest, setRequestPastDataBest] = useState([]);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  const { pastMonthDate, pastWeekDate } = useDateWeekSelector({
    defaultWeek: date?.week,
    defaultMonth: date?.month,
  });

  const splitMonthDate = pastMonthDate.split(".");
  const splitWeekDate = pastWeekDate.split(".");
  const splitPastMonth = +splitMonthDate[0];
  const splitPastMonthYear = +splitMonthDate[1];
  const splitPastWeek = +splitWeekDate[0];
  const splitPastWeekYear = +splitWeekDate[1];

  useEffect(() => {
    if (selectType !== "daily") {
      const params = {
        station,
        year: +date?.year,
        ...(selectType === "month" ? { month: +date?.month } : { week: +date?.week }),
      };
      const pastParams = {
        station,
        year: selectType === "month" ? splitPastMonthYear : splitPastWeekYear,
        ...(selectType === "month" ? { month: splitPastMonth } : { week: splitPastWeek }),
      };

      handleGetPerformers(
        params,
        (data) => {
          setRequestDataBest(data?.best);
          setRequestDataWorst(data?.worst);
        },
        setLoading,
      );
      handleGetPerformers(
        pastParams,
        (data) => {
          setRequestPastDataBest(data?.best);
          setRequestPastDataWorst(data?.worst);
        },
        setLoadingPast,
      );
    }
  }, [
    station,
    date?.year,
    date?.month,
    date?.week,
    selectType,
    splitPastMonth,
    splitPastMonthYear,
    splitPastWeek,
    splitPastWeekYear,
  ]);

  const newArrayData = [...requestDataBest, ...requestDataWorst.slice().reverse()];
  const newArrayPastData = [
    ...requestPastDataBest,
    ...requestPastDataWorst.slice().reverse(),
  ].reverse();

  const labels =
    newArrayData.length > 10
      ? newArrayData
          .map(
            (el: { driver__first_name: string; driver__last_name: string }) =>
              `${el.driver__first_name} ${el.driver__last_name}`,
          )
          .reverse()
      : requestDataBest
          .map(
            (el: { driver__first_name: string; driver__last_name: string }) =>
              `${el.driver__first_name} ${el.driver__last_name}`,
          )
          .reverse();

  const dataChart = {
    labels,
    datasets:
      selectType !== "daily"
        ? [
            {
              type: "line" as const,
              fill: false,
              label: "Counter",
              data: labels.map((elem) => {
                const newElem: any = newArrayPastData.find(
                  (el: { sum: number; driver__first_name: string; driver__last_name: string }) =>
                    elem === `${el.driver__first_name} ${el.driver__last_name}`,
                );
                return newElem ? newElem?.sum : 0;
              }),
              backgroundColor: Colors.PASTEL_GREEN,
              borderColor: Colors.PASTEL_GREEN,
              tension: 0.4,
            },
            {
              type: "bar" as const,
              label: "Counter",
              data: newArrayData
                .reverse()
                .map(
                  (el: { sum: number; driver__first_name: string; driver__last_name: string }) =>
                    el.sum ? el.sum : 0,
                ),
              backgroundColor:
                newArrayData.length >= 20
                  ? newArrayData
                      .reverse()
                      .map((_, index) =>
                        index < Math.round(newArrayData.length / 2)
                          ? Colors.PASTEL_RED
                          : Colors.PASTEL_BLUE,
                      )
                  : newArrayData.length > 10 && newArrayData.length < 20
                    ? newArrayData
                        .reverse()
                        .map((_, index) =>
                          index < Math.round(newArrayData.length / 2)
                            ? Colors.PASTEL_RED
                            : Colors.PASTEL_BLUE,
                        )
                    : requestDataBest.map(() => Colors.PASTEL_BLUE),
              minBarLength: 3,
              barThickness: 15,
              borderRadius: 25,
            },
          ]
        : [],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading && loadingPast ? (
        <CircularProgress />
      ) : (
        //@ts-ignore
        <Chart options={options} data={dataChart} />
      )}
      <Box style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "20px",
              height: "20px",
              background: Colors.PASTEL_RED,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Box
            style={{
              width: "20px",
              height: "20px",
              background: Colors.PASTEL_BLUE,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Typography variant="body2">Current data</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "30px",
              height: "10px",
              background: Colors.PASTEL_GREEN,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Typography variant="body2">Past data</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const options = {
  responsive: true,
  scales: {
    y: {
      title: {
        display: false,
        text: "Value",
      },
      min: 0,
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: true,
      text: "Top/Lowest Performers",
    },
  },
};
