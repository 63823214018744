import { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@material-ui/core";
import { AuthForm } from "components/elements/AuthForm";
import { localUserService } from "utils/localStorage";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useNav } from "hooks/useNav";
import Person from "@material-ui/icons/Person"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  boxButton: {
    width: "100%",
    height: "100%",
  },
  buttonModal: {
    padding: "12px",
    border: "0px",
    margin: "0px",
    color: "#00324f",

    "&:hover": {
      background: "#00324f",
      color: "#fff",
    },
  },
  account: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.primary.main,
    background: "white",
  },
  avatarIcon: {
    fontSize: 30,
  },
  userName: {
    textAlign: "end",
  },
  tittle: {
    textAlign: "end",
  },
}));

export const Auth = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { viewAccount } = useNav();
  const { userData } = useTypeSelectors((state) => state.auth);
  const localAuthToken = localUserService.getAccess();
  const handleGotoAccount = () => {
    viewAccount();
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localUserService.logout();
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box className={classes.boxButton}>
        {localAuthToken ? (
          <Box className={classes.account} onClick={handleClick}>
            <Box>
              <Typography variant="h6" className={classes.userName}>
                {userData.first_name} {userData.last_name}
              </Typography>
              <Typography variant="body2" className={classes.tittle}>
                {userData.role_name}
              </Typography>
            </Box>
            <Avatar className={classes.avatar}>
              <Person className={classes.avatarIcon}/>
            </Avatar>
          </Box>
        ) : (
          <IconButton
            aria-label="open drawer"
            size="medium"
            color="inherit"
            className={classes.buttonModal}
            onClick={() => setOpen(true)}
          >
            <PersonOutlineIcon />
          </IconButton>
        )}
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleGotoAccount}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box>
          <AuthForm />
        </Box>
      </Modal>
    </div>
  );
};
