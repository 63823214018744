import { useState, useEffect, FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box } from "@material-ui/core";
import { Colors } from "constants/constants";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { handleGetReliabilityScores } from "./requests";
import { formatNumberType } from "utils/formatNUmber";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ReliabilityChartData {}

interface LabelContent {
  date: string;
  week_number: number;
  date__month: number;
  date__year: number;
  capacity_reliability_score: number;
}

export const ReliabilityChart: FC<ReliabilityChartData> = () => {
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  useEffect(() => {
    const params = {
      station: perfomanceSettings.station,
      ...(selectType === "month" && { month: +date?.month, year: +date?.year }),
      ...(selectType === "week" && { single_week: +date?.week, year: +date?.year }),
      ...(selectType === "daily" && { week: +date?.week, year: +date?.year }),
      ...(selectType === "annual" && { year: +date?.year }),
    };
    handleGetReliabilityScores(params, setLoading, setRequestData);
  }, [selectType, date?.month, date?.year, date?.week, perfomanceSettings.station]);

  const labels = requestData?.map((el: LabelContent) =>
    el.date ? el.date : el.week_number !== null && `Week${el.week_number + 1}`,
  );

  const dataChart = {
    labels,
    datasets: [
      {
        label: "",
        data: requestData?.map((el: LabelContent, index: number) =>
          getLabel(el, selectType) === labels[index] ? el.capacity_reliability_score : null,
        ),
        backgroundColor: requestData?.map((el: LabelContent, index: number) =>
          getLabel(el, selectType) === labels[index] ? Colors.PASTEL_BLUE : "#fff",
        ),
        minBarLength: 3,
        barThickness: 15,
        borderRadius: 25,
      },
    ],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!loading && (
        //@ts-ignore
        <Bar options={options} data={dataChart} />
      )}
    </Box>
  );
};

const options = {
  responsive: true,
  scales: {
    y: {
      title: {
        display: false,
        text: "Value",
      },
      min: 90,
      ticks: {
        callback: function (value: number) {
          return value + "%";
        },
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: true,
      text: "Reliability Scorecard",
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          let label = "";
          if (context.parsed.y) {
            label = formatNumberType(context.parsed.y) + "%";
          }
          return label;
        },
      },
    },
  },
};

const getLabel = (elementLabel: LabelContent, selectType: string | undefined) => {
  switch (selectType) {
    case "daily":
      return elementLabel.date;
    case "week":
    case "month":
    case "annual":
      return `Week${elementLabel.week_number + 1}`;
  }
};
