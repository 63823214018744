import { useTypeSelectors } from "hooks/useTypeSelectors";

import { MissingDeliveriesChart } from "./PerformanceCharts/MissingDeliveriesChart";
import { CashOnDeliveryChart } from "./PerformanceCharts/CashOnDeliveryChart";
import { DARWorstChart } from "./PerformanceCharts/DARWorstChart";
import { TotalWeeklyChart } from "./PerformanceCharts/TotalWeeklyChart";
import { VansChart } from "./PerformanceCharts/VansChart";
import { HighDaShiftsChart } from "./PerformanceCharts/HighDaShiftsChart";
import { RolesChart } from "./PerformanceCharts/RolesChart";
import { ScoreTrendChart } from "./PerformanceCharts/ScoreTrendChart";
import { HighPerformersChart } from "./PerformanceCharts/HighPerformersChart";

export const RenderMainScoreboard = () => {
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);
  const currentStation = perfomanceSettings?.station || "1";

  switch (perfomanceSettings?.currentScorecard) {
    case "TL_PERFORMANCE":
      return <HighPerformersChart station={currentStation} />;
    case "HL_DA_SHIFTS":
      return <HighDaShiftsChart station={currentStation} />;
    case "ROLES":
      return <RolesChart station={currentStation} />;
    case "DA_SCORE_TREND":
      return <ScoreTrendChart station={currentStation} />;
    case "TL_VANS":
      return <VansChart station={currentStation} />;
    case "MISSING_DELIVERIES":
      return <MissingDeliveriesChart station={currentStation} />;
    case "CASH_ON_DELIVERY":
      return <CashOnDeliveryChart station={currentStation} />;
    case "WORST_OF_DAR":
      return <DARWorstChart station={currentStation} />;
    case "TOTAL_SCORE_TREND_ON_WEEKLY_BASIS":
      return <TotalWeeklyChart station={currentStation} />;
  }
};
