import { useState, useEffect, FC } from "react";
import { Colors } from "constants/constants";
import { Box, Typography } from "@material-ui/core";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { DashboardMainChartType } from "types/DashboardRecord";
import { handleGetMissingDeliveries } from "./requests";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useTypeSelectors } from "hooks/useTypeSelectors";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  BarElement,
  LineController,
  BarController,
);

export const MissingDeliveriesChart: FC<DashboardMainChartType> = ({ station }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPast, setLoadingPast] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [requestPastData, setRequestPastData] = useState([]);
  const { perfomanceSettings } = useTypeSelectors((state) => state.perfomanceScores);

  const { date, selectType } = perfomanceSettings;

  const { pastMonthDate, pastWeekDate } = useDateWeekSelector({
    defaultWeek: date?.week,
    defaultMonth: date?.month,
  });

  const splitMonthDate = pastMonthDate.split(".");
  const splitWeekDate = pastWeekDate.split(".");
  const splitPastMonth = +splitMonthDate[0];
  const splitPastMonthYear = +splitMonthDate[1];
  const splitPastWeek = +splitWeekDate[0];
  const splitPastWeekYear = +splitWeekDate[1];

  useEffect(() => {
    if (selectType !== "daily") {
      const params = {
        station,
        year: +date.year,
        ...(selectType === "month" ? { month: +date.month } : { week: +date.week }),
      };
      const pastParams = {
        station,
        year: selectType === "month" ? splitPastMonthYear : splitPastWeekYear,
        ...(selectType === "month" ? { month: splitPastMonth } : { week: splitPastWeek }),
      };

      handleGetMissingDeliveries(params, setLoading, setRequestData);
      handleGetMissingDeliveries(pastParams, setLoadingPast, setRequestPastData);
    }
  }, [
    station,
    date?.year,
    date?.month,
    date?.week,
    selectType,
    splitPastMonth,
    splitPastMonthYear,
    splitPastWeek,
    splitPastWeekYear,
  ]);

  const newArrayData = requestData.filter((el: { name: string; counter: number }, index) =>
    el.counter > 0 ? el.name : null,
  );

  const newArrayPastData = requestPastData.filter((el: { name: string; counter: number }, index) =>
    el.counter > 0 ? el.name : null,
  );

  const labels = newArrayData.map((el: { name: string }) => el.name).reverse();

  const counterArray = requestData.map((el: { counter: number }) => el.counter);
  const total = counterArray.length > 0 ? counterArray.reduce((a, b) => a + b) : 0;

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: false,
          text: "Value",
        },
        min: 0,
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: true,
        text: `Missing deliveries by DA, Total: ${total}`,
      },
    },
  };

  const dataChart = {
    labels,
    datasets:
      selectType !== "daily"
        ? [
            {
              type: "line" as const,
              fill: false,
              label: "Counter",
              data: labels.map((elem) => {
                const newElem: any = newArrayPastData.find(
                  (el: { counter: number; name: string }) => elem === el.name,
                );
                return newElem ? newElem?.counter : 0;
              }),
              backgroundColor: Colors.PASTEL_GREEN,
              borderColor: Colors.PASTEL_GREEN,
              tension: 0.4,
            },
            {
              type: "bar" as const,
              label: "Counter",
              data: newArrayData
                .reverse()
                .map((el: { counter: number; name: string }, index) =>
                  el.name === labels[index] ? el.counter : null,
                ),
              backgroundColor: newArrayData.map((el: { counter: number }) =>
                el.counter > 0 ? Colors.PASTEL_RED : Colors.PASTEL_BLUE,
              ),
              minBarLength: 3,
              barThickness: 15,
              borderRadius: 25,
            },
          ]
        : [],
  };

  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!loading && !loadingPast && (
        //@ts-ignore
        <Chart options={options} data={dataChart} />
      )}
      <Box style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "20px",
              height: "20px",
              background: Colors.PASTEL_RED,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Box
            style={{
              width: "20px",
              height: "20px",
              background: Colors.PASTEL_BLUE,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Typography variant="body2">Current data</Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "30px",
              height: "10px",
              background: Colors.PASTEL_GREEN,
              display: "block",
              borderRadius: "5px",
            }}
          />
          <Typography variant="body2">Past data</Typography>
        </Box>
      </Box>
    </Box>
  );
};
