import { CustomGraphsConstants } from "constants/constants";
import { initialCustomGraphState } from "store/initialStates";
import { ReducersInterfaceAction } from "types/Reducers";
import { CustomGraphsState } from "types/CustomGraphs";

export const customGraphReducer = (
  state = initialCustomGraphState,
  action: ReducersInterfaceAction,
): CustomGraphsState => {
  switch (action.type) {
    case CustomGraphsConstants.CUSTOM_GRAPHS_ACTION_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case CustomGraphsConstants.CUSTOM_GRAPHS_ACTION_SUCCESS:
      return {
        ...state,
        loadingData: false,
        pageData: action.payload,
      };
    case CustomGraphsConstants.CUSTOM_GRAPHS_ACTION_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case CustomGraphsConstants.CUSTOM_GRAPHS_CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case CustomGraphsConstants.CUSTOM_GRAPHS_PAGE_DELETE_SUCCESS:
      return {
        ...state,
        loadingData: false,
        ...action.payload,
      };
    case CustomGraphsConstants.CUSTOM_GRAPHS_CREATE_BOARD_SUCCESS:
      return {
        ...state,
        loadingData: false,
        boards: action.payload,
      };
    case CustomGraphsConstants.GET_CUSTOM_GRAPHS_ENDPOINTS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        endpoints: action.payload,
      };
    default:
      return state;
  }
};
