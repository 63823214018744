import { Dispatch } from "redux";
import { ScorecardConstants } from "constants/constants";

export const ScorecardExternalSettingsActive =
  (data: boolean, station: string) => (dispatch: Dispatch) => {
    dispatch({
      type: ScorecardConstants.EXTERNAL_SETTINGS_ACTIVE,
      payload: { data, station },
    });
  };

export const ScorecardSetStation = (data: string, dispatchType: string) => (dispatch: Dispatch) => {
  dispatch({
    type: dispatchType,
    payload: data,
  });
};

export const ScorecardSetSelectType =
  (data: string, dispatchType: string) => (dispatch: Dispatch) => {
    dispatch({
      type: dispatchType,
      payload: data,
    });
  };

export const ScorecardExternalSetDate =
  (
    data: {
      week: string;
      month: string;
      year: string;
      startDate?: string;
      endDate?: string;
      day?: string;
    },
    dispatchType: string
  ) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: dispatchType,
      payload: data,
    });
  };
