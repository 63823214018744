import { CheckReportWeekRow } from "types/Check";
import { Box, Input, TableCell } from "@material-ui/core";
import { CheckTableCellPaint } from "components/elements/CheckTableCellPaint";
import Alert, { Color } from "@material-ui/lab/Alert";
import { inputNumberValidate, inputNumberValidateOnPaste } from "utils/formats";
import { useStyles } from "./styles";
import { OnBlurEvent } from "types/general";

export const RenderInfo = (
  key: string,
  data: CheckReportWeekRow,
  globalTarget: { name: string; target: string | number | undefined | null },
  handleDataChange: (id: number, name: string, value: number) => void,
  premissionToEdit: boolean,
) => {
  const classes = useStyles();
  const params = {
    alertClassName: classes.greenAlert,
    className: classes.greenCell,
    severity: "success",
  };

  switch (key) {
    case "dcr":
      if (Number(globalTarget.target) > data?.dcr!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.dcr}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.dcr_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "dnr":
      if (data?.dnr! > Number(globalTarget.target)) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.dnr}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.dnr_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "dsb":
      if (data?.dsb! > Number(globalTarget.target)) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.dsb}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.dsb_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "pod":
      if (Number(globalTarget.target) > data?.pod!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.pod}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.pod_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "violation":
      if (Number(globalTarget.target) > data?.violation!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.violation}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.violation_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "rd":
      if (
        Number(data?.rescue_given !== undefined ? data?.rescue_given : 0) <
        Number(globalTarget.target)
      ) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.rescue_given}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.rescue_given_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "mc":
      if (Number(globalTarget.target) > data?.mc!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.mc}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.mc_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "oa":
      if (Number(globalTarget.target) > data?.oa!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.oa}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.oa_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "hp6w":
      if (Number(globalTarget.target) > data?.hp6w!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.hp6w}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.hp6w_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "cc":
      if (Number(globalTarget.target) > data?.cc!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.cc}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.cc_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "ada":
      if (Number(globalTarget.target) > data?.ada!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.ada}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.ada_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "whc":
      if (Number(globalTarget.target) > data?.whc!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.whc}
              conditionDay
              isNumber
            />
          </TableCell>
          {premissionToEdit && (
            <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
              <Alert
                className={params.alertClassName}
                icon={false}
                severity={params.severity as Color}
                style={{
                  width: 60,
                  padding: "0",
                  height: 35,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Input
                  defaultValue={Math.round(Number(data?.whc_da_off_target))}
                  name={`whc_da_off_target`}
                  onBlur={(e: OnBlurEvent) =>
                    handleDataChange(data.id, "whc_da_off_target", e?.target?.value)
                  }
                  onKeyPress={inputNumberValidate}
                  onPaste={inputNumberValidateOnPaste}
                  inputProps={{
                    style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
                    "data-testid": "renderInfoWhcInput",
                  }}
                  disabled={!premissionToEdit}
                  disableUnderline={!premissionToEdit}
                />
              </Alert>
            </TableCell>
          )}
        </>
      );
    case "cas":
      if (Number(globalTarget.target) > data?.cas!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
            <CheckTableCellPaint
              alertStyle={params.severity}
              alertBackgoundStyle={params.alertClassName}
              typographyStyle={params.className}
              value={data?.cas}
              conditionDay
              isNumber
            />
          </TableCell>
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.cas_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    case "es":
      if (Number(globalTarget.target) > data?.employee_satisfaction!) {
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
      }
      return (
        <>
          {premissionToEdit && (
            <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
              <Alert
                className={params.alertClassName}
                icon={false}
                severity={params.severity as Color}
                style={{
                  width: 60,
                  padding: "0",
                  height: 35,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Input
                  defaultValue={Math.round(Number(data?.employee_satisfaction))}
                  name={`employee_satisfaction`}
                  onBlur={(e: OnBlurEvent) =>
                    handleDataChange(data.id, "employee_satisfaction", e?.target?.value)
                  }
                  onKeyPress={inputNumberValidate}
                  onPaste={inputNumberValidateOnPaste}
                  inputProps={{
                    style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
                    "data-testid": "renderInfoEsInput",
                  }}
                  disabled={!premissionToEdit}
                  disableUnderline={!premissionToEdit}
                />
              </Alert>
            </TableCell>
          )}
          <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
            <Box>
              <CheckTableCellPaint
                alertStyle={params.severity}
                alertBackgoundStyle={params.alertClassName}
                typographyStyle={params.className}
                value={data?.employee_satisfaction_da_off_target}
                conditionDay
                isNumber
              />
            </Box>
          </TableCell>
        </>
      );
    default:
      return <TableCell colSpan={2} />;
  }
};
