import { Button, IconButton } from "@material-ui/core"
import { IconElement } from "types/general";

interface OpenButtonProps {
    className: string;
    color?: string;
    onClick: () => void;
    buttonText: string;
    variant: string;
    props?: any;
}

interface IconOpenButtonProps {
    Icon: IconElement;
    onClick: () => void;
}

export const OpenButton = ({
    className,
    color,
    onClick,
    buttonText,
    variant,
    props,
}: OpenButtonProps) => {
    return (
        <Button
            data-testid="filterDrawerButton"
            className={className}
            variant={variant}
            color={color}
            onClick={onClick}
            {...props}
        >
            {buttonText}
        </Button>
    )
}

export const IconOpenButton = ({
    Icon,
    onClick,
}: IconOpenButtonProps) => {
    return (
        <IconButton 
            data-testid="filterDrawerIconButton"
            color="primary"
            onClick={onClick}
        >
            <Icon />
        </IconButton>
    )
} 