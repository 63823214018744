import { FC, useState } from "react";
import { Badge, ButtonProps } from "@material-ui/core";
import { ActFiltersDrawer } from "components/drawers/ActFiltersDrawer";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { setActTableFilters } from "actions/actActions";
import { actOffTargetAreaConstants } from "constants/constants";
import { useStyles } from "./styles";
import { OpenButton } from "./Buttons";

interface ActFiltersButtonProps extends ButtonProps {}

export const ActFiltersButton: FC<ActFiltersButtonProps> = (props) => {
  const classes = useStyles();
  const { reportListFilters } = useTypeSelectors((state) => state.actTable);
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  
  const selectFilterCondition =
    Boolean(reportListFilters?.weekDate) ||
    Boolean(reportListFilters?.driver) ||
    Boolean(reportListFilters?.offTargetArea) ||
    Boolean(reportListFilters?.action) ||
    Boolean(reportListFilters?.owner) ||
    Boolean(reportListFilters?.dueDate) ||
    Boolean(reportListFilters?.status) ||
    Boolean(reportListFilters?.coachingCount);

  const selectFilterBadge =
    Number(Boolean(reportListFilters?.weekDate)) +
    Number(Boolean(reportListFilters?.driver)) +
    Number(Boolean(reportListFilters?.offTargetArea)) +
    Number(Boolean(reportListFilters?.action)) +
    Number(Boolean(reportListFilters?.owner)) +
    Number(Boolean(reportListFilters?.dueDate)) +
    Number(Boolean(reportListFilters?.status)) +
    Number(Boolean(reportListFilters?.coachingCount))

  return (
    <>
      <Badge
        className={classes.badge}
        badgeContent={selectFilterBadge}
        color="error"
      >
        <OpenButton
          buttonText={"Filters"}
          variant={"outlined"}
          className={classes.buttonClass}
          color={selectFilterCondition ? "secondary" : "primary"}
          onClick={onOpen}
          props={props}
        />
      </Badge>
      <ActFiltersDrawer
        open={open}
        onClose={onClose}
        offTragetAreaConstants={actOffTargetAreaConstants}
        filters={reportListFilters}
        filterAction={setActTableFilters}
      />
    </>
  );
};
