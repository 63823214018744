import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Colors, ScorecardConstants } from "constants/constants";
import { handleGetLaunchpadSupport } from "../requests";
import { StationSelect } from "components/elements/StationSelect";
import { SearchTypeSelect } from "components/elements/SearchTypeSelect";
import { MonthSelect } from "components/elements/MonthSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useStyles } from "./styles";
import { ScorecardOpenButton } from "components/buttons/ScorecardOpenButton";
import { ScorecardCompareButton } from "components/buttons/ScorecardCompareButton";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useDispatch } from "react-redux";
import {
  ScorecardExternalSetDate,
  ScorecardSetSelectType,
  ScorecardSetStation,
} from "actions/scorecardActions";
import { DatePickWithArrow } from "components/elements/DatePickWithArrow";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const LaunchpadSupport = ({ withModal = true, withCompare = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useTypeSelectors((state) => state.auth);
  const { externalSettingActive, externalSettings, launchpadSupportSettings } = useTypeSelectors(
    (state) => state.scoreCardSettings
  );
  const defaultStationCondition = userData?.station_spots.some(
    (el: { station: { title: string; id: number } }) => el.station.id === 1
  )
    ? 1
    : userData?.station_spots[0]?.station?.id;
  const [loading, setLoading] = useState(false);
  const [compareStation, setCompareStation] = useState(`${defaultStationCondition}`);
  const [requestData, setRequestData] = useState([]);

  const handleChangeStation = (data: string) =>
    !withCompare
      ? setCompareStation(data)
      : dispatch(ScorecardSetStation(data, ScorecardConstants.LAUNCHPAD_SUPPORT_STATION));
  const handleChangeSelectType = (select: string) =>
    dispatch(ScorecardSetSelectType(select, ScorecardConstants.LAUNCHPAD_SUPPORT_SELECT_TYPE));
  const handleChangeWeek = (week: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...launchpadSupportSettings?.date, week },
        ScorecardConstants.LAUNCHPAD_SUPPORT_DATE
      )
    );
  const handleChangeMonth = (month: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...launchpadSupportSettings?.date, month },
        ScorecardConstants.LAUNCHPAD_SUPPORT_DATE
      )
    );
  const handleChangeYear = (year: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...launchpadSupportSettings?.date, year },
        ScorecardConstants.LAUNCHPAD_SUPPORT_DATE
      )
    );

  const handleChangeDayDate = (date: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...launchpadSupportSettings?.date, day: date },
        ScorecardConstants.LAUNCHPAD_SUPPORT_DATE
      )
    );

  const {
    week,
    month,
    year,
    selectType,
    date,
    onTypeChange,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onDateChange,
    onDateChangeByDay,
  } = useDateWeekSelector({
    defaultWeek: launchpadSupportSettings?.date?.week,
    defaultMonth: launchpadSupportSettings?.date?.month,
    defaultYear: launchpadSupportSettings?.date?.year,
    defaultSelectType: launchpadSupportSettings?.selectType,
    onSelectTypeSet: handleChangeSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
    onDateSet: handleChangeDayDate,
  });

  const conditionLocalStation = !withCompare ? compareStation : launchpadSupportSettings?.station;

  useEffect(() => {
    if (
      (conditionLocalStation && (month || week || year || date)) ||
      (externalSettings?.station && externalSettings?.selectType && externalSettings?.date)
    ) {
      const params = externalSettingActive
        ? {
            station: externalSettings?.station,
            ...(externalSettings?.selectType === "month" && {
              month: +externalSettings?.date?.month,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "week" && {
              week: +externalSettings?.date?.week,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "day" && { day: externalSettings?.date?.day }),
          }
        : {
            station: conditionLocalStation,
            ...(selectType === "month" && { month, year: year }),
            ...(selectType === "week" && { week, year: year }),
            ...(selectType === "day" && { day: date }),
          };
      handleGetLaunchpadSupport(params, setRequestData, setLoading);
    }
  }, [
    launchpadSupportSettings?.station,
    year,
    month,
    week,
    date,
    selectType,
    externalSettingActive,
    externalSettings?.station,
    externalSettings?.selectType,
    externalSettings?.date,
    conditionLocalStation,
  ]);

  const labelsData =
    requestData.length > 20
      ? requestData.filter((el: { name: string }, index) =>
          index < 10 || index >= requestData.length - 11 ? el.name : null
        )
      : requestData.filter((el: { name: string }, index) =>
          index < Math.round(requestData.length / 2) ||
          index > requestData.length - (Math.round(requestData.length / 2) + 1)
            ? el.name
            : null
        );
  const labels = labelsData.map((el: { name: string }) => el.name).reverse();

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Counter",
        data: labelsData
          .reverse()
          .map((el: { counter: number; name: string }, index) =>
            el.name === labels[index] ? el.counter : null
          ),
        backgroundColor:
          labelsData.length === 20
            ? labelsData
                .reverse()
                .map((_, index) => (index < 10 ? Colors.PASTEL_RED : Colors.PASTEL_BLUE))
            : labelsData
                .reverse()
                .map((_, index) =>
                  index < Math.round(labelsData.length / 2) ? Colors.PASTEL_RED : Colors.PASTEL_BLUE
                ),
        minBarLength: 3,
        barThickness: 15,
        borderRadius: 25,
      },
    ],
  };

  return (
    <Box className={classes.root}>
      {!externalSettingActive && (
        <Box className={classes.head}>
          <Box className={classes.box}>
            <StationSelect
              value={!withCompare ? compareStation : launchpadSupportSettings?.station}
              onChange={handleChangeStation}
              stations={userData?.station_spots}
            />
            <SearchTypeSelect
              value={selectType}
              onChange={onTypeChange}
              options={[
                { id: "week", title: "Week" },
                { id: "month", title: "Month" },
                { id: "day", title: "Day" },
              ]}
            />
            {selectType === "month" && (
              <MonthSelect
                month={month}
                year={year}
                onNext={onMonthIncrease}
                onPrev={onMonthReduce}
                disabled={dateConditionMonth()}
              />
            )}
            {selectType === "week" && (
              <WeekSelect
                week={week}
                year={year}
                onNext={onWeekIncrease}
                onPrev={onWeekReduce}
                disabled={dateCondition()}
              />
            )}
            {selectType === "day" && (
              <DatePickWithArrow
                value={date}
                onChange={onDateChange}
                changeDay={onDateChangeByDay}
                displayWeek
              />
            )}
            {loading && <CircularProgress />}
          </Box>
          <Box>
            {withCompare && (
              <ScorecardCompareButton
                children={<LaunchpadSupport withModal={false} withCompare={false} />}
                children2={<LaunchpadSupport withModal={false} withCompare={false} />}
              />
            )}
            {withModal && <ScorecardOpenButton children={<LaunchpadSupport withModal={false} />} />}
          </Box>
        </Box>
      )}
      <Typography variant="body2" style={{ width: "100%", textAlign: "center" }}>
        Launchpad Support
      </Typography>
      <Bar options={options} data={dataChart} />
    </Box>
  );
};

const options = {
  responsive: true,
  scales: {
    y: {
      title: {
        display: false,
        text: "Value",
      },
      min: 0,
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
    x: {
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Launchpad Support",
    },
  },
};
