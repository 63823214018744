import {
  Box,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { FC } from "react";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import Loader from "router/components/Loader";
import { ActTableFilter } from "./ActTableFilter"
import { ActPageTable } from "./ActPageTable"

interface PropsType {}

const ActPage: FC<PropsType> = () => {
  const classes = useStyles();

  const { loadingData } = useTypeSelectors(
    (state) => state.actTable
  );

  return (
    <Box data-testid="testActPage" className={classes.root}>
      <Loader isLoading={loadingData} />
      <ActTableFilter />
      <ActPageTable />
    </Box>
  );
};

export default ActPage;
