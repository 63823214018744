import { FC } from "react";
import { Box, Typography, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { tableRowsList } from "components/tables/tableRows";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { EMPTY } from "constants/constants";
import { ReportsListRow } from "./ReportsListRow";
import { useReportsStyles } from "./styles";
import { useTableFilters } from "hooks/useTableFilters";
import { SortArrow } from "components/elements/SortArrow";

interface ReportsListTableProps {}

const SORT_OPTIONS = ["report_date", "created", "modified"];

export const ReportsListTable: FC<ReportsListTableProps> = () => {
  const classes = useReportsStyles();

  const { reportList } = useTypeSelectors((state) => state.dasBoard);

  const { sort, setSort } = useTableFilters("report_date", SORT_OPTIONS);

  return (
    <Box className={classes.root}>
      {!reportList.length ? (
        <Typography variant="h1" className={classes.emptyTable}>
          {EMPTY}
        </Typography>
      ) : (
        <>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableRowsList.map((TableRows) =>
                    SORT_OPTIONS.includes(TableRows.id) ? (
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => setSort(TableRows.id)}
                        key={TableRows.id}
                        style={{ cursor: "pointer" }}
                      >
                        <Box className={classes.sortCell}>
                          <Typography className={classes.tableCellTypography} variant="body2">
                            {TableRows.label}
                          </Typography>
                          <SortArrow id={TableRows.id} sort={sort} availableSort={SORT_OPTIONS} />
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell} key={TableRows.id}>
                        <Box className={classes.sortCell}>
                          <Typography className={classes.tableCellTypography} variant="body2">
                            {TableRows.label}
                          </Typography>
                          <SortArrow id={TableRows.id} sort={sort} availableSort={SORT_OPTIONS} />
                        </Box>
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportList.length
                  ? reportList.map((row: any) => <ReportsListRow row={row} key={row} />)
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};
