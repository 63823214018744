import api, { apiErrorHandler } from "api";
import { ActTableConstants } from "constants/constants";
import { Dispatch } from "redux";
import { RootState } from "reducers/rootReducer";
import { UserDataShort } from "types/User";
import {ActFilters, UpdateActTableTypes } from "types/Act";

export const getActTableInfo = (
    page?: number,
    page_size?: number,
    action?: number | null,
    owner?: UserDataShort | null,
    status?: number | null ,
    coaching_count?: number | null,
    due_date?: string | null,
    off_target_area?: string | null,
    driver?: UserDataShort | null,
    week_date?: string | null,
    ordering?: string,
  ) => async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: ActTableConstants.ACT_TABLE_GET_INFO_ERROR });
    dispatch({ type: ActTableConstants.ACT_TABLE_GET_INFO_LOADING });

    api
      .getActTableData({
        page,
        page_size,
        action: action || undefined,
        owner: owner?.id || undefined,
        status: status || undefined,
        coaching_count: coaching_count || undefined,
        due_date: due_date || undefined,
        off_target_area: off_target_area || undefined,
        driver_id: driver?.id || undefined,
        week_date: week_date || undefined,
        ordering: ordering
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: ActTableConstants.ACT_TABLE_GET_INFO_SUCCESS,
            payload: res?.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };
export const updateActTableInfo =
  (id: number, data: UpdateActTableTypes) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { dataList } = getStore()?.actTable;
    const onError = () => dispatch({ type: ActTableConstants.ACT_TABLE_GET_INFO_ERROR });
    dispatch({ type: ActTableConstants.ACT_TABLE_GET_INFO_LOADING });

    api
      .updateActTableData(id, data)
      .then((res) => {
        if (res?.data) {
          const newWeeklyData =
            dataList?.map((el)=> (el.id === res?.data.id ? res?.data : el)) || [];           
          dispatch({
            type: ActTableConstants.ACT_TABLE_UPDATE_INFO_SUCCESS,
            payload: newWeeklyData,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const actTableWeeklyChangeRowsPerPage = (data: number) => (dispatch: Dispatch) => {
    dispatch({
      type: ActTableConstants.ACT_TABLE_CHANGE_ROWS_PER_PAGE,
      payload: data,
    });
  };

export const setActTableFilters = (data: ActFilters) => (dispatch: Dispatch) => {
    dispatch({
      type: ActTableConstants.SET_ACT_TABLE_FILTERS,
      payload: data,
    });
  };

export const setActTablePagginationPage = (data: number) => (dispatch: Dispatch) => {
    dispatch({
      type: ActTableConstants.SET_ACT_TABLE_FILTERS,
      payload: { pageNumber: data },
    });
  };

export const setActTableSortOrder = (data: string) => (dispatch: Dispatch) => {
    dispatch({
      type: ActTableConstants.SET_ACT_TABLE_FILTERS,
      payload: { ordering: data },
    });
  };