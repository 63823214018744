import { Box, Tabs, Tab } from "@material-ui/core";
import { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "theme";

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

interface TabOption {
  label: string;
  component: ReactNode;
}

interface TabsWrapperProps {
  value: number;
  onChange: (val: number) => void;
  tabs: TabOption[];
}

export const TabWrapper: FC<TabsWrapperProps> = (props) => {
  const { value, onChange, tabs } = props;

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: number) => {
    onChange(value);
  };
  const labels = tabs.map((el) => el.label);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {labels.map((el, index) => (
          <Tab
            label={el}
            {...a11yProps(index)}
            style={
              index === value
                ? {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                  }
                : {}
            }
          />
        ))}
      </Tabs>
      {tabs.map((el, index) => (
        <TabPanel value={value} index={index}>
          {el.component}
        </TabPanel>
      ))}
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const classes = tabPanelStyles();
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.root}>{children}</Box>}
    </Box>
  );
};

export const tabPanelStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));