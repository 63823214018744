import { FC } from "react";
import { Modal, Paper, Box } from "@material-ui/core";
import { ReactNode } from "react";

interface ScorecardCompareModalsProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  children2: ReactNode;
}

export const ScorecardCompareModals: FC<ScorecardCompareModalsProps> = ({
  open,
  onClose,
  children,
  children2,
}) => {
  return (
    <Modal
      data-testid="testScorecardCompareModal"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Paper
        style={{
          width: "90vw",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          boxSizing: "border-box",
          gap: "10px",
        }}
      >
        <Box data-testid="testScorecardCompareModalText" style={{ width: "100%" }}>{children}</Box>
        <Box data-testid="testScorecardCompareModalText2" style={{ width: "100%" }}>{children2}</Box>
      </Paper>
    </Modal>
  );
};
