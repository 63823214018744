import { Typography } from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import { FC } from "react";
import { formatNumberType } from "utils/formatNUmber";

interface CheckTableCellPaintInterface {
  alertStyle: string;
  alertBackgoundStyle: string;
  typographyStyle: string;
  value: number | string | null | undefined;
  conditionDay: boolean;
  isNumber: boolean;
  isBold?: boolean;
}

export const CheckTableCellPaint: FC<CheckTableCellPaintInterface> = ({
  alertStyle,
  alertBackgoundStyle,
  typographyStyle,
  value,
  conditionDay,
  isNumber,
  isBold,
}) => {

  return value !== undefined && value !== null && conditionDay ? (
    <Alert
      data-testid="testCheckTableCellPaint" 
      className={alertBackgoundStyle}
      icon={false}
      severity={alertStyle as Color}
      style={{
        padding: "0 6px",
        height: "35px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography className={typographyStyle} style={{ ...(isBold && { fontWeight: 600 }) }}>
        {isNumber
          ? formatNumberType(Number(value))
          : value
        }
      </Typography>
    </Alert>
  ) : (
    <Typography style={{ ...(isBold && { fontWeight: 600 }), whiteSpace:"nowrap"}}>{ value !== null ? 0 : "--" }</Typography>
  );
};
