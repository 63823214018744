import { FC } from "react";
import { Box, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export type Station = {
  id: number;
  title: string;
};

interface StationSelectProps {
  value: string | number;
  onChange: (value: string) => void;
  stations: { station: { title: string; id: number } }[];
  disabled?: boolean;
  hasAllOption?: boolean;
}

export const StationSelect: FC<StationSelectProps> = ({
  value,
  onChange,
  stations,
  disabled,
  hasAllOption,
}) => {
  const classes = useStyles();

  return (
    <Box data-testid="testStationSelect" className={classes.dateBox}>
      <Select
        data-testid="testStationSelectInput"
        label="Station"
        value={value}
        onChange={(e) => onChange(String(e.target.value))}
        className={classes.select}
        disabled={disabled}
        name="station"
      >
        {hasAllOption && <MenuItem value={999}>All</MenuItem>}
        {stations?.map((el: { station: Station }) => (
          <MenuItem key={el.station.id} value={el.station.id}>
            {el.station.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  dateBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    display: "flex",
    minWidth: "150px",
    margin: theme.spacing(1),
  },
}));
