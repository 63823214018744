import { FC } from "react";
import { Modal, Paper } from "@material-ui/core";
import { ReactNode } from "react";

interface ScorecardModalsProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const ScorecardModals: FC<ScorecardModalsProps> = ({ open, onClose, children }) => {
  return (
    <Modal
      data-testid="testScorecardModal"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Paper style={{ width: "80vw" }}>{children}</Paper>
    </Modal>
  );
};
