import { createTheme } from "@material-ui/core";
import { Colors } from "constants/constants";

const theme = createTheme({
  overrides: {
    MuiTableContainer: {
      root: {
        overflow: "auto",

        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: Colors.LIGHT_BLUE,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: Colors.MAIN_LIGHT,
          borderRadius: "5px",
        },
      },
    },
    MuiTablePagination: {
      root: {
        overflow: "auto",

        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: Colors.LIGHT_BLUE,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: Colors.MAIN_LIGHT,
          borderRadius: "5px",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "5px",
        textAlign: "start",
      },
    },
    MuiTableRow: {
      root: {
        background: Colors.WHITE,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "25px",
      },
    },
    MuiTable: {
      stickyHeader: {
        background: Colors.WHITE,
      },
      root: {
        border: "none",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "25px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "5px",
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          background: Colors.MAIN,
          color: Colors.WHITE,
        },
        "&$selected": {
          background: Colors.MAIN_DARK,
          color: Colors.WHITE,
          "&:hover": {
            background: Colors.MAIN,
            color: Colors.WHITE,
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: Colors.LIGHT_BLUE,
      paper: Colors.WHITE,
    },
    primary: {
      contrastText: Colors.WHITE,
      light: Colors.MAIN_LIGHT,
      main: Colors.MAIN,
      dark: Colors.MAIN_DARK,
    },
    secondary: {
      contrastText: Colors.WHITE,
      main: Colors.RED,
    },
    text: {
      primary: Colors.MAIN,
      secondary: Colors.MAIN,
    },
  },
  shadows: [
    "none",
    "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)",
  ],
  typography: {
    fontFamily: ["Nunito"],
    h1: {
      fontWeight: 700,
      fontSize: 30,
      letterSpacing: "-0.24px",
    },
    h2: {
      fontWeight: 600,
      fontSize: 26,
      letterSpacing: "-0.24px",
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: "-0.06px",
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "-0.06px",
    },
    h5: {
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: "-0.05px",
    },
    h6: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
    },
    overline: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
    },
    body2: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "-0.05px",
    },
  },
});

export default theme;
