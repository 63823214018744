import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

export const SortArrow = ({
  id,
  sort,
  availableSort,
}: {
  id: string;
  sort: string;
  availableSort: string[];
}) => {
  for (const sortOption of availableSort) {
    if (id === `${sortOption}`) {
      if (sort === `${sortOption}`) return <ArrowDownward style={{fontSize: 18}} />;
      if (sort === `-${sortOption}`) return <ArrowUpward style={{fontSize: 18}} />;
    }
  }
  return null;
};
