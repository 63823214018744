import { ScorecardConstants } from "../constants/constants";
import { ReducersInterfaceAction } from "types/Reducers";
import { ScorecardState } from "types/Scorecard";
import { initialScorecardState } from "store/initialStates";

export const scorecardReducer = (
  state = initialScorecardState,
  action: ReducersInterfaceAction,
): ScorecardState => {
  switch (action.type) {
    case ScorecardConstants.EXTERNAL_SETTINGS_ACTIVE:
      return {
        ...state,
        externalSettingActive: action.payload?.data,
        externalSettings: { ...state.externalSettings, station: action.payload?.station },
        daScoreTrendSettings: { ...state.daScoreTrendSettings, station: action.payload?.station },
        totalScoreboardSettings: {
          ...state.totalScoreboardSettings,
          station: action.payload?.station,
        },
        topTenShiftSettings: {
          ...state.topTenShiftSettings,
          station: action.payload?.station,
        },
        topTenDasSettings: {
          ...state.topTenDasSettings,
          station: action.payload?.station,
        },
        rolesSettings: {
          ...state.rolesSettings,
          station: action.payload?.station,
        },
        returnSettings: {
          ...state.returnSettings,
          station: action.payload?.station,
        },
        vansSettings: {
          ...state.vansSettings,
          station: action.payload?.station,
        },
        missingDeliveriesSettings: {
          ...state.missingDeliveriesSettings,
          station: action.payload?.station,
        },
        cashOnDeliverySettings: {
          ...state.cashOnDeliverySettings,
          station: action.payload?.station,
        },
        lateAttendanceSettings: {
          ...state.lateAttendanceSettings,
          station: action.payload?.station,
        },
        launchpadSupportSettings: {
          ...state.launchpadSupportSettings,
          station: action.payload?.station,
        },
        morningChecklistSettings: {
          ...state.morningChecklistSettings,
          station: action.payload?.station,
        },
        rescueSettings: {
          ...state.rescueSettings,
          station: action.payload?.station,
        },
        returnedDataByDriverSettings: {
          ...state.returnedDataByDriverSettings,
          station: action.payload?.station,
        },
      };
    case ScorecardConstants.EXTERNAL_SET_CURRENT_STATION:
      return {
        ...state,
        externalSettings: { ...state.externalSettings, station: action.payload as string },
      };
    case ScorecardConstants.EXTERNAL_SET_SELECT_TYPE:
      return {
        ...state,
        externalSettings: { ...state.externalSettings, selectType: action.payload },
      };
    case ScorecardConstants.EXTERNAL_SET_DATE:
      return {
        ...state,
        externalSettings: { ...state.externalSettings, date: action.payload },
      };
    case ScorecardConstants.DA_SCORE_TREND_SET_CURRENT_STATION:
      return {
        ...state,
        daScoreTrendSettings: { ...state.daScoreTrendSettings, station: action.payload as string },
      };
    case ScorecardConstants.DA_SCORE_TREND_SET_SELECT_TYPE:
      return {
        ...state,
        daScoreTrendSettings: { ...state.daScoreTrendSettings, selectType: action.payload },
      };
    case ScorecardConstants.DA_SCORE_TREND_SET_DATE:
      return {
        ...state,
        daScoreTrendSettings: { ...state.daScoreTrendSettings, date: action.payload },
      };
    case ScorecardConstants.TOTAL_SCOREBOARD_SET_CURRENT_STATION:
      return {
        ...state,
        totalScoreboardSettings: {
          ...state.totalScoreboardSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.TOTAL_SCOREBOARD_SET_SELECT_TYPE:
      return {
        ...state,
        totalScoreboardSettings: { ...state.totalScoreboardSettings, selectType: action.payload },
      };
    case ScorecardConstants.TOTAL_SCOREBOARD_SET_DATE:
      return {
        ...state,
        totalScoreboardSettings: { ...state.totalScoreboardSettings, date: action.payload },
      };
    case ScorecardConstants.TOP_TEN_SHIFTS_SET_CURRENT_STATION:
      return {
        ...state,
        topTenShiftSettings: {
          ...state.topTenShiftSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.TOP_TEN_SHIFTS_SET_SELECT_TYPE:
      return {
        ...state,
        topTenShiftSettings: { ...state.topTenShiftSettings, selectType: action.payload },
      };
    case ScorecardConstants.TOP_TEN_SHIFTS_SET_DATE:
      return {
        ...state,
        topTenShiftSettings: { ...state.topTenShiftSettings, date: action.payload },
      };
    case ScorecardConstants.TOP_TEN_DAS_SET_CURRENT_STATION:
      return {
        ...state,
        topTenDasSettings: {
          ...state.topTenDasSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.TOP_TEN_DAS_SET_SELECT_TYPE:
      return {
        ...state,
        topTenDasSettings: { ...state.topTenDasSettings, selectType: action.payload },
      };
    case ScorecardConstants.TOP_TEN_DAS_SET_DATE:
      return {
        ...state,
        topTenDasSettings: { ...state.topTenDasSettings, date: action.payload },
      };
    case ScorecardConstants.ROLES_SET_CURRENT_STATION:
      return {
        ...state,
        rolesSettings: {
          ...state.rolesSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.ROLES_SET_SELECT_TYPE:
      return {
        ...state,
        rolesSettings: { ...state.rolesSettings, selectType: action.payload },
      };
    case ScorecardConstants.ROLES_SET_DATE:
      return {
        ...state,
        rolesSettings: { ...state.rolesSettings, date: action.payload },
      };
    case ScorecardConstants.RETURN_SET_CURRENT_STATION:
      return {
        ...state,
        returnSettings: {
          ...state.returnSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.RETURN_SET_SELECT_TYPE:
      return {
        ...state,
        returnSettings: { ...state.returnSettings, selectType: action.payload },
      };
    case ScorecardConstants.RETURN_SET_DATE:
      return {
        ...state,
        returnSettings: { ...state.returnSettings, date: action.payload },
      };
    case ScorecardConstants.VANS_SET_CURRENT_STATION:
      return {
        ...state,
        vansSettings: {
          ...state.vansSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.VANS_SET_SELECT_TYPE:
      return {
        ...state,
        vansSettings: { ...state.vansSettings, selectType: action.payload },
      };
    case ScorecardConstants.VANS_SET_DATE:
      return {
        ...state,
        vansSettings: { ...state.vansSettings, date: action.payload },
      };

    case ScorecardConstants.MISSING_DELIVERIES_SET_CURRENT_STATION:
      return {
        ...state,
        missingDeliveriesSettings: {
          ...state.missingDeliveriesSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.MISSING_DELIVERIES_SET_SELECT_TYPE:
      return {
        ...state,
        missingDeliveriesSettings: {
          ...state.missingDeliveriesSettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.MISSING_DELIVERIES_SET_DATE:
      return {
        ...state,
        missingDeliveriesSettings: { ...state.missingDeliveriesSettings, date: action.payload },
      };
    case ScorecardConstants.CASH_ON_DELIVERY_SET_CURRENT_STATION:
      return {
        ...state,
        cashOnDeliverySettings: {
          ...state.cashOnDeliverySettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.CASH_ON_DELIVERY_SET_SELECT_TYPE:
      return {
        ...state,
        cashOnDeliverySettings: {
          ...state.cashOnDeliverySettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.CASH_ON_DELIVERY_SET_DATE:
      return {
        ...state,
        cashOnDeliverySettings: { ...state.cashOnDeliverySettings, date: action.payload },
      };
    case ScorecardConstants.LATE_ATTENDANCE_STATION:
      return {
        ...state,
        lateAttendanceSettings: {
          ...state.lateAttendanceSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.LATE_ATTENDANCE_SELECT_TYPE:
      return {
        ...state,
        lateAttendanceSettings: {
          ...state.lateAttendanceSettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.LATE_ATTENDANCE_DATE:
      return {
        ...state,
        lateAttendanceSettings: { ...state.lateAttendanceSettings, date: action.payload },
      };

    case ScorecardConstants.LAUNCHPAD_SUPPORT_STATION:
      return {
        ...state,
        launchpadSupportSettings: {
          ...state.launchpadSupportSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.LAUNCHPAD_SUPPORT_SELECT_TYPE:
      return {
        ...state,
        launchpadSupportSettings: {
          ...state.launchpadSupportSettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.LAUNCHPAD_SUPPORT_DATE:
      return {
        ...state,
        launchpadSupportSettings: { ...state.launchpadSupportSettings, date: action.payload },
      };

    case ScorecardConstants.MORNING_CHECKLIST_STATION:
      return {
        ...state,
        morningChecklistSettings: {
          ...state.morningChecklistSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.MORNING_CHECKLIST_SELECT_TYPE:
      return {
        ...state,
        morningChecklistSettings: {
          ...state.morningChecklistSettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.MORNING_CHECKLIST_DATE:
      return {
        ...state,
        morningChecklistSettings: { ...state.morningChecklistSettings, date: action.payload },
      };
    case ScorecardConstants.RESCUE_STATION:
      return {
        ...state,
        rescueSettings: {
          ...state.rescueSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.RESCUE_SELECT_TYPE:
      return {
        ...state,
        rescueSettings: {
          ...state.rescueSettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.RESCUE_DATE:
      return {
        ...state,
        rescueSettings: { ...state.rescueSettings, date: action.payload },
      };
    case ScorecardConstants.RETURNED_DATA_BY_DRIVER_STATION:
      return {
        ...state,
        returnedDataByDriverSettings: {
          ...state.returnedDataByDriverSettings,
          station: action.payload as string,
        },
      };
    case ScorecardConstants.RETURNED_DATA_BY_DRIVER_SELECT_TYPE:
      return {
        ...state,
        returnedDataByDriverSettings: {
          ...state.returnedDataByDriverSettings,
          selectType: action.payload,
        },
      };
    case ScorecardConstants.RETURNED_DATA_BY_DRIVER_DATE:
      return {
        ...state,
        returnedDataByDriverSettings: {
          ...state.returnedDataByDriverSettings,
          date: action.payload,
        },
      };
    default:
      return state;
  }
};
