import api, { apiErrorHandler } from "api";
import { StatsParams } from "api/types";
import { AxiosError, AxiosResponse } from "axios";

const requestHandler =
  (request: (params: StatsParams) => Promise<AxiosResponse>) =>
  (
    params: StatsParams,
    onSuccess: (res: any) => void,
    onLoading?: (status: boolean) => void,
    onError?: (err: AxiosError) => void | undefined,
  ) => {
    if (onLoading) onLoading(true);
    return request(params)
      .then((res) => {
        if (res.data) onSuccess(res.data);
        if (onLoading) onLoading(false);
      })
      .catch(
        apiErrorHandler((error) => {
          if (onLoading) onLoading(false);
          if (onError) onError(error);
        }),
      );
  };

export const handleGetReturned = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsReturned)(params, onSuccess, onLoading, onError);
};

export const handleGetPackages = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsPackages)(params, onSuccess, onLoading, onError);
};

export const handleGetPerformers = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsPerformers)(params, onSuccess, onLoading, onError);
};

export const handleGetRoles = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsRoles)(params, onSuccess, onLoading, onError);
};

export const handleGetDrivers = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsDrivers)(params, onSuccess, onLoading, onError);
};

export const handleGetStatuses = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsStatuses)(params, onSuccess, onLoading, onError);
};

export const handleGetVans = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsVans)(params, onSuccess, onLoading, onError);
};

export const handleGetMissingDeliveries = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsMissing)(params, onSuccess, onLoading, onError);
};

export const handleGetCashOnDelivery = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsCash)(params, onSuccess, onLoading, onError);
};

export const handleGetDAR = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsDar)(params, onSuccess, onLoading, onError);
};

export const handleGetLateAttendance = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsLateAttendance)(params, onSuccess, onLoading, onError);
};

export const handleGetLaunchpadSupport = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsLaunchpadSupport)(params, onSuccess, onLoading, onError);
};

export const handleGetMorningChecklist = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsMorningChecklist)(params, onSuccess, onLoading, onError);
};

export const handleGetRescue = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsRescue)(params, onSuccess, onLoading, onError);
};

export const handleGetReturnedDataByDriver = async (
  params: StatsParams,
  onSuccess: (res: any) => void,
  onLoading?: (status: boolean) => void,
  onError?: (err: AxiosError) => void,
) => {
  return await requestHandler(api.getStatsReturnedDataByDriver)(
    params,
    onSuccess,
    onLoading,
    onError,
  );
};
