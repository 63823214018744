import moment from "moment";
import { useState, ChangeEvent } from "react";

interface useDateWeekSelectorType {
  defaultWeek?: string;
  defaultMonth?: string;
  defaultYear?: string;
  defaultDateStart?: string;
  defaultDateEnd?: string;
  defaultSelectType?: string;
  defaultDate?: string;
  onWeekSet?: (week: string) => void;
  onYearSet?: (year: string) => void;
  onMonthSet?: (month: string) => void;
  onDateStartSet?: (startDate: string) => void;
  onDateEndSet?: (endDate: string) => void;
  onSelectTypeSet?: (select: string) => void;
  onDateSet?: (date: string) => void;
}

export const useDateWeekSelector = ({
  defaultWeek,
  defaultMonth,
  defaultYear,
  defaultDateStart,
  defaultDateEnd,
  defaultSelectType,
  defaultDate,
  onWeekSet,
  onYearSet,
  onMonthSet,
  onDateStartSet,
  onDateEndSet,
  onSelectTypeSet,
  onDateSet,
}: useDateWeekSelectorType) => {
  const currentYear = moment().format("yyyy");
  const currentMonth = moment().format("M");
  const currentWeek = moment().format("W");
  const currentDate = moment().format("yyyy-MM-DD");
  const [week, setWeek] = useState(Number(defaultWeek) || +currentWeek);
  const [year, setYear] = useState(Number(defaultYear) || +currentYear);
  const [month, setMonth] = useState(Number(defaultMonth) || +currentMonth);
  const [selectType, setSelectType] = useState(defaultSelectType || "week");
  const [startDate, setStartDate] = useState(defaultDateStart || currentDate);
  const [endDate, setEndDate] = useState(defaultDateEnd || currentDate);
  const [date, setDate] = useState(defaultDate || currentDate);

  const onTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    setWeek(+currentWeek);
    if (onWeekSet) onWeekSet(currentWeek);
    setMonth(+currentMonth);
    if (onMonthSet) onMonthSet(currentMonth);
    setSelectType(event.target.value as string);
    if (onSelectTypeSet) onSelectTypeSet(event.target.value as string);
    setYear(+currentYear);
    if (onYearSet) onYearSet(currentYear);
  };

  const onTypeExternalChange = (select: string) => {
    setSelectType(select);
    if (onSelectTypeSet) onSelectTypeSet(select);
  };

  const dateCondition = () => {
    let disabled = true;
    if (currentWeek && currentYear) {
      if (year < +currentYear) disabled = false;
      if (year === +currentYear && week < +currentWeek) disabled = false;
    }
    return disabled;
  };

  const dateConditionMonth = () => {
    let disabled = true;
    if (currentMonth && currentYear) {
      if (year < +currentYear) disabled = false;
      if (year === +currentYear && month < +currentMonth) disabled = false;
    }
    return disabled;
  };

  const dateConditionYear = () => {
    return year === +currentYear;
  };

  const onWeekReduce = () => {
    if (week && year) {
      if (week === 1) {
        setWeek(52);
        if (onWeekSet) onWeekSet(`${52}`);
        setYear(year - 1);
        if (onYearSet) onYearSet(`${year - 1}`);
      } else {
        setWeek(week - 1);
        if (onWeekSet) onWeekSet(`${week - 1}`);
      }
    }
  };
  const onWeekIncrease = () => {
    if (week && year) {
      if (week === 52) {
        setWeek(1);
        if (onWeekSet) onWeekSet(`${1}`);
        setYear(year + 1);
        if (onYearSet) onYearSet(`${year + 1}`);
      } else {
        setWeek(week + 1);
        if (onWeekSet) onWeekSet(`${week + 1}`);
      }
    }
  };

  const onMonthReduce = () => {
    if (month && year) {
      if (month === 1) {
        setMonth(12);
        if (onMonthSet) onMonthSet(`${12}`);
        setYear(year - 1);
        if (onYearSet) onYearSet(`${year - 1}`);
      } else {
        setMonth(month - 1);
        if (onMonthSet) onMonthSet(`${month - 1}`);
      }
    }
  };
  const onMonthIncrease = () => {
    if (month && year) {
      if (month === 12) {
        setMonth(1);
        if (onMonthSet) onMonthSet(`${1}`);
        setYear(year + 1);
        if (onYearSet) onYearSet(`${year + 1}`);
      } else {
        setMonth(month + 1);
        if (onMonthSet) onMonthSet(`${month + 1}`);
      }
    }
  };

  const onYearReduce = () => {
    setYear(year - 1);
    if (onYearSet) onYearSet(`${year - 1}`);
  };
  const onYearIncrease = () => {
    setYear(year + 1);
    if (onYearSet) onYearSet(`${year + 1}`);
  };

  const onDateChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setDate(event.target.value);
    if (onDateSet) onDateSet(event.target.value);
  };

  const onDateChangeByDay = (val: string) => {
    setDate(val);
    if (onDateSet) onDateSet(val);
  };

  const onStartDateChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setStartDate(event.target.value);
    if (onDateStartSet) onDateStartSet(event.target.value);
  };

  const onEndDateChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEndDate(event.target.value);
    if (onDateEndSet) onDateEndSet(event.target.value);
  };

  const pastMonthDate = moment()
    .year(year)
    .month(month - 1)
    .subtract(1, "month")
    .format("MM.yyyy");
  const pastWeekDate = moment().year(year).week(week).subtract(1, "week").format("w.yyyy");

  return {
    week,
    month,
    year,
    start_date: startDate,
    end_date: endDate,
    selectType,
    date,
    pastMonthDate,
    pastWeekDate,
    onTypeChange,
    dateCondition,
    dateConditionMonth,
    dateConditionYear,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onYearReduce,
    onYearIncrease,
    onStartDateChange,
    onEndDateChange,
    onTypeExternalChange,
    onDateChange,
    onDateChangeByDay,
  };
};
