import { ModalConstants } from "constants/constants";
import { Dispatch } from "redux";

export const openModal = (data: string) => (dispatch: Dispatch) => {
    dispatch({
      type: ModalConstants.OPEN_MODAL,
      payload: data,
    });
  };

export const closeModal = () => (dispatch: Dispatch) => {
    dispatch({
      type: ModalConstants.CLOSE_MODAL
    });
  };
  