import { FC } from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

interface WeekSelectProps {
  week: number;
  year: number;
  onNext: () => void;
  onPrev: () => void;
  disabled?: boolean;
}

export const WeekSelect: FC<WeekSelectProps> = ({ week, year, onNext, onPrev, disabled }) => {
  const classes = useStyles();
  return (
    <Box data-testid="testWeekSelect" className={classes.dateBox}>
      <IconButton data-testid="testWeekSelectButton" color="primary" onClick={onPrev} className={classes.iconButton}>
        <NavigateBeforeIcon />
      </IconButton>
      <Typography variant="body2" className={classes.date}>
        {week}.{year}
      </Typography>
      <IconButton
        data-testid="testWeekSelectButtonTwo"
        color="primary"
        onClick={onNext}
        className={classes.iconButton}
        disabled={disabled}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  date: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
  },
  dateBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconButton: {
    padding: "0",
    margin: "0 10px",
  },
}));
