import { FC } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReportsListTable } from "components/tables/ReportTables/ReportsListTable";
import { ReportsListFilter } from "components/tables/ReportTables/ReportsListFilter";

const DaBoardPage: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} id="Table">
      <ReportsListFilter />
      <ReportsListTable />
    </Box>
  );
};

export default DaBoardPage;

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));
