import { AUTH } from "api";
import axios from "axios";
import {
  BAD_REQUEST,
  INTERNAL_SERVER_ERROR,
  LARGE_REQUEST,
  NOT_FOUND,
  UNAUTHORIZED,
  HAVENTACCESS,
} from "constants/constants";
import { customToast } from "hooks/toast";
import { localUserService } from "utils/localStorage";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "http://164.90.180.185/";
// const BASE_URL = "https://dpiscore.com/";

const apiClient = axios.create({
  baseURL: BASE_URL,
});

export const setSession = ({ token }: { token?: string | null }) => {
  if (token) {
    const Bearer = `Bearer ${token}`;
    apiClient.defaults.headers.common.Authorization = Bearer;
  } else {
    delete apiClient.defaults.headers.common.Authorization;
  }
};

apiClient.interceptors.request.use(
  (config) => {
    const token = localUserService.getAccess();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const refreshTokenRequest = async () => {
  const refreshLocal = localUserService.getRefresh();
  if (refreshLocal) {
    return await axios
      .post(`${BASE_URL}${AUTH.refresh()}`, { refresh: refreshLocal })
      .then((res) => {
        const { access, refresh } = res.data;
        if (access && refresh) {
          setSession(access);
          localUserService.setAccess(access);
          localUserService.setRefresh(refresh);
          return access;
        }
      })
      .catch(localUserService.logout);
  } else {
    localUserService.logout();
    return null;
  }
};

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const refreshLocal = localUserService.getRefresh();
    switch (error.response.status) {
      case INTERNAL_SERVER_ERROR:
        customToast("error", "Internal server error!");
        break;
      case UNAUTHORIZED:
        if (refreshLocal)
          return refreshTokenRequest().then((access) => {
            if (access) {
              return axios.request({
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: `Bearer ${access}`,
                },
              });
            }
          });
        return localUserService.logout();
      case NOT_FOUND:
        if (error.response.data.detail === "No free vans!") {
          customToast("warning", "No free vans!");
        }
        break;
      case HAVENTACCESS:
        customToast("warning", "You haven't permissions");
        break;
      case BAD_REQUEST:
        if (error.response.data.non_field_errors) {
          customToast("error", "Email or password not valid!");
        }
        if (error.response.data.email) {
          customToast("error", "User with this email does not found!");
        }
        break;
      case LARGE_REQUEST:
        customToast("error", "File so large for upload");
        break;
      default:
        break;
    }

    return Promise.reject(error);
  },
);

export default apiClient;
