import {
  Typography,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { ActTableHeaderTwoCell } from "types/Act";
import { IkeaActTableHeaderTwoCell } from "types/IkeaAct";
import { ActFilters } from "types/Act";
import { IkeaActFilters } from "types/IkeaAct";

interface PropsType {
  tableHeadersList: ActTableHeaderTwoCell[] | IkeaActTableHeaderTwoCell[];
  sortAction: (arg0: string) => void;
  pagginationAction: (arg0: number) => void;
  filters: ActFilters | IkeaActFilters;
}

export const ActTableHead: FC<PropsType> = ({
  tableHeadersList,
  sortAction,
  pagginationAction,
  filters,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [sortId, setSortId] = useState<string | undefined>(filters?.ordering || "-week_number");

  const handleSortChange = (sortId: string | undefined, cellId: string) => {
    if (filters.pageNumber > 0) dispatch(pagginationAction(0));
    if (sortId?.replace("-", "") === cellId) {
      const sortString = `${sortId?.includes("-") ? "" : "-"}${cellId}`;
      setSortId(sortString);
      dispatch(sortAction(sortString));
    } else {
      setSortId(cellId);
      dispatch(sortAction(`${cellId}`));
    }
  };

  return (
    <TableHead data-testid="testActTableHead">
      <TableRow>
        {tableHeadersList.map((el: ActTableHeaderTwoCell | IkeaActTableHeaderTwoCell) => (
          <TableCell
            data-testid={`actTableHeadColumn-${el.id}`}
            key={el.label}
            colSpan={1}
            className={`${classes.tableHeadCell} ${classes.tableHeadWhiteCell}`}
          >
            <TableSortLabel
              disabled={!el.hasSortLabel}
              active={sortId?.replace("-", "") === el.id}
              direction={
                sortId?.replace("-", "") === el.id && sortId?.includes("-") ? "desc" : "asc"
              }
              onClick={() => handleSortChange(sortId, el.id)}
            >
              {el?.toolTipLabel ? (
                <Tooltip title={<Typography>{el.toolTipLabel}</Typography>}>
                  <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
