export const tableRowsCreate = [
  { field: "small" },
  { field: "small", label: "#" },
  { field: "big", id: "Fleet Group", label: "Fleet  Group" },
  { field: "small", id: "Fleet No.", label: "Fleet No." },
  { field: "big", id: "Fleet Size", label: "Fleet Size" },
  { field: "big", id: "Role", label: "Role" },
  { field: "small", id: "Route", label: "Route" },
  { field: "big", id: "Wave", label: "Wave" },
  {
    field: "big",
    id: "DA 1",
    label: "DA 1",
  },
  { field: "big", id: "agency_1", label: "Agency 1" },
  { field: "big", id: "Transporter ID", label: "Transporter ID" },
  {
    field: "big",
    id: "DA 2",
    label: "DA 2",
  },
  { field: "big", id: "agency_2", label: "Agency 2" },
  { field: "big", id: "Comment", label: "Comment" },
  { field: "small", id: "Rabbit", label: "Rabbit" },
  { field: "small", id: "Fuel Card Number", label: "Fuel Card Number" },
  { field: "small", id: "Dolly", label: "Dolly" },
  { field: "small", id: "Ontime Attendance", label: "Ontime Attendance" },
  { field: "small", id: "Launchpad Support", label: "Launchpad Support" },
  { field: "small", id: "Morning Checklist", label: "Morning Checklist" },
  { field: "small", id: "Rescue", label: "Rescue" },
  { field: "small", id: "Violation", label: "Violation" },
  { field: "small", id: "Defects", label: "Defects" },
  { field: "small", id: "# of Stops", label: "# of Stops" },
  { field: "small", id: "# of PKGS", label: "# of PKGS" },
  { field: "small", id: "First Stop", label: "First Stop" },
  { field: "small", id: "Pld End Time", label: "Pld End Time" },
  { field: "small", id: "Act End Time", label: "Act End Time" },
  { field: "small", id: "Rescue Given", label: "Rescue Given" },
  { field: "small", id: "Rescue Taken", label: "Rescue Taken" },
  { field: "small", id: "UTA", label: "UTA" },
  { field: "small", id: "UTL", label: "UTL" },
  { field: "small", id: "NSL", label: "NSL" },
  { field: "small", id: "BC", label: "BC" },
  { field: "small", id: "Missing", label: "Missing" },
  { field: "small", id: "Damage", label: "Damage" },
  { field: "small", id: "FDD", label: "FDD" },
  { field: "small", id: "Missort", label: "Missort" },
  { field: "small", id: "OC", label: "OC" },
  { field: "small", id: "others", label: "Others" },
  { field: "small", id: "COD", label: "COD" },
];

export const tableRowsList = [
  { field: "small", id: "Week", label: "W" },
  { field: "small", id: "report_date", label: "Shift Date" },
  { field: "big", id: "Station", label: "Station" },
  { field: "big", id: "Created by", label: "Created By" },
  { field: "small", id: "created", label: "Created Date" },
  { field: "big", id: "Edited by", label: "Edited By" },
  { field: "small", id: "modified", label: "Changed Date" },
  { field: "small", id: "Buttons", label: "" },
];

export const inputHistoryTableList = [
  { field: "small", id: "id", label: "ID" },
  { field: "big", id: "File Name", label: "File Name" },
  { field: "big", id: "Uploader Email", label: "Uploader Email" },
  { field: "small", id: "Uploaded", label: "Uploaded Date" },
];

export const fleetSettingsList = [
  { field: "icon", id: "status", label: "Status" },
  { field: "small", id: "station__title", label: "Fixed Station" },
  { field: "small", id: "number", label: "Van" },
  { field: "small", id: "fleet_size__title", label: "Fleet Size" },
  { field: "small", id: "blocking__temp_station__title", label: "Temp station" },
  { field: "big", id: "blocking__assignment", label: "Assigned" },
  { field: "big", id: "description", label: "Description" },
  { field: "big", id: "edited_by", label: "Last Editors" },
  { field: "small", id: "actions", label: "" },
];
