import api, { apiErrorHandler } from "api";
import { CheckIkeaTableConstants } from "constants/constants";
import { DailyIkeaCheck, IkeaCheckReportWeekRow } from "types/IkeaCheck";
import { RootState } from "reducers/rootReducer";
import { Dispatch } from "redux";

export const checkIkeaTableWeeklyInfo =
  (
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
  ) =>
  async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_ERROR });
    dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_LOADING });

    api
      .getIkeaCheckTableWeeklyData({
        page,
        page_size,
        date_from: date_from,
        date_to: date_to, 
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS,
            payload: res?.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const checkIkeaTableWeeklyChangeRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_CHANGE_ROWS_PER_PAGE,
    payload: data,
  });
};

export const checkIkeaTableWeekFullInfo = (id: number) => async (dispatch: Dispatch) => {
  const onError = () =>
    dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR });
  dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING });

  api
    .getIkeaCheckTableWeeklyDataByDays(id)
    .then((res) => {
      if (res?.data) {
        dispatch({
          type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(apiErrorHandler(onError));
};

export const updateCheckIkeaTableWeeklyDay =
  (id: number, data:{[key: string]: string | number}) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { currentWeekData } = getStore()?.checkIkeaTable;
    const onError = () => {
      dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR });
    };

    dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING });

    api
      .updateIkeaCheckTableDayData(id, data)
      .then((res) => {
        if (res?.data) {
          const newDailyData =
            currentWeekData?.daily_ikea_checks.map((el: DailyIkeaCheck) => (el?.id !== id ? el : res.data)) || [];
          dispatch({
            type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS,
            payload: { ...currentWeekData, daily_ikea_checks: newDailyData },
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const updateCheckIkeaTableWeek =
  (id: number, data:{[key: string]: string | number}) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { dataList } = getStore()?.checkIkeaTable;
    const onError = () => {
      dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_ERROR });
    };

    dispatch({ type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_LOADING });

    api
      .updateIkeaCheckTableWeeklyData(id, data)
      .then((res) => {
        if (res?.data) {  
          const newWeeklyData =
            dataList?.map((el: IkeaCheckReportWeekRow)=> (el.id === res?.data.id ? res?.data : el)) || [];  
          dispatch({
            type: CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_CAS_ES,
            payload: newWeeklyData,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  }

export const getIkeaCheckAverageScoreData =
  () => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { dataList } = getStore()?.checkIkeaTable;

    const averageScore = dataList.reduce((s: any, e: any) => {
      Object.keys(s).forEach((k: string) => s[k] += + e[k]/dataList.length);
      return s
    }
    ,{ 
      cc: 0,
      cc_da_off_target: 0,
      dcr: 0,
      dcr_da_off_target: 0,
      mc: 0,
      mc_da_off_target: 0,
      oa: 0,
      oa_da_off_target: 0,
      infraction: 0,
      reliability_score: 0,
      tenured_da: 0,
      employee_satisfaction_survey: 0,
    });
    
    dispatch({
      type: CheckIkeaTableConstants.IKEA_UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA,
      payload: averageScore,
    });
  }

export const removeIkeaCheckTableRangeDates = 
  () => async (dispatch: Dispatch) => {

    dispatch({
      type: CheckIkeaTableConstants.IKEA_SET_CHECK_TABLE_FILTERS,
      payload: {
        rangeDateOne: "",
        rangeDateTwo: "",
      },
    });
  }

export const updateIkeaCheckTableRangeDates =
  (dateOne: string, dateTwo:string) => async (dispatch: Dispatch) => {

    dispatch({
      type: CheckIkeaTableConstants.IKEA_SET_CHECK_TABLE_FILTERS,
      payload: {
        rangeDateOne: dateOne,
        rangeDateTwo: dateTwo,
      },
    });
  }

export const setIkeaCheckTablePagginationPage =
  (data: number) => (dispatch: Dispatch) => {

    dispatch({
      type: CheckIkeaTableConstants.IKEA_SET_CHECK_TABLE_FILTERS,
      payload: { pageNumber: data },
    });
  };
