import api, { apiErrorHandler } from "api";
import { CheckTableConstants } from "constants/constants";
import { customToast } from "hooks/toast";
import { RootState } from "reducers/rootReducer";
import { Dispatch } from "redux";

export const checkTableWeeklyInfo =
  (
    page?: number,
    page_size?: number,
    date_from?: string,
    date_to?: string,
  ) =>
  async (dispatch: Dispatch) => {
    const onError = () => dispatch({ type: CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_ERROR });
    dispatch({ type: CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_LOADING });

    api
      .getCheckTableWeeklyData({
        page,
        page_size,
        date_from: date_from,
        date_to: date_to,
      })
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS,
            payload: res?.data,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const checkTableWeeklyChangeRowsPerPage = (data: number) => (dispatch: Dispatch) => {
  dispatch({
    type: CheckTableConstants.CHECK_TABLE_CHANGE_ROWS_PER_PAGE,
    payload: data,
  });
};

export const checkTableWeekFullInfo = (id: number) => async (dispatch: Dispatch) => {
  const onError = () =>
    dispatch({ type: CheckTableConstants.CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR });
  dispatch({ type: CheckTableConstants.CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING });

  api
    .getCheckTableWeeklyDataByDays(id)
    .then((res) => {
      if (res?.data) {
        dispatch({
          type: CheckTableConstants.CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS,
          payload: res.data,
        });
      }
    })
    .catch(apiErrorHandler(onError));
};

export const createCheckTableWeeklyFileUpload =
  (file: File, file2: File) => async (dispatch: Dispatch) => {
    const onError = () => {
      dispatch({ type: CheckTableConstants.CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_ERROR });
      customToast("error", "Something was wrong!");
    };

    dispatch({ type: CheckTableConstants.CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_LOADING });

    const request: FormData = new FormData();
    request.append("file1", file);
    request.append("file2", file2);

    api
      .createCheckTableByUploadFile(request)
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: CheckTableConstants.CHECK_TABLE_UPLOAD_FILE_WEEKLY_INFO_SUCCESS,
          });
          customToast("success", "Csv files was uploaded!");
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const updateCheckTableWeeklyDay =
  (id: number, data:{[key: string]: string | number}) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { currentWeekData } = getStore()?.checkTable;
    const onError = () => {
      dispatch({ type: CheckTableConstants.CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR });
    };

    dispatch({ type: CheckTableConstants.CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING });

    api
      .updateCheckTableDayData(id, data)
      .then((res) => {
        if (res?.data) {
          const newDailyData =
            currentWeekData?.daily_checks.map((el) => (el?.id !== id ? el : res.data)) || [];
          dispatch({
            type: CheckTableConstants.CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS,
            payload: { ...currentWeekData, daily_checks: newDailyData },
          });
        }
      })
      .catch(apiErrorHandler(onError));
  };

export const updateCheckTableWeek =
  (id: number, data:{[key: string]: string | number}) => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { dataList } = getStore()?.checkTable;
    const onError = () => {
      dispatch({ type: CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_ERROR });
    };

    dispatch({ type: CheckTableConstants.CHECK_TABLE_GET_WEEKLY_INFO_LOADING });

    api
      .updateCheckTableWeeklyData(id, data)
      .then((res) => {
        if (res?.data) {  
          const newWeeklyData =
            dataList?.map((el)=> (el.id === res?.data.id ? res?.data : el)) || [];     
          dispatch({
            type: CheckTableConstants.CHECK_TABLE_UPDATE_CAS_ES,
            payload: newWeeklyData,
          });
        }
      })
      .catch(apiErrorHandler(onError));
  }

export const getCheckAverageScoreData =
  () => async (dispatch: Dispatch, getStore: () => RootState) => {
    const { dataList } = getStore()?.checkTable;

    const averageScore = dataList.reduce((s: any, e: any) => {
      Object.keys(s).forEach(k => s[k] += + e[k]/dataList.length);
      return s
    }
    ,{ 
      ada: 0,
      ada_da_off_target: 0,
      cas: 0,
      cas_da_off_target: 0,
      cc: 0,
      cc_da_off_target: 0,
      dcr: 0,
      dcr_da_off_target: 0,
      dnr: 0,
      dnr_da_off_target: 0,
      dsb: 0,
      dsb_da_off_target: 0,
      employee_satisfaction: 0,
      employee_satisfaction_da_off_target: 0,
      hp6w: 0,
      hp6w_da_off_target: 0,
      mc: 0,
      mc_da_off_target: 0,
      oa: 0,
      oa_da_off_target: 0,
      pod: 0,
      pod_da_off_target: 0,
      rescue_given: 0,
      rescue_given_da_off_target: 0,
      violation: 0,
      violation_da_off_target: 0,
      whc: 0,
      whc_da_off_target: 0
    });
    
    dispatch({
      type: CheckTableConstants.UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA,
      payload: averageScore,
    });
  }

export const removeCheckTableRangeDates = 
  () => async (dispatch: Dispatch) => {

    dispatch({
      type: CheckTableConstants.SET_CHECK_TABLE_FILTERS,
      payload: {
        pageNumber: 0,
        rangeDateOne: "",
        rangeDateTwo: "",
      },
    });
  }

export const updateCheckTableRangeDates =
  (dateOne: string, dateTwo:string) => async (dispatch: Dispatch) => {

    dispatch({
      type: CheckTableConstants.SET_CHECK_TABLE_FILTERS,
      payload: {
        pageNumber: 0,
        rangeDateOne: dateOne,
        rangeDateTwo: dateTwo,
      },
    });
  }

export const setCheckTablePagginationPage =
  (data: number) => (dispatch: Dispatch) => {

  dispatch({
    type: CheckTableConstants.SET_CHECK_TABLE_FILTERS,
      payload: { pageNumber: data },
    });
  };
