import { defaultUserData } from "constants/defaultValues";
import moment from "moment";
import { AmazonState } from "types/AmazonRecord";
import { AuthState } from "types/Auth";
import { CheckState } from "types/Check";
import { ActState } from "types/Act";
import { DashboardState } from "types/DashboardRecord";
import { FleetSettingsState } from "types/FleetSettings";
import { DasBoardState } from "types/Report";
import { ScorecardState } from "types/Scorecard";
import { WeightsState } from "types/Weights";
import { IkeaCheckState } from "types/IkeaCheck";
import { IkeaActState } from "types/IkeaAct";
import { ModalState } from "types/Modal";
import { CustomGraphsState } from "types/CustomGraphs";
import { PerfomanceState } from "types/Performance";

export const initialAuthState: AuthState = {
  isLoginPending: false,
  authModalState: false,
  authModalStateLogin: true,
  userData: defaultUserData,
};

export const defaultReportFilters = {
  pageNumber: 0,
  userCreate: null,
  userEdit: null,
  dateCreate: "",
  shiftDate: "",
  stationFilter: null,
  dateFrom: "",
  dateTo: "",
  dateMonth: "",
  dateYear: "",
};

export const initialReportState: DasBoardState = {
  reportListLoading: false,
  reportList: [],
  reportListCount: 1,
  currentReport: [],
  currentReportDate: "",
  currentReportStation: { id: 0, title: "" },
  reportListFiltersTab: 0,
  reportListFilters: defaultReportFilters,
  fleetSizeLoading: false,
  fleetSizes: [],
  dasBoardRowsPerPage: 10,
  uploadFileLoading: false,
};

export const initialDashboardState: DashboardState = {
  dashboardDataLoading: false,
  dashboardDataList: [],
  dashboardDataListCount: 1,
  dashboardModalState: false,
  dashBoardDate: null,
  dashBoardDateLoading: false,
  dashboardScoreSettings: {
    labels: {
      apc: false,
      nd: true,
      nr: true,
      nrs: true,
      nad: false,
    },
    currentLargeScorecard: "TL_PERFORMANCE",
    currentStation: "1",
    currentDateTypeScorecardMonthly: true,
    currentDateTypeLabelDateType: "week",
    labelDate: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  dashboardTableRowsPerPage: 10,
};

export const initialAmazonState: AmazonState = {
  amazonHistoryLoading: false,
  csvHistoryLoading: false,
  amazonHistoryDataList: [],
  csvHistoryDataList: [],
  importHistoryDataListCount: 1,
  importPDFTableRowsPerPage: 10,
  isAmazonPage: true,
};

export const initialScorecardState: ScorecardState = {
  externalSettingActive: false,
  externalSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  daScoreTrendSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
    },
  },
  totalScoreboardSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
    },
  },
  topTenShiftSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  topTenDasSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
    },
  },
  rolesSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  returnSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      startDate: moment().format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  vansSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  missingDeliveriesSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  cashOnDeliverySettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
      day: moment().format("DD-MM-yyyy"),
    },
  },
  lateAttendanceSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      day: moment().format("DD-MM-yyyy"),
      year: moment().format("yyyy"),
    },
  },
  launchpadSupportSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      day: moment().format("DD-MM-yyyy"),
      year: moment().format("yyyy"),
    },
  },
  morningChecklistSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      day: moment().format("DD-MM-yyyy"),
      year: moment().format("yyyy"),
    },
  },
  rescueSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      day: moment().format("DD-MM-yyyy"),
      year: moment().format("yyyy"),
    },
  },
  returnedDataByDriverSettings: {
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      day: moment().format("DD-MM-yyyy"),
      year: moment().format("yyyy"),
    },
  },
};

export const initialWeightsState: WeightsState = {
  weightsLoading: false,
  weightsData: null,
};

export const initialFleetSettingsState: FleetSettingsState = {
  isFleetSettingsPending: false,
  fleetSettingsData: [],
  reportListCount: 1,
};

export const initialCheckState: CheckState = {
  loadingData: false,
  dataList: [],
  dataListCount: 1,
  dataTableRowsPerPage: 10,
  currentWeekId: null,
  currentWeekData: null,
  currentWeekLoading: false,
  uploadFileLoading: false,
  changeDataLoading: false,
  avergeScoreData: null,
  reportListFilters: {
    pageNumber: 0,
    rangeDateOne: "",
    rangeDateTwo: "",
  },
};

export const initialIkeaCheckState: IkeaCheckState = {
  loadingData: false,
  dataList: [],
  dataListCount: 1,
  dataTableRowsPerPage: 10,
  currentWeekId: null,
  currentWeekData: null,
  currentWeekLoading: false,
  changeDataLoading: false,
  avergeScoreData: null,
  reportListFilters: {
    pageNumber: 0,
    rangeDateOne: "",
    rangeDateTwo: "",
  },
};

export const initialActState: ActState = {
  loadingData: false,
  dataList: [],
  dataListCount: 1,
  dataTableRowsPerPage: 10,
  reportListFilters: {
    pageNumber: 0,
    weekDate: "",
    offTargetArea: null,
    driver: null,
    owner: null,
    action: 0,
    status: 0,
    dueDate: "",
    coachingCount: 0,
    ordering: "-week_number",
  },
};

export const initialIkeaActState: IkeaActState = {
  loadingData: false,
  dataList: [],
  dataListCount: 1,
  dataTableRowsPerPage: 10,
  reportListFilters: {
    pageNumber: 0,
    weekDate: "",
    offTargetArea: null,
    driver: null,
    owner: null,
    action: 0,
    status: 0,
    dueDate: "",
    coachingCount: 0,
    ordering: "-week_number",
  },
};

export const initialCustomGraphState: CustomGraphsState = {
  page: null,
  endpoints: [],
  loadingData: false,
  boards: [],
  pageData: null,
};

export const initialModalState: ModalState = {
  isOpen: false,
  modalName: "",
};

export const initialPerformanceState: PerfomanceState = {
  perfomanceSettings: {
    currentScorecard: "TL_PERFORMANCE",
    station: "1",
    selectType: "week",
    date: {
      week: moment().format("W"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
    },
  },
};
