import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxHeight: "calc(100vh - 180px)",
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "5px",
    },
  },
  emptyTable: {
    alignSelf: "center",
  },
  pagination: {
    display: "flex",
  },
  filter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
    "@media (max-width: 1200px)": {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "@media (max-width: 700px)": {
      padding: theme.spacing(2, 1, 1),
      flexDirection: "column",
    }
  },
  buttons: {
    display: "flex",
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    gap: "10px",
  },
  leftButton: {
    boxSizing: "border-box",
    borderRadius: "25px 0 0 25px",
  },
  rightButton: {
    boxSizing: "border-box",
    borderRadius: "0 25px 25px 0",
  },
  tableContainer: {
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  tableCell: {
    padding: theme.spacing(2),
  },
  tableHeadCell: {
    background: Colors.WHITE,
  },
  tableHeadTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
  "@media (max-width: 700px)": {
    buttonBox: {
      width: "100%",
      flexDirection: "column",
    },
    buttons: {
      width: "100%",
    },
    leftButton: {
      width: "50%",
    },
    rightButton: {
      width: "50%",
    },
  }
}));
