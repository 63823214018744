import { UploadFileDialog } from "components/modals/modalContent/UploadFileDialog";
import { UploadCsvFileDialog } from "components/modals/modalContent/UploadCsvFileDialog";
import { UploadReliabilityFileDialog } from "components/modals/modalContent/UploadReliabilityFileDialog";
import { UploadCheckCSVFilesDialog } from "components/modals/modalContent/UploadCheckCSVFilesDialog";
import { UploadExelWhcDialog } from "components/modals/modalContent/UploadExelWhcDialog";
import { UploadDeliveriesCsvFileDialog } from "components/modals/modalContent/UploadDeliveriesCsvFileDialog";

export const pdfUploadButtons = [
  {
    modalName: "upload-amazon-modal",
    modalTitle: "Upload Weekly Amazon Report (.pdf)",
    modalDescription: "Attention! Fill in the number of the page where “DA Current Week Performance” table starts. After uploading files, please waiting to processing data. It should be around 10 min.",
    component: <UploadFileDialog />,
  },
  {
    modalName: "upload-reliability-data-modal",
    modalTitle: "Upload Reliability Scores (.pdf)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 15 sec.",
    component: <UploadReliabilityFileDialog />,
  },
]
  
export const csvUploadButtons = [
  {
    modalName: "upload-new-csv-modal",
    modalTitle: "Upload Weekly Amazon Report (.csv)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 10 min.",
    component: <UploadCsvFileDialog/>,
  },
  {
    modalName: "upload-csv-modal",
    modalTitle: "Upload Check (AMZN) Report (.csv)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 15 sec.",
    component: <UploadCheckCSVFilesDialog/>,
  },
  {
    modalName: "upload-total-of-deliveries",
    modalTitle: "Upload Total Of Deliveries  (.csv)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 15 sec.",
    component: <UploadDeliveriesCsvFileDialog />,
  },
]
  
export   const xlsxUploadButtons = [
  {
    modalName: "upload-xlsx-whc-model",
    modalTitle: "Upload Check WHC Value (.xlsx)",
    modalDescription: "Attention! After uploading files, please waiting to processing data. It should be around 15 sec.",
    component: <UploadExelWhcDialog />,
  },
]
  