import { FC } from "react";
import { Box, Paper, Divider, Typography, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { Colors } from "constants/constants";
import { formatNumber } from "utils/formatNUmber";
import { DashboardInfoBlockProps } from "types/DashboardRecord";

export const DashboardInfoBlock: FC<DashboardInfoBlockProps> = ({
  Icon,
  title,
  currentValue,
  pastValue,
  currentDate,
  returnDetails,
}) => {
  const classes = useStyles();
  const notReturned = title !== "Number of returns";
  const difference = notReturned
    ? Math.round(currentValue) - Math.round(pastValue)
    : Math.round(pastValue) - Math.round(currentValue);
  const differenceCondition = notReturned
    ? Math.round(currentValue) > Math.round(pastValue)
    : Math.round(pastValue) < Math.round(currentValue);

  return (
    <Paper data-testid="testDashboardInfoBlock" className={classes.root}>
      <Box className={classes.box}>
        <Box className={classes.titleBox}>
          <Typography style={{fontWeight:600}} variant="h6">{title}</Typography>
          {
            title === "Number of returns" && returnDetails ? (
              <Tooltip
                title={
                  <Box>
                    <Typography>RTS Business Closed: {returnDetails?.business_closed}</Typography>
                    <Typography>RTS Customer Unavailable: {returnDetails?.customer_unavailable}</Typography>
                    <Typography>RTS No Secure Location: {returnDetails?.no_secure_location}</Typography>
                    <Typography>RTS other: {returnDetails?.other}</Typography>
                    <Typography>RTS Out of Drive Time: {returnDetails?.out_of_drive_time}</Typography>
                    <Typography>RTS Unable To Access: {returnDetails?.unable_to_access}</Typography>
                    <Typography>RTS Unable To Locate: {returnDetails?.unable_to_locate}</Typography>
                    <Typography>RTS unsafe due to dog: {returnDetails?.unsafe_due_to_dog}</Typography>
                  </Box>
                }
              >
                <Typography variant="h3">{formatNumber(Math.round(currentValue) || 0)}</Typography>
              </Tooltip>
            ) : (
              <Typography variant="h3">{formatNumber(Math.round(currentValue) || 0)}</Typography>
            )
          }
        </Box>
        <Box className={classes.titleBoxRight}>
          <Icon />
          <Typography>{currentDate}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className={classes.boxBottom}>
        {notReturned ? (
          <Alert
            icon={false}
            severity={differenceCondition ? "success" : "error"}
            className={differenceCondition ? classes.greenAlert : classes.redAlert} 
            style={{ padding: "0 10px" }}
          >
            <Typography
              variant="body2"
              style={differenceCondition ? { color: Colors.GREEN } : { color: Colors.RED }}
            >
              {difference > 0 ? `+${formatNumber(difference || 0)}` : formatNumber(difference || 0)}
            </Typography>
          </Alert>
        ) : (
          <Alert
            icon={false}
            severity={differenceCondition ? "error" : "success"}
            className={differenceCondition ? classes.redAlert : classes.greenAlert}
            style={{ padding: "0 10px" }}
          >
            <Typography
              variant="body2"
              style={differenceCondition ? { color: Colors.RED } : { color: Colors.GREEN }}
            >
              {difference === 0
                ? `0`
                : difference > 0
                ? `-${formatNumber(difference || 0)}`
                : `+${formatNumber(Math.abs(difference) || 0)}`}
            </Typography>
          </Alert>
        )}

        <Typography variant="body2">From previous period</Typography>
      </Box>
    </Paper>
  );
};

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    gap: "10px",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    boxShadow: "none",
    borderRadius: 15,
    borderBottom: "#e8e9ee 2px solid",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  titleBoxRight: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "flex-end",
  },
  boxBottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  greenAlert: {
    background: "#cefad0",
  },
  blueAlert: {
    background: "#e2f1ff",
  },
  redAlert: {
    background: "#ffe3e6",
  },
  orangeAlert: {
    background: "#f7f5bc",
  }
}));