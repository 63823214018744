import { Box, Button, IconButton, Modal, Tooltip, Typography } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import api from "api";
import { UtilizationVanTableMonth } from "components/tables/UtilizationVanTable/UtilizationVanTableMonth";
import { UtilizationVanTableYear } from "components/tables/UtilizationVanTable/UtilizationVanTableYear";
import { UtilizationVanTableWeek } from "components/tables/UtilizationVanTable/UtilizationVanTableWeek";
import { customToast } from "hooks/toast";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTypeSelectors } from "hooks/useTypeSelectors";

interface Data {
  vans_data: {
    van__number: number;
    counter: number;
    van__van_group__fleet_ownership: number | null;
  }[];
  number_rental_vans: string;
  number_owned_vans: string;
  number_shifts_for_rental_vans: string;
  number_shifts_for_owned_vans: string;
  number_unregister_vans: string;
  unregister_vans: { number: number; counter: number }[];
}

interface FleetTableModalType {
  requestDataTooltip: Data | null;
  selectType: string;
  station: string;
  week: number;
  month: number;
  year: number;
}

export const FleetTableModalTooltip: FC<FleetTableModalType> = ({
  requestDataTooltip,
  selectType,
  station,
  week,
  month,
  year,
}) => {
  const { userData } = useTypeSelectors((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const generateDateToPDF = () => {
    if (selectType === "month") return `${month}, ${year}`;
    if (selectType === "week") return `${week}, ${year}`;
    if (selectType === "annual") return `${year}`;
  };

  const handleDownloadPdf = () => {
    customToast("loading", "Generating PDF...");
    const data = document.getElementById("fleetPdf");
    if (data)
      html2canvas(data)
        .then((canvas) => {
          const contentDataURL = canvas.toDataURL("image/png");
          let margin = 0;
          let imgWidth = 210;
          let pageHeight = 295;
          let imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let doc = new jsPDF("p", "mm");
          let position = 0;

          doc.addImage(
            contentDataURL,
            "PNG",
            margin,
            position,
            imgWidth,
            imgHeight,
            "someAlias",
            "FAST",
          );

          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(
              contentDataURL,
              "PNG",
              margin,
              position,
              imgWidth,
              imgHeight,
              "someAlias",
              "FAST",
            );
            heightLeft -= pageHeight;
          }
          doc.save(`Fleet_UT_${station}_by_${generateDateToPDF()}.pdf`);
        })
        .catch(() => {
          customToast("error", "Something was wrong!");
        });
  };

  useEffect(() => {
    if (selectType === "week") {
      api
        .getFleetWeekTable({
          station,
          year,
          week,
        })
        .then((res) => {
          if (res?.data) setData(res.data);
        })
        .catch((err) => console.error(err));
    }
    if (selectType === "month") {
      api
        .getFleetMonthTable({
          station,
          year,
          month,
        })
        .then((res) => {
          if (res?.data) setData(res.data);
        })
        .catch((err) => console.error(err));
    }
    if (selectType === "annual") {
      api
        .getFleetYearTable({
          station,
          year,
        })
        .then((res) => {
          if (res?.data) setData(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [year, month, week, selectType, station]);

  const conditionStation = userData?.station_spots.find((el) => el.station.id === +station);

  return (
    <>
      <Tooltip
        title={
          <Box>
            <Box style={{ padding: "5px" }}>
              <Typography>
                Rental Vans: <b>{requestDataTooltip?.number_rental_vans}</b>
              </Typography>
            </Box>
            <Box style={{ padding: "5px" }}>
              <Typography>
                Rental Shifts: <b>{requestDataTooltip?.number_shifts_for_rental_vans}</b>
              </Typography>
            </Box>
            <Box style={{ padding: "5px" }}>
              <Typography>
                Unregister Vans: <b>{requestDataTooltip?.number_unregister_vans}</b>
              </Typography>
            </Box>
          </Box>
        }
      >
        <IconButton
          style={{ padding: 0 }}
          onClick={() => {
            if (selectType !== "day") setOpen(true);
          }}
        >
          <InfoOutlinedIcon color="primary" style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          style={{
            backgroundColor: "white",
            boxSizing: "border-box",
            padding: "10px",
          }}
        >
          <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4">Fleet utilization data</Typography>
            <Button onClick={handleDownloadPdf}>
              <GetAppIcon />
            </Button>
          </Box>

          <Box style={{ width: "100%", boxSizing: "border-box" }}>
            {selectType === "week" && (
              <UtilizationVanTableWeek
                data={data}
                date={String(week)}
                station={conditionStation?.station?.title}
              />
            )}
            {selectType === "month" && (
              <UtilizationVanTableMonth
                data={data}
                date={String(month)}
                station={conditionStation?.station?.title}
              />
            )}
            {selectType === "annual" && (
              <UtilizationVanTableYear
                data={data}
                date={String(year)}
                station={conditionStation?.station?.title}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
