import { FC, useEffect, useState } from "react";
import { TableCell, TableRow, Tooltip, Typography, Box, Checkbox } from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  FiberManualRecord,
  FiberManualRecordOutlined,
} from "@material-ui/icons";
import { DashboardRecord } from "types/DashboardRecord";
import { useRowStyles } from "./styles";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import Alert, { Color } from "@material-ui/lab/Alert";
import { Colors } from "constants/constants";
import { formatNumber } from "utils/formatNUmber";
import api from "api";

interface DashboardTableRowProps {
  row: DashboardRecord;
  index: number;
  week: number;
  year: number;
}

const DashboardTableRow: FC<DashboardTableRowProps> = ({ row, index, week, year }) => {
  const classes = useRowStyles();

  const [blockNotify, setBlockNotify] = useState(row?.ignore_sms);

  const handleBlockNotify = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBlockNotify(event.target.checked);
    api.blockNotificationDrivers(row?.id, event.target.checked);
  };

  useEffect(() => {
    setBlockNotify(row?.ignore_sms);
  }, [row]);

  const paintInTQuadrantCell = (value: string) => {
    const params = {
      alertClassName: classes.greenAlert,
      className: classes.greenCell,
      severity: "success",
    };
    if (value === "Star") {
      params.alertClassName = classes.blueAlert;
      params.className = classes.blueCell;
      params.severity = "info";
    }
    if (value === "Watchout") {
      params.alertClassName = classes.redAlert;
      params.className = classes.redCell;
      params.severity = "error";
    }
    return (
      <Alert
        className={params.alertClassName}
        icon={false}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
        </Typography>
      </Alert>
    );
  };

  const paintInFourColorCell = (
    supperWeight: number,
    highWeight: number,
    middleWeight: number,
    lowWeight: number,
    value = 0,
    percent?: boolean,
  ) => {
    const conditionValue = value || value === 0;
    const params = {
      alertClassName: classes.redAlert,
      className: classes.redCell,
      severity: "error",
    };
    if (!conditionValue) return null;
    if (conditionValue && value >= middleWeight) {
      params.alertClassName = classes.orangeAlert;
      params.className = classes.orangeCell;
      params.severity = "warning";
    }
    if (conditionValue && value >= highWeight) {
      params.alertClassName = classes.greenAlert;
      params.className = classes.greenCell;
      params.severity = "success";
    }
    if (conditionValue && value >= supperWeight) {
      params.alertClassName = classes.blueAlert;
      params.className = classes.blueCell;
      params.severity = "info";
    }

    return (
      <Alert
        className={params.alertClassName}
        icon={false}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
          {percent && "%"}
        </Typography>
      </Alert>
    );
  };

  const paintInFourColorCellReverseWithName = (
    supperWeight: number,
    highWeight: number,
    middleWeight: number,
    lowWeight: number,
    value = 0,
    percent?: boolean,
  ) => {
    const conditionValue = value || value === 0;
    const params = {
      alertClassName: classes.blueAlert,
      className: classes.blueCell,
      severity: "info",
    };
    if (!conditionValue) return null;
    if (conditionValue && value >= middleWeight) {
      params.alertClassName = classes.greenAlert;
      params.className = classes.greenCell;
      params.severity = "success";
    }
    if (conditionValue && value >= highWeight) {
      params.alertClassName = classes.orangeAlert;
      params.className = classes.orangeCell;
      params.severity = "warning";
    }
    if (conditionValue && value >= supperWeight) {
      params.alertClassName = classes.redAlert;
      params.className = classes.redCell;
      params.severity = "error";
    }

    return (
      <Alert
        className={params.alertClassName}
        icon={false}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
          {percent && "%"}
        </Typography>
      </Alert>
    );
  };

  const paintInThreeColorCell = (
    highWeight: number,
    middleWeight: number,
    lowWeight: number,
    value = 0,
    percent?: boolean,
  ) => {
    const conditionValue = value || value === 0;
    const params = {
      alertClassName: classes.greenAlert,
      className: classes.greenCell,
      severity: "success",
    };
    if (!conditionValue) return null;
    if (conditionValue && value >= highWeight) {
      params.alertClassName = classes.blueAlert;
      params.className = classes.blueCell;
      params.severity = "info";
    }
    if (conditionValue && value <= lowWeight) {
      params.alertClassName = classes.redAlert;
      params.className = classes.redCell;
      params.severity = "error";
    }
    return (
      <Alert
        className={params.alertClassName}
        icon={false}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
          {percent && "%"}
        </Typography>
      </Alert>
    );
  };

  const paintWeekTierCell = (value?: string) => {
    const params = {
      alertClassName: classes.greenAlert,
      className: classes.greenCell,
      severity: "success",
    };
    if (!value) return null;
    switch (value) {
      case "Fantastic":
        params.alertClassName = classes.blueAlert;
        params.className = classes.blueCell;
        params.severity = "info";
        break;
      case "Fair":
        params.alertClassName = classes.orangeAlert;
        params.className = classes.orangeCell;
        params.severity = "warning";
        break;
      case "Poor":
        params.alertClassName = classes.redAlert;
        params.className = classes.redCell;
        params.severity = "error";
        break;
      default:
        params.alertClassName = classes.greenAlert;
        params.className = classes.greenCell;
        params.severity = "success";
        break;
    }
    return (
      <Alert
        icon={false}
        className={params.alertClassName}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
        </Typography>
      </Alert>
    );
  };

  const paintInTwoColorCell = (weight: number, value = 0, percent?: boolean) => {
    const conditionValue = value || value === 0;
    const params = {
      alertClassName: classes.redAlert,
      className: classes.redCell,
      severity: "error",
    };
    if (conditionValue && value === weight) {
      params.alertClassName = classes.blueAlert;
      params.className = classes.blueCell;
      params.severity = "info";
    }
    return (
      <Alert
        icon={false}
        className={params.alertClassName}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
          {percent && "%"}
        </Typography>
      </Alert>
    );
  };

  const paintInTwoColorReverseCell = (weight: number, value = 0) => {
    const conditionValue = value || value === 0;
    const params = {
      alertClassName: classes.blueAlert,
      className: classes.blueCell,
      severity: "info",
    };
    if (conditionValue && value === weight) {
      params.alertClassName = classes.redAlert;
      params.className = classes.redCell;
      params.severity = "error";
    }
    return (
      <Alert
        className={params.alertClassName}
        icon={false}
        severity={params.severity as Color}
        style={{ padding: "0 5px", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="body2" className={params.className}>
          {value}
        </Typography>
      </Alert>
    );
  };

  const driverFullName = `${row?.driver?.first_name} ${row?.driver?.last_name}`;

  const paintWithArrows = (current: number, previous?: number | null) => {
    if (!previous)
      return <FiberManualRecordOutlined style={{ fontSize: 20, color: Colors.BLUE, width: 32 }} />;
    if (current < previous) return <ArrowDropDown style={{ color: Colors.RED, fontSize: 32 }} />;
    if (current > previous) return <ArrowDropUp style={{ color: Colors.GREEN, fontSize: 32 }} />;
    return <FiberManualRecord style={{ fontSize: 20, color: Colors.BLUE, width: 32 }} />;
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography style={{ paddingLeft: 13 }} variant="body2">
          {Number(index + 1)}
        </Typography>
      </TableCell>
      <TableCell>
        <Checkbox
          key={row?.id}
          name="blockNotify"
          checked={blockNotify}
          onChange={handleBlockNotify}
        />
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            <Typography variant="h5">
              {row?.driver?.first_name} {row?.driver?.last_name}
            </Typography>
          }
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            {paintWithArrows(row?.points_total, row?.old_points_total)}
            <Typography variant="h5">
              {driverFullName.length > 15 ? driverFullName.slice(0, 15) + "..." : driverFullName}
            </Typography>
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{row?.driver.transporter_id}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
          <Tooltip
            title={
              row?.old_points_total ? (
                <Typography variant="body1">{`Previous week: ${row?.old_points_total}`}</Typography>
              ) : (
                <Typography variant="body1">{"Previous week data not available"}</Typography>
              )
            }
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              {formatNumber(row?.points_total)}
            </Box>
          </Tooltip>
        </Typography>
      </TableCell>
      <TableCell>{row?.number_of_shifts}</TableCell>
      <TableCell>{paintInTQuadrantCell(row?.quadrant_name)}</TableCell>

      <TableCell>{paintWeekTierCell(row?.raw_data?.current_week_tier)}</TableCell>

      <TableCell>{paintWeekTierCell(row?.raw_data?.average_week6_tier)}</TableCell>

      <TableCell>{paintInFourColorCell(30, 25, 15, 14, row?.raw_data?.tenured)}</TableCell>

      <TableCell style={{ borderLeft: `1px solid ${Colors.GRAY}` }}>
        {paintInFourColorCell(99, 98, 97, 96, row?.raw_data?.dcr, true)}
      </TableCell>

      <TableCell>
        {Boolean(+year > 2023 || (+week > 34 && +year <= 2023))
          ? paintInFourColorCellReverseWithName(1000, 600, 200, 0, row?.raw_data?.dsb)
          : paintInFourColorCell(70, 50, 25, 24, row?.raw_data?.dar, true)}
      </TableCell>

      <TableCell>{paintInFourColorCell(98, 97, 96, 95, row?.raw_data?.pod, true)}</TableCell>

      <TableCell>{paintInFourColorCell(99.5, 98.5, 97.5, 97.4, row?.raw_data?.cc, true)}</TableCell>

      <TableCell>{paintInTwoColorCell(0, row?.raw_data?.sc)}</TableCell>

      <TableCell>{paintInTwoColorCell(0, row?.raw_data?.ced)}</TableCell>

      <TableCell>
        {paintInFourColorCell(4, 3, 2, 1, row?.raw_data?.high_performer_in_weeks)}
      </TableCell>

      <TableCell style={{ borderLeft: `1px solid ${Colors.GRAY}` }}>
        {paintInTwoColorReverseCell(0, row?.rescue)}
      </TableCell>

      <TableCell>{paintInTwoColorCell(100, row?.morning_checklist, true)}</TableCell>

      <TableCell>{paintInThreeColorCell(100, 91, 90, row?.ontime_attendance, true)}</TableCell>

      <TableCell>
        <Tooltip
          disableHoverListener={!row.text_message}
          title={<Typography variant="body1">{row.text_message}</Typography>}
        >
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <MessageOutlinedIcon
              style={{ color: `${!row.text_message ? "#d0d0d0" : Colors.MAIN}` }}
            />
          </Box>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default DashboardTableRow;
