import { ReducersInterfaceAction } from "types/Reducers";
import { ModalConstants } from "../constants/constants";
import { ModalState } from "../types/Modal";
import { initialModalState } from "store/initialStates";

export const modalReducer = (
  state = initialModalState,
  action: ReducersInterfaceAction,
): ModalState => {
  switch (action.type) {
    case ModalConstants.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        modalName: action.payload,
      };
    case ModalConstants.CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        modalName: "",
      };
    default:
      return state;
  }
};
