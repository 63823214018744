import { Box, CircularProgress, makeStyles } from "@material-ui/core";

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  const classes = useStyles();
  return isLoading ? (
    <Box className={classes.progressPage}>
      <CircularProgress color="primary" style={{ width: 100, height: 100 }} />
    </Box>
  ) : null;
};

export default Loader;

const useStyles = makeStyles((theme) => ({
  progressPage: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999,
    background: "rgba(0,0,0,0.3)",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
