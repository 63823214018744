import { FC, useState, useEffect } from "react";
import { Box, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

interface SearchInputProps {
  value: string;
  onChange: (val: string) => void;
  timeout?: number;
}

export const SearchInput: FC<SearchInputProps> = ({ value, onChange, timeout = 1000 }) => {
  const classes = useStyles();
  const [query, setQuery] = useState(value || "");

  useEffect(() => {
    const timeoutId = setTimeout(() => onChange(query), timeout);
    return () => clearTimeout(timeoutId);
  }, [onChange, timeout, query]);

  return (
    <Box data-testid="testSearchInput" className={classes.search}>
      <Box className={classes.searchIcon} color="primary">
        <SearchIcon />
      </Box>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search", "data-testid": "testSearchInputField" }}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "25px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: theme.spacing(6),
    width: "100%",
  },
}));
