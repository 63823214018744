import {
  LOCAL_PERMISSIONS,
  LOCAL_REFRESH,
  LOCAL_TOKEN,
  LOCAL_PERSIST_SCORECARD,
} from "../constants/constants";

const setItemToStorage = (data: string, key: string) => {
  data = JSON.stringify(data);
  if (data) return localStorage.setItem(key, data);
  else return false;
};

const getItemToStorage = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) return JSON.parse(data);
  else return null;
};

export const localUserService = {
  getAccess: () => getItemToStorage(LOCAL_TOKEN),
  getRefresh: () => getItemToStorage(LOCAL_REFRESH),
  getPermissions: () => getItemToStorage(LOCAL_PERMISSIONS),
  removeAccess: () => localStorage.removeItem(LOCAL_TOKEN),
  removeRefresh: () => localStorage.removeItem(LOCAL_REFRESH),
  removePermissions: () => localStorage.removeItem(LOCAL_PERMISSIONS),
  setAccess: (data: string) => setItemToStorage(data, LOCAL_TOKEN),
  setRefresh: (data: string) => setItemToStorage(data, LOCAL_REFRESH),
  setPermissions: (data: string) => setItemToStorage(data, LOCAL_PERMISSIONS),
  logout: () => {
    localStorage.removeItem(LOCAL_TOKEN);
    localStorage.removeItem(LOCAL_REFRESH);
    localStorage.removeItem(LOCAL_PERMISSIONS);
    localStorage.removeItem(LOCAL_PERSIST_SCORECARD);
    window.location.replace("/");
  },
};
