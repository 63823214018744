import { Box, Paper, TablePagination, Tooltip, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { checkTableWeeklyChangeRowsPerPage, checkTableWeeklyInfo, setCheckTablePagginationPage } from "actions/checkActions";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { EMPTY, SUPERADMIN } from "constants/constants";
import { TABLE_HEADERS_COLUMN } from "./checkTable";
import { CheckPageRow } from "./CheckPageRow";
import { CheckDateSelector } from "./CheckDateSelector"
import { CheckAverageScoreRow } from "./CheckAverageScoreRow"
import { CheckReportWeekRow } from "types/Check";
import Loader from "router/components/Loader";

interface PropsType {}

const CheckPage: FC<PropsType> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const { dataList, loadingData, dataTableRowsPerPage, dataListCount, avergeScoreData, reportListFilters } = useTypeSelectors(
    (state) => state.checkTable
  );

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(checkTableWeeklyChangeRowsPerPage(+event.target.value));
    dispatch(setCheckTablePagginationPage(0));
  };

  const handleChangePagination = (event: unknown, value: number) => {
    dispatch(setCheckTablePagginationPage(value));
  };

  useEffect(() => {
    dispatch(checkTableWeeklyInfo(
      reportListFilters?.pageNumber + 1, 
      dataTableRowsPerPage,
      reportListFilters?.rangeDateOne,
      reportListFilters?.rangeDateTwo,
    ));
  }, [
    dataTableRowsPerPage,
    reportListFilters?.pageNumber,
    reportListFilters,
    dispatch,
  ]);

  return (
    <Box data-testid="testCheckPage" className={classes.root}>
      <Loader isLoading={loadingData} />
      <Paper className={classes.filter}>
        <CheckDateSelector
          date_one={reportListFilters?.rangeDateOne}
          date_two={reportListFilters?.rangeDateTwo}
        />
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={dataListCount}
          rowsPerPage={dataTableRowsPerPage}
          page={reportListFilters?.pageNumber}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePagination}
        />
      </Paper>

      <Box className={classes.tableRoot}>
        {!dataList.length ? (
          <Typography variant="h1" className={classes.emptyTable}>
            {EMPTY}
          </Typography>
        ) : (
          <>
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {TABLE_HEADERS_COLUMN.map((el) => (
                        <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableHeadCell} ${classes.tableHeadWhiteCell}`}>
                          {
                            el?.toolTipLabel ? (
                              <Tooltip title={<Typography>{el.toolTipLabel}</Typography>}>
                                <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                              </Tooltip>
                            ) : (
                              <Typography className={classes.tableHeadTypography}>{el.label}</Typography>
                            )
                          }
                        </TableCell>
                      )
                    )}
                  </TableRow>
                  <TableRow>
                    {TABLE_HEADERS_COLUMN.map((el) =>
                      el.id !== "date" && el.id !== "activities" ? (
                        <>
                          <TableCell
                            className={`${classes.tableCell} ${classes.tableHeadWhiteCell} ${classes.tableCellPaddingLeft}`}
                          >
                            <Tooltip title={<Typography>Current Score</Typography>}>
                              <Typography>S</Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}
                          >
                            <Tooltip title={<Typography>DA off target</Typography>}>
                              <Typography>#</Typography>
                            </Tooltip>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableHeadWhiteCell}`}/>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportListFilters?.rangeDateOne && (
                    <CheckAverageScoreRow
                      data={avergeScoreData} 
                      loadingData={loadingData}/>
                  )}
                  {dataList
                    .map((el:CheckReportWeekRow, index:number) => (
                      <CheckPageRow 
                        key={`${index}${el.id}`}
                        row={el}
                        premissionToEdit={userData.role === SUPERADMIN}
                      />
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CheckPage;

