import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Typography } from "@material-ui/core";
import { Colors, ScorecardConstants } from "constants/constants";
import { handleGetDAR } from "../requests";
import { StationSelect } from "components/elements/StationSelect";
import { SearchTypeSelect } from "components/elements/SearchTypeSelect";
import { MonthSelect } from "components/elements/MonthSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useStyles } from "./styles";
import { ScorecardOpenButton } from "components/buttons/ScorecardOpenButton";
import { ScorecardCompareButton } from "components/buttons/ScorecardCompareButton";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useDispatch } from "react-redux";
import {
  ScorecardExternalSetDate,
  ScorecardSetSelectType,
  ScorecardSetStation,
} from "actions/scorecardActions";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const DARWorst = ({ withModal = true, withCompare = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useTypeSelectors((state) => state.auth);
  const { externalSettingActive, externalSettings, cashOnDeliverySettings } = useTypeSelectors(
    (state) => state.scoreCardSettings
  );
  const defaultStationCondition = userData?.station_spots.some(
    (el: { station: { title: string; id: number } }) => el.station.id === 1
  )
    ? 1
    : userData?.station_spots[0]?.station?.id;
  const [compareStation, setCompareStation] = useState(`${defaultStationCondition}`);
  const [requestData, setRequestData] = useState<[] | null>(null);

  const handleChangeStation = (data: string) =>
    !withCompare
      ? setCompareStation(data)
      : dispatch(ScorecardSetStation(data, ScorecardConstants.DAR_SET_CURRENT_STATION));
  const handleChangeSelectType = (select: string) =>
    dispatch(ScorecardSetSelectType(select, ScorecardConstants.DAR_SET_SELECT_TYPE));
  const handleChangeWeek = (week: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...cashOnDeliverySettings?.date, week },
        ScorecardConstants.DAR_SET_DATE
      )
    );
  const handleChangeMonth = (month: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...cashOnDeliverySettings?.date, month },
        ScorecardConstants.DAR_SET_DATE
      )
    );
  const handleChangeYear = (year: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...cashOnDeliverySettings?.date, year },
        ScorecardConstants.DAR_SET_DATE
      )
    );

  const handleChangeDayDate = (date: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...cashOnDeliverySettings?.date, day: date },
        ScorecardConstants.DAR_SET_DATE
      )
    );

  const {
    week,
    month,
    year,
    date,
    selectType,
    onTypeChange,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
  } = useDateWeekSelector({
    defaultWeek: cashOnDeliverySettings?.date?.week,
    defaultMonth: cashOnDeliverySettings?.date?.month,
    defaultYear: cashOnDeliverySettings?.date?.year,
    defaultSelectType: cashOnDeliverySettings?.selectType,
    onSelectTypeSet: handleChangeSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
    onDateSet: handleChangeDayDate,
  });

  const conditionLocalStation = !withCompare ? compareStation : cashOnDeliverySettings?.station;

  useEffect(() => {
    if (
      (conditionLocalStation && (month || week)) ||
      (externalSettings?.station && externalSettings?.selectType && externalSettings?.date)
    ) {
      const params = externalSettingActive
        ? {
            station: externalSettings?.station,
            ...(externalSettings?.selectType === "month" && {
              month: +externalSettings?.date?.month,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "week" && {
              week: +externalSettings?.date?.week,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "day" && { day: externalSettings?.date?.day }),
          }
        : {
            station: conditionLocalStation,
            year: year,
            ...(selectType === "month" && { year, month }),
            ...(selectType === "week" && { year, week }),
            ...(selectType === "day" && { day: date }),
          };
      handleGetDAR(params, setRequestData);
    }
  }, [
    cashOnDeliverySettings?.station,
    year,
    month,
    week,
    date,
    selectType,
    externalSettingActive,
    externalSettings?.station,
    externalSettings?.selectType,
    externalSettings?.date,
    conditionLocalStation,
  ]);

  const labels = requestData ? requestData.map((el: { name: string }) => el.name).reverse() : [];

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Counter",
        data: requestData
          ? requestData
              .reverse()
              .map((el: { dar_value: number; name: string }, index) =>
                el.name === labels[index] ? el.dar_value : null
              )
          : [],
        backgroundColor: Colors.PASTEL_RED,
        minBarLength: 3,
        barThickness: 15,
        borderRadius: 25,
      },
    ],
  };

  return (
    <Box className={classes.root}>
      {!externalSettingActive && (
        <Box className={classes.head}>
          <Box className={classes.box}>
            <StationSelect
              value={!withCompare ? compareStation : cashOnDeliverySettings?.station}
              onChange={handleChangeStation}
              stations={userData?.station_spots}
            />
            <SearchTypeSelect
              value={selectType}
              onChange={onTypeChange}
              options={[
                { id: "week", title: "Week" },
                { id: "month", title: "Month" },
              ]}
            />
            {selectType === "month" && (
              <MonthSelect
                month={month}
                year={year}
                onNext={onMonthIncrease}
                onPrev={onMonthReduce}
                disabled={dateConditionMonth()}
              />
            )}
            {selectType === "week" && (
              <WeekSelect
                week={week}
                year={year}
                onNext={onWeekIncrease}
                onPrev={onWeekReduce}
                disabled={dateCondition()}
              />
            )}
          </Box>
          <Box>
            {withCompare && (
              <ScorecardCompareButton
                children={<DARWorst withModal={false} withCompare={false} />}
                children2={<DARWorst withModal={false} withCompare={false} />}
              />
            )}
            {withModal && <ScorecardOpenButton children={<DARWorst withModal={false} />} />}
          </Box>
        </Box>
      )}
      <Typography variant="body2" style={{ width: "100%", textAlign: "center" }}>
        DAR
      </Typography>
      <Bar options={options} data={dataChart} />
    </Box>
  );
};

const options = {
  responsive: true,
  scales: {
    y: {
      title: {
        display: false,
        text: "Value",
      },
      min: 0,
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
    x: {
      ticks: {
        color: `${Colors.MAIN}`,
        font: {
          weight: "700",
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Cash on Delivery",
    },
  },
};
