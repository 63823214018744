import { Box, Grid, Paper } from "@material-ui/core";
import { FC } from "react";
import { useStyles } from "./styles";
import { RenderMainScoreboard } from "./RenderMainScoreBoard";
import { PerfomaceHead } from "./PerformanceHead";
import { ReliabilityChart } from "./PerformanceCharts/ReliabilityChart";

interface PropsType {}

const PerfomancePage: FC<PropsType> = () => {
  const classes = useStyles();

  return (
    <Box data-testid="testPerfomancePage" className={classes.root}>
      <Paper className={classes.paper}>
        <PerfomaceHead />
      </Paper>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={6} xl={12}>
          <Paper className={classes.graphPaper}>
            <Box className={classes.performersChartBox}>{RenderMainScoreboard()}</Box>
          </Paper>
        </Grid>
        <Grid item sm={12} lg={6} xl={12}>
          <Paper className={classes.graphPaper}>
            <ReliabilityChart />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PerfomancePage;
