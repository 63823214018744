import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { localUserService } from "utils/localStorage";
import { checkAuthFromStorage } from "actions/authAction";
import { initializeApp } from "actions/initializeActions";
import theme from "./theme";
import Loader from "router/components/Loader";
import Router from "router/Router";
import "./App.css";

function App() {
  const dispatch = useDispatch();
  const isInitialize = useRef(false);
  const { isLoginPending } = useTypeSelectors((state) => state.auth);
  const { amazonHistoryLoading } = useTypeSelectors((state) => state.amazonReports);
  const { reportListLoading } = useTypeSelectors((state) => state.dasBoard);
  const { dashboardDataLoading, dashBoardDateLoading } = useTypeSelectors(
    (state) => state.dashboard
  );
  const { weightsLoading } = useTypeSelectors((state) => state.weightsData);

  const generalLoading =
    amazonHistoryLoading ||
    isLoginPending ||
    dashboardDataLoading ||
    dashBoardDateLoading ||
    weightsLoading ||
    reportListLoading;

  const token = localUserService.getAccess();

  useEffect(() => {
    if (token && !isInitialize.current) {
      dispatch(checkAuthFromStorage());
      dispatch(initializeApp());
      isInitialize.current = true;
    } else {
      isInitialize.current = false;
    }
  }, [token, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Loader isLoading={generalLoading} />
      <Router />
    </ThemeProvider>
  );
}

export default App;
