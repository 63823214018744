import * as EmailValidator from "email-validator";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Input, FormControl, Button, FormHelperText } from "@material-ui/core";

import { useTypeSelectors } from "hooks/useTypeSelectors";
import {
  AuthFormErrorState,
  AuthFormState,
  AuthFormStateField,
  AuthFormStateFields,
  AuthPasswordReset,
  AuthPasswordResetError,
  AuthPasswordResetField,
  AuthPasswordResetFields,
} from "types/Auth";
import {
  authLoginRequest,
  handleChangeAuthModalState,
  handleChangeModalContainer,
  passwordReset,
} from "actions/authAction";
import { FC } from "react";

interface AuthFormProps {
  testModalState?:boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      fontFamily: theme.typography.fontFamily,
      width: 400,
      padding: theme.spacing(4, 6, 5),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "@media (max-width: 750px)": {
        width: "100%",
      },
    },
    title: {
      fontSize: "40px",
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      display: "flex",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignSelf: "center",
      height: "100%",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    error: {
      height: theme.spacing(3),
      color: "red",
    },
    button: {
      fontSize: 18,
      marginTop: theme.spacing(1),
    },
    buttonRestore: {
      marginTop: theme.spacing(2),
      display: "flex",
      alignSelf: "center",
    },
  })
);

const validateLogin = (values: AuthFormState) => {
  const errors: AuthFormErrorState = { email: undefined, password: undefined };
  if (values) {
    const requiredFields: AuthFormStateFields = ["email", "password"];
    requiredFields.forEach((field: AuthFormStateField) => {
      if (!values[field]) {
        errors[field] = "Required";
      }
    });
    if (values.email && !EmailValidator.validate(values.email)) {
      errors.email = "Not valid";
    }
    return errors;
  }
};

const validatePasswordReset = (values: AuthPasswordReset) => {
  const errors: AuthPasswordResetError = { email: undefined };
  if (values) {
    const requiredFields: AuthPasswordResetFields = ["email"];
    requiredFields.forEach((field: AuthPasswordResetField) => {
      if (!values[field]) {
        errors[field] = "Required";
      }
    });
    if (values.email && !EmailValidator.validate(values.email)) {
      errors.email = "Not valid";
    }
    return errors;
  }
};

export const AuthForm:FC<AuthFormProps> = ({testModalState}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { authModalStateLogin } = useTypeSelectors((state) => state.auth);

  const onSubmit = (values: AuthFormState) => {
    authModalStateLogin ? dispatch(authLoginRequest(values)) : dispatch(passwordReset(values));
    dispatch(handleChangeAuthModalState());
  };

  const handleModalContainerChange = () => {
    dispatch(handleChangeModalContainer());
  };

  return (
    <Box data-testid="testAuthForm" className={classes.paper}>
      {!testModalState && authModalStateLogin ? (
        <>
          <h2 className={classes.title}>Login</h2>
          <Form
            onSubmit={onSubmit}
            validate={validateLogin}
            render={({ handleSubmit }) => (
              <>
                <FormControl component="fieldset" className={classes.box}>
                  <h4 className={classes.subtitle}>Email</h4>
                  <Field
                    name="email"
                    type="email"
                    render={({ input, meta }) => (
                      <>
                        <Input {...input} data-testid="testAuthInputEmail"/>
                        <FormHelperText className={classes.error}>
                          {meta.touched && meta.error}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
                <FormControl component="fieldset" className={classes.box}>
                  <h4 className={classes.subtitle}>Password</h4>
                  <Field
                    name="password"
                    type="password"
                    render={({ input, meta }) => (
                      <>
                        <Input {...input} data-testid="testAuthInputPassword"/>
                        <FormHelperText className={classes.error}>
                          {meta.touched && meta.error}
                        </FormHelperText>
                      </>
                    )}
                  />
                </FormControl>
                <Button
                  data-testid="testAuthFormBtnSubmitOne"
                  type="submit"
                  aria-label="open drawer"
                  size="medium"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          />
        </>
      ) : (
        <>
          <h2 className={classes.title}>Password reset</h2>
          <Form
            onSubmit={onSubmit}
            validate={validatePasswordReset}
            render={({ handleSubmit }) => (
              <FormControl component="fieldset" className={classes.box}>
                <h4 className={classes.subtitle}>Email</h4>
                <Field
                  name="email"
                  type="email"
                  render={({ input, meta }) => (
                    <>
                      <Input {...input} data-testid="testAuthInputEmailTwo"/>
                      <FormHelperText className={classes.error}>
                        {meta.touched && meta.error}
                      </FormHelperText>
                    </>
                  )}
                />
                <Button
                  data-testid="testAuthFormBtnSubmitTwo"
                  type="submit"
                  aria-label="open drawer"
                  size="medium"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </FormControl>
            )}
          />
        </>
      )}
      <Button
        data-testid="testAuthFormBtnForgetPassword"
        variant="text"
        className={classes.buttonRestore}
        onClick={handleModalContainerChange}
      >
        {authModalStateLogin ? "Forget password ?" : "Login"}
      </Button>
    </Box>
  );
};
