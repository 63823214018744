import { FC } from "react";
import { DialogActions } from "@material-ui/core";
import { Colors } from "constants/constants";
import { useDispatch } from "react-redux";
import { closeModal } from "actions/modalActions";
import { useDialogStyles } from "../styles";
import { DialogButton } from "components/buttons/DialogButton";

interface DriverNotifyDialogProps {
  onSubmit: () => void;
}

export const DriverNotifyDialog: FC<DriverNotifyDialogProps> = ({ onSubmit }) => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModal());
  const handleSubmit = () => {
    onClose();
    onSubmit();
  };
  return (
    <>
      <DialogActions data-testid="testDriverNotifyModalActions" className={classes.dialogActions}>
        <DialogButton
          testId={"disagreeDriverNotifyDialogButton"}
          buttonName={"Disagree"}
          onClick={onClose}
          customStyle={{ background: Colors.RED, color: "#fff" }}
        />
        <DialogButton
          testId={"agreeDriverNotifyDialogButton"}
          buttonName={"Agree"}
          onClick={handleSubmit}
          customStyle={{ background: Colors.BLUE, color: "#fff" }}
        />
      </DialogActions>
    </>
  );
};
