import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "constants/constants";

export const useStyles = makeStyles((theme) => ({
  tableHeadCell: {
    cursor: "default",
    textAlign: "center",
    padding: theme.spacing(1.5, 1.5, 1.5, 4),
  },
  tableHeadWhiteCell: {
    background: Colors.WHITE,
  },
  tableHeadTypography: {
    fontSize: 16,
    fontWeight: 600,
  },
  tableCell: {
    cursor: "default",
    textAlign: "center",
    padding: theme.spacing(1.5),
  },
  greenCell: {
    color: Colors.GREEN,
    background: "#cefad0",
  },
  yellowCell: {
    color: Colors.YELLOW,
    background: "#f7f5bc",
  },
  redCell: {
    color: Colors.RED,
    "MuiAlert-standardError": {
      background: "#ffe3e6",
    }
  },
  greenAlert: {
    background: "#cefad0",
  },
  redAlert: {
    background: "#ffe3e6",
  },
  orangeAlert: {
    background: "#f7f5bc",
  },
  weekSelectorBox: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
