import { IconButton, TableCell, TableRow, Typography } from "@material-ui/core";
import { FC } from "react";
import { CheckReportWeekRow, GlobalTarget } from "types/Check";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useStyles } from "./styles";
import { useNav } from "hooks/useNav";
import { TABLE_HEADERS_COLUMN } from "./checkTable";
import { RenderInfo } from "./RenderInfo";
import { updateCheckTableWeek } from "actions/checkActions";
import { useDispatch } from "react-redux";

interface CheckPageRowProps {
  row: CheckReportWeekRow;
  premissionToEdit: boolean;
}

export const CheckPageRow: FC<CheckPageRowProps> = ({ row, premissionToEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { viewCurrentWeekCheck } = useNav();

  const { id, year, week_number, target } = row;

  const handleDataChange = (id: number, name: string, value: number) =>
    dispatch(
      updateCheckTableWeek(id, {
        [name]: value,
      }),
    );

  return (
    <TableRow hover>
      <TableCell
        colSpan={2}
        className={`${classes.tableCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}
      >
        <Typography>
          {week_number + 1}.{year}
        </Typography>
      </TableCell>

      {TABLE_HEADERS_COLUMN.map((el, i) =>
        i > 0 && i < TABLE_HEADERS_COLUMN.length - 1
          ? RenderInfo(el.id, row, getGlobalTargetValue(el?.id, target), handleDataChange, premissionToEdit)
          : null,
      )}

      <TableCell colSpan={2} className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
        <IconButton onClick={() => viewCurrentWeekCheck(id)}>
          <OpenInNewIcon color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const getGlobalTargetValue = (key: string, data: GlobalTarget | null | undefined) => {
  switch (key) {
    case "dcr":
      return { name: "dcr", target: data?.dcr };
    case "dnr":
      return { name: "dnr", target: data?.dnr };
    case "dsb":
      return { name: "dsb", target: data?.dsb };
    case "pod":
      return { name: "pod", target: data?.pod };
    case "cc":
      return { name: "cc", target: data?.cc };
    case "ada":
      return { name: "ada", target: data?.ada };
    case "violation":
      return { name: "violation", target: data?.violation };
    case "whc":
      return { name: "whc", target: data?.whc };
    case "rd":
      return { name: "rd", target: data?.rescue_given };
    case "mc":
      return { name: "mc", target: data?.mc };
    case "oa":
      return { name: "oa", target: data?.oa };
    case "cas":
      return { name: "cas", target: data?.cas };
    case "hp6w":
      return { name: "hp6w", target: data?.hp6w };
    case "es":
      return { name: "es", target: data?.employee_satisfaction };

    default:
      return { name: "", target: null };
  }
};
