import { Box, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import { DashboardInfoBlock } from "components/elements/DashboardInfoBlock";
import moment from "moment";
import { useState, useEffect, FC } from "react";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import {
  handleGetReturnedDelivered,
  handleGetCsvReturnedDelivered,
  handleGetRoutes,
} from "./DashboardCharts/requests";
import Loader from "router/components/Loader";
import { useDashboardScores } from "hooks/useDashboardScores";
import {
  DashboardInfoBlockProps,
  DeliveredReturnedType,
  RoutesActiveDaysType,
} from "types/DashboardRecord";

export interface AverageStatusType {
  ratings: {
    actual: {
      star: number;
      road_runner: number;
      team_player: number;
      watchout: number;
    };
    prev: {
      star: number;
      road_runner: number;
      team_player: number;
      watchout: number;
    };
  };
  points: {
    actual: {
      average_point: number;
    };
    prev: {
      average_point: number;
    };
  };
}

interface DashboardScoresType {}

export const DashboardScores: FC<DashboardScoresType> = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [csvDeliveredReturnedData, setCsvDeliveredReturnedData] =
    useState<DeliveredReturnedType | null>(null);
  const [deliveredReturnedData, setDeliveredReturnedData] = useState<DeliveredReturnedType | null>(
    null,
  );
  const [routesData, setRoutesData] = useState<RoutesActiveDaysType | null>(null);
  const { dashboardScoreSettings } = useTypeSelectors((state) => state.dashboard);
  const { mockedInfoData } = useDashboardScores({
    defaultStation: dashboardScoreSettings?.currentStation,
    defaultCsvDeliveredData: csvDeliveredReturnedData,
    defaultDeliveredData: deliveredReturnedData,
    defaultRoutesData: routesData,
  });

  const currentStation = dashboardScoreSettings?.currentStation || "1";

  const currentYear = moment().format("yyyy");

  useEffect(() => {
    const params = {
      station: currentStation,
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "annual" && {
        two_year: Number(dashboardScoreSettings?.labelDate?.year),
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "month" && {
        two_month: `${dashboardScoreSettings?.labelDate?.year}-${dashboardScoreSettings?.labelDate?.month}-01`,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "week" && {
        two_week: Number(dashboardScoreSettings?.labelDate?.week),
        year: dashboardScoreSettings?.labelDate?.year
          ? +dashboardScoreSettings?.labelDate?.year
          : +currentYear,
      }),
      ...(dashboardScoreSettings?.currentDateTypeLabelDateType === "day" && {
        two_day: dashboardScoreSettings?.labelDate?.day,
      }),
    };
    if (dashboardScoreSettings?.currentStation === "1") {
      handleGetCsvReturnedDelivered(params, setLoading, setCsvDeliveredReturnedData);
      handleGetReturnedDelivered(params, setLoading, setDeliveredReturnedData);
      handleGetRoutes(params, setLoading, setRoutesData);
    } else {
      handleGetReturnedDelivered(params, setLoading, setDeliveredReturnedData);
      handleGetRoutes(params, setLoading, setRoutesData);
    }
  }, [
    currentStation,
    dashboardScoreSettings?.currentDateTypeLabelDateType,
    dashboardScoreSettings?.currentStation,
    dashboardScoreSettings?.labelDate,
    dashboardScoreSettings?.labelDate?.week,
    dashboardScoreSettings?.labelDate?.month,
    dashboardScoreSettings?.labelDate?.year,
    currentYear,
  ]);

  return (
    <Box data-testid="testDashboardScores" className={classes.root}>
      <Loader isLoading={loading} />
      <Grid container spacing={3}>
        <Grid item sm={12} md={8} lg={12}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            {mockedInfoData.map((el: DashboardInfoBlockProps) => (
              <Grid key={el.title} item sm={12} md={12} lg={4}>
                <DashboardInfoBlock
                  title={el.title}
                  Icon={el.Icon}
                  currentValue={el?.currentValue || 0}
                  pastValue={el?.pastValue || 0}
                  currentDate={el.currentDate}
                  returnDetails={el?.returnDetails}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
