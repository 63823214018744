import { IconButton, TableCell, TableRow, Typography, Input } from "@material-ui/core";
import { FC } from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useStyles } from "./styles";
import { useNav } from "hooks/useNav";
import { useDispatch } from "react-redux";
import { inputNumberValidate, inputNumberValidateOnPaste } from "utils/formats";
import { updateCheckIkeaTableWeek } from "actions/ikeaCheckActions";
import { formatNumberType } from "utils/formatNUmber";
import { IkeaCheckReportWeekRow } from "types/IkeaCheck";
import { OnBlurEvent } from "types/general";

interface IkeaCheckPageRowProps {
  row: IkeaCheckReportWeekRow;
}

export const IkeaCheckPageRow: FC<IkeaCheckPageRowProps> = ({ row }) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const { viewCurrentWeekIkeaCheck } = useNav();

  const {
    id,
    year,
    week_number,
    cc,
    dcr,
    mc,
    oa,
    infraction,
    reliability_score,
    tenured_da,
    employee_satisfaction_survey,
    dcr_da_off_target,
    cc_da_off_target,
    mc_da_off_target,
    oa_da_off_target,
  } = row;

  return (
    <TableRow hover>
      <TableCell
        colSpan={2}
        className={`${classes.tableCell} ${classes.rightBorderCell}`}
      >
        <Typography>
          {week_number + 1}.{year}
        </Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
        <Typography>{formatNumberType(dcr)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
        <Typography>{formatNumberType(dcr_da_off_target)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
        <Typography>{formatNumberType(cc)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
        <Typography>{formatNumberType(cc_da_off_target)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}>
        <Typography>{formatNumberType(mc)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
        <Typography>{formatNumberType(mc_da_off_target)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell}`}>
        <Typography>{formatNumberType(oa)}</Typography>
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.rightBorderCell}`}>
        <Typography>{formatNumberType(oa_da_off_target)}</Typography>
      </TableCell>
      <TableCell colSpan={2} className={`${classes.tableCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
        <Input
          defaultValue={Math.round(Number(infraction))}
          name={`infraction`}
          onBlur={(e: OnBlurEvent) =>{
              dispatch(
                updateCheckIkeaTableWeek(id, {
                  infraction: e?.target?.value,
                })
              )}
          }
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          inputProps={{
              style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
              "data-testid": "checkPageRowInfractionInput",
          }}
        />
      </TableCell>
      <TableCell colSpan={2} className={`${classes.tableCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
        <Input
          defaultValue={Math.round(Number(reliability_score))}
          name={`reliability_score`}
          onBlur={(e: OnBlurEvent) =>{
              dispatch(
                updateCheckIkeaTableWeek(id, {
                  reliability_score: e?.target?.value,
                })
              )}
          }
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          inputProps={{
              style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
              "data-testid": "checkPageRowReliabilityScoreInput"
          }}
        />
      </TableCell>
      <TableCell colSpan={2} className={`${classes.tableCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
        <Input
          defaultValue={Math.round(Number(tenured_da))}
          name={`tenured_da`}
          onBlur={(e: OnBlurEvent) =>{
              dispatch(
                updateCheckIkeaTableWeek(id, {
                  tenured_da: e?.target?.value,
                })
              )}
          }
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          inputProps={{
              style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
              "data-testid": "checkPageRowTenuredDaInput"
          }}
        />
      </TableCell>
      <TableCell colSpan={2} className={`${classes.tableCell} ${classes.rightBorderCell} ${classes.tableCellPaddingLeft}`}>
        <Input
          defaultValue={Math.round(Number(employee_satisfaction_survey))}
          name={`employee_satisfaction_survey`}
          onBlur={(e: OnBlurEvent) =>{
              dispatch(
                updateCheckIkeaTableWeek(id, {
                  employee_satisfaction_survey: e?.target?.value,
                })
              )}
          }
          onKeyPress={inputNumberValidate}
          onPaste={inputNumberValidateOnPaste}
          inputProps={{
              style: { textAlign: "center", padding: "0 0 1px 0", maxWidth: "50px" },
              "data-testid": "checkPageRowESInput"
          }}
        />
      </TableCell>
      <TableCell
        colSpan={2}
        className={`${classes.tableCell} ${classes.tableCellPaddingLeft}`}
      >
        <IconButton onClick={() => viewCurrentWeekIkeaCheck(id)}>
          <OpenInNewIcon color="primary" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
