export const TABLE_HEADERS_COLUMN = [
  { field: "small", id: "date", label: "Date" },
  { field: "small", id: "dcr", label: "DCR", toolTipLabel: "Delivery Completion Rate" },
  { field: "small", id: "dnr", label: "DNR", toolTipLabel: "" },
  { field: "small", id: "dsb", label: "DSB", toolTipLabel: "Delivery Success Behaviors" },
  { field: "small", id: "pod", label: "POD", toolTipLabel: "Photo On Delivery" },
  { field: "small", id: "violation", label: "VIO", toolTipLabel: "Violation" },
  { field: "small", id: "rd", label: "RG", toolTipLabel: "Rescue Given" },
  { field: "small", id: "mc", label: "MC", toolTipLabel: "Morning Checklist" },
  { field: "small", id: "oa", label: "OA", toolTipLabel: "Ontime Attendance" },
  { field: "small", id: "cc", label: "CC", toolTipLabel: "Contact Compliance" },
  { field: "small", id: "ada", label: "ADA", toolTipLabel: "Attended Delivery Accuracy" },
  { field: "small", id: "whc", label: "WHC", toolTipLabel: "" },
  { field: "small", id: "hp6w", label: "HP6W", toolTipLabel: "High Performer in Weeks" },
  { field: "small", id: "cas", label: "CAS", toolTipLabel: "" },
  { field: "small", id: "es", label: "ES", toolTipLabel: "Employee Satisfaction" },
  { field: "small", id: "activities", label: "" },
];

export const WEEK_TABLE_HEADERS_COLUMN = [
  { field: "small", id: "Sunday", label: "Sunday" },
  { field: "small", id: "Monday", label: "Monday" },
  { field: "small", id: "Tuesday", label: "Tuesday" },
  { field: "small", id: "Wednesday", label: "Wednesday" },
  { field: "small", id: "Thursday", label: "Thursday" },
  { field: "small", id: "Friday", label: "Friday" },
  { field: "small", id: "Saturday", label: "Saturday" },
];
