import { FC } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { CSVRecord, AmazonRecord } from "types/AmazonRecord";
import moment from "moment";
import { useStyles } from "./styles";

interface FileImportTableRowProps {
  row: CSVRecord | AmazonRecord;
  fileName: string;
}

const FileImportTableRow: FC<FileImportTableRowProps> = ({ row, fileName }) => {
  const { id, user, uploaded } = row;

  const classes = useStyles();

  return (
    <TableRow data-testid="testFileImportTableRow" hover>
      <TableCell data-testid="fileImportTableRowId" className={classes.tableCell}>
        <Typography variant="body2">{id}</Typography>
      </TableCell>
      <TableCell data-testid="fileImportTableRowFileName" className={classes.tableCell}>
        <Typography variant="body2">{fileName}</Typography>
      </TableCell>
      <TableCell data-testid="fileImportTableRowEmail" className={classes.tableCell}>
        <Typography variant="body2">{user.email}</Typography>
      </TableCell>
      <TableCell data-testid="fileImportTableRowDate" className={classes.tableCell}>
        <Typography variant="body2">{moment(uploaded).format("MMMM Do, yyyy")}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default FileImportTableRow;
