import { FC } from "react";
import { DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { Colors } from "constants/constants";
import { useDispatch } from "react-redux";
import { closeModal } from "actions/modalActions";
import { useDialogStyles } from "../styles";
import { DialogButton } from "components/buttons/DialogButton";

interface ShiftDialogProps {
  onSubmit?: () => void;
}

interface ShiftDialogDataProps {
  data?: {
    report_id: number;
  };
}

export const DeleteShiftDialog: FC<ShiftDialogProps> = ({ onSubmit }) => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const handleSubmit = () => {
    onClose();
    if (onSubmit) onSubmit();
  };

  return (
    <>
      <DialogActions data-testid="shiftDeleteModal" className={classes.dialogActions}>
        <DialogButton
          testId={"cancelDeleteDialogButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={"deleteDeleteDialogButton"}
          buttonName={"Delete"}
          onClick={handleSubmit}
          customStyle={{ background: Colors.RED, color: "#fff" }}
        />
      </DialogActions>
    </>
  );
};

export const SendDriversMessageDialog: FC<ShiftDialogProps> = ({ onSubmit }) => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());
  const handleSubmit = () => {
    onClose();
    if (onSubmit) onSubmit();
  };

  return (
    <>
      <DialogContent data-testid="shiftSendDriversMessageModal">
        <DialogContentText>
          Are you sure you want to notify the drivers from this list that changes was confirmed?
          <br />
          They will receive an SMS with a notification.
        </DialogContentText>
      </DialogContent>
      <DialogActions
        data-testid="shiftSendDriversMessageModalActions"
        className={classes.dialogActions}
      >
        <DialogButton
          testId={"cancelSendDriversMessageDialogButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          customStyle={{ background: Colors.GRAY }}
        />
        <DialogButton
          testId={"notigySendDriversMessageDialogButton"}
          buttonName={"Notify"}
          onClick={handleSubmit}
          customStyle={{ background: Colors.BLUE, color: "#fff" }}
        />
      </DialogActions>
    </>
  );
};

export const NoVansDialog: FC = () => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const handleSubmit = () => {
    onClose();
  };

  return (
    <>
      <DialogActions data-testid="shiftNoVansModal" className={classes.dialogActions}>
        <DialogButton
          testId={"okNoVansDialogButton"}
          buttonName={"OK"}
          onClick={handleSubmit}
          customStyle={{ background: Colors.BLUE, color: "#fff" }}
        />
      </DialogActions>
    </>
  );
};

export const DuplicateVansDialog: FC<ShiftDialogDataProps> = ({ data }) => {
  const classes = useDialogStyles();
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  return (
    <>
      <DialogActions data-testid="shiftDuplicateVansModal" className={classes.dialogActions}>
        <Button
          data-testid="okDuplicateVansDialogButton"
          onClick={onClose}
          style={{ background: Colors.BLUE, color: "#fff" }}
        >
          OK
        </Button>
        {data?.report_id && (
          <Button
            data-testid="goToReportDuplicateVansDialogButton"
            onClick={onClose}
            style={{ background: Colors.MAIN, color: "#fff" }}
            href={`/das-reports/${data?.report_id}`}
            target="_blank"
          >
            Go to report
          </Button>
        )}
      </DialogActions>
    </>
  );
};
