import { KeyboardEvent, ClipboardEvent } from "react";

export const ellipsis = (text: string, limit = 40) => {
  if (text && text.length) {
    return text.length > limit ? `${text.slice(0, limit).trim()}...` : text;
  }
  return "";
};

export const inputNumberValidate = (event: KeyboardEvent) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const inputNumberValidateOnPaste = (event: ClipboardEvent) => {
  const value = event.clipboardData.getData("text");
  if (!/^[0-9]*$/.test(value)) {
    event.preventDefault();
  }
};
