import { FC, useState } from "react";
import {
  DialogContent,
  DialogActions,
  Typography,
  Button,
  FormLabel,
  Box,
  IconButton,
} from "@material-ui/core";
import PdfIcon from "icon/pdfIcon.png";
import ClearIcon from "@material-ui/icons/Clear";
import { ellipsis } from "utils/formats";
import { closeModal } from "actions/modalActions";
import { useDispatch } from "react-redux";
import { useDialogStyles } from "../styles";
import api from "api";
import { customToast } from "hooks/toast";
import { DialogButton } from "components/buttons/DialogButton";

interface UploadReliabilityFileDialogContentProps {}

export const UploadReliabilityFileDialog: FC<UploadReliabilityFileDialogContentProps> = () => {
  const classes = useDialogStyles();
  const [file, setFile] = useState<File | null>(null);
  const [page, setPage] = useState<string>("");
  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModal());

  const handleClose = () => {
    setFile(null);
    setPage("");
    onClose();
  };

  const onClear = () => {
    setFile(null);
  };

  const onFileSubmit = (file: File | null, page_numbers: string) => {
    if (file != null) {
      handleClose();
      const request: any = new FormData();
      request.append("pdf_file", file);
      request.append("page_numbers", page_numbers);
      api.uploadReliabilityPdf(request).then((res) => {
        if (res.data) {
          customToast("success", "PDF file was uploaded!");
        }
      });
    }
  };

  return (
    <>
      <DialogContent
        data-testid="uploadReliabilityFileDialogModal"
        style={{
          minHeight: 200,
          minWidth: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <FormLabel htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            accept="application/pdf"
            onChange={(e) => {
              if (e?.target?.files?.[0]) {
                setFile(e?.target?.files?.[0]);
              }
            }}
          />
          <Button
            fullWidth
            className="btn-choose"
            component="span"
            color="primary"
            variant="contained"
          >
            Choose File
          </Button>
        </FormLabel>
        {file ? <FileCard file={file} onClear={onClear} /> : null}
      </DialogContent>
      <DialogActions
        data-testid="uploadReliabilityFileDialogModalActions"
        className={classes.dialogActions}
      >
        <DialogButton
          testId={"cancelUploadReliabilityFileButton"}
          buttonName={"Cancel"}
          onClick={onClose}
          variant="contained"
          color="secondary"
        />
        <DialogButton
          testId={"uploadUploadReliabilityFileButton"}
          buttonName={"Upload"}
          onClick={() => onFileSubmit(file, page)}
          variant="contained"
          color="primary"
          disabled={file === null}
        />
      </DialogActions>
    </>
  );
};

const FileCard = ({ file, onClear }: { file: File; onClear: () => void }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={PdfIcon} height="40" alt="logo" />
        <Box sx={{ mx: 2 }}>
          <Typography>
            {ellipsis(file.name)} ({Number(file.size / 1000000).toFixed(2)} MB)
          </Typography>
        </Box>
      </Box>
      <IconButton onClick={onClear}>
        <ClearIcon />
      </IconButton>
    </Box>
  );
};
