import { TableCell, TableRow, Typography } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { DailyCheck } from "types/Check";
import { useStyles } from "./styles";
import { useDispatch } from "react-redux";
import { updateCheckTableWeeklyDay } from "actions/checkActions";
import { WEEK_TABLE_HEADERS_COLUMN } from "./checkTable";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { SUPERADMIN } from "constants/constants";
import { CheckTableCellPaint } from "components/elements/CheckTableCellPaint";
import { RenderDayInfo } from "./RenderDayInfo";

interface CheckWeekPageRowProps {
  label: string;
  name: string;
  data: DailyCheck[] | [];
  weekData: { score: number | null | undefined; target: number | null | undefined };
  globalTarget: { name: string; target: string | number | undefined | null };
}

export const CheckWeekPageRow: FC<CheckWeekPageRowProps> = ({
  label,
  name,
  data,
  weekData,
  globalTarget,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userData } = useTypeSelectors((state) => state.auth);
  const [paramsWeek, setParamsWeek] = useState({
    alertClassName: classes.greenAlert,
    className: classes.greenCell,
    severity: "success",
  });

  const handleChangeData = (id: number, name: string, value: number) =>
    dispatch(
      updateCheckTableWeeklyDay(id, {
        [name]: value,
      }),
    );

  useEffect(() => {
    if (painWeekCells(globalTarget?.name, globalTarget?.target, weekData?.score)) {
      setParamsWeek({
        alertClassName: classes.redAlert,
        className: classes.redCell,
        severity: "error",
      });
    }
  }, [
    globalTarget?.name,
    globalTarget?.target,
    weekData?.score,
    classes.redAlert,
    classes.redCell,
  ]);

  return (
    <TableRow hover>
      <TableCell
        className={classes.tableCell}
        style={{
          cursor: "default",
          ...((label === "OA" || label === "HP6W") && { borderBottom: "3px solid lightGrey" }),
        }}
      >
        <Typography>{label}</Typography>
      </TableCell>
      <TableCell
        style={{
          cursor: "default",
          textAlign: "end",
          ...((label === "OA" || label === "HP6W") && { borderBottom: "3px solid lightGrey" }),
        }}
        className={classes.rightBorderCell}
      >
        <Typography>{getGlobalTargetValue(globalTarget)}</Typography>
      </TableCell>
      {WEEK_TABLE_HEADERS_COLUMN.map((el) =>
        RenderDayInfo(
          name,
          el.id,
          data,
          globalTarget,
          handleChangeData,
          userData.role !== SUPERADMIN,
        ),
      )}
      <TableCell
        className={`${classes.tableCellWeekName}`}
        style={{
          ...((label === "OA" || label === "HP6W") && { borderBottom: "3px solid lightGrey" }),
        }}
      >
        <CheckTableCellPaint
          alertBackgoundStyle={paramsWeek.alertClassName}
          alertStyle={paramsWeek.severity}
          typographyStyle={paramsWeek.className}
          value={weekData?.score}
          conditionDay={true}
          isNumber
          isBold
        />
      </TableCell>
      <TableCell
        className={`${classes.tableCellWeekName} ${classes.rightBorderCell}`}
        style={{
          textAlign: "end",
          ...((label === "OA" || label === "HP6W") && { borderBottom: "3px solid lightGrey" }),
        }}
      >
        <CheckTableCellPaint
          alertBackgoundStyle={paramsWeek.alertClassName}
          alertStyle={paramsWeek.severity}
          typographyStyle={paramsWeek.className}
          value={weekData?.target}
          conditionDay={true}
          isNumber
          isBold
        />
      </TableCell>
    </TableRow>
  );
};

const getGlobalTargetValue = (data: {
  name: string;
  target: string | number | undefined | null;
}) => {
  switch (data.name) {
    case "cc":
    case "dcr":
    case "pod":
      return `>${data?.target}%`;
    case "dnr":
    case "dsb":
    case "rd":
      return `>${data?.target}`;
    case "ada":
    case "violation":
    case "whc":
    case "mc":
    case "oa":
    case "cas":
    case "es":
      return `${data?.target}%`;
    case "hp6w":
      return `>${data?.target}`;
    default:
      return null;
  }
};

const painWeekCells = (
  name: string,
  target: string | number | undefined | null,
  value: string | number | undefined | null,
) => {
  switch (name) {
    case "cc":
    case "dcr":
    case "pod":
    case "ada":
    case "violation":
    case "whc":
    case "mc":
    case "oa":
    case "cas":
    case "es":
    case "hp6w":
      return Number(target) > Number(value);
    case "dnr":
    case "dsb":
      return Number(value) >= Number(target);
    case "rd":
      return Number(value) < Number(target);
    default:
      return false;
  }
};
