import { FormControl, TextField, Box } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./styles";
import {
  updateIkeaCheckTableRangeDates,
  removeIkeaCheckTableRangeDates,
} from "actions/ikeaCheckActions";
import { OnChangeEvent } from "types/general";

interface CheckDateSelectorProps {
  date_one: string;
  date_two: string;
}

export const IkeaCheckDateSelector: FC<CheckDateSelectorProps> = ({ date_one, date_two }) => {
  const [dateOne, setIdOne] = useState<string>(date_one || "");
  const [dateTwo, setIdTwo] = useState<string>(date_two || "");
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (dateOne && dateTwo) {
      dispatch(updateIkeaCheckTableRangeDates(dateOne, dateTwo));
    } else {
      dispatch(removeIkeaCheckTableRangeDates());
    }
  }, [dateOne, dateTwo, dispatch]);

  return (
    <Box className={classes.selectors}>
      <FormControl fullWidth style={{ height: 50 }}>
        <TextField
          label="From:"
          variant="standard"
          name="week_one"
          type="week"
          InputLabelProps={{ shrink: true }}
          value={dateOne}
          onChange={(e: OnChangeEvent) => {
            setIdOne(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth style={{ height: 50 }}>
        <TextField
          label="To:"
          variant="standard"
          name="week_two"
          type="week"
          InputLabelProps={{ shrink: true }}
          value={dateTwo}
          onChange={(e: OnChangeEvent) => {
            setIdTwo(e.target.value);
          }}
        />
      </FormControl>
    </Box>
  );
};
