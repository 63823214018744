import { Box, TextField, IconButton, Typography } from "@material-ui/core";
import moment from "moment";
import { FC } from "react";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

interface DatePickWithArrowType {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  changeDay: (val: string) => void;
  displayWeek: boolean;
}

export const DatePickWithArrow: FC<DatePickWithArrowType> = ({
  value,
  onChange,
  changeDay,
  displayWeek,
}) => {
  const reduceDay = () => {
    const newDay = moment(value).subtract(1, "days").format("yyyy-MM-DD");
    changeDay(newDay);
  };

  const increaseDay = () => {
    const newDay = moment(value).add(1, "days").format("yyyy-MM-DD");
    changeDay(newDay);
  };

  return (
    <Box data-testid="testDatePickWithArrow" style={{ display: "flex", alignItems: "center" }}>
      <IconButton onClick={reduceDay}>
        <ArrowLeftIcon />
      </IconButton>
      <TextField
        inputProps={{ style: { fontSize: 14 }, "data-testid": "testDatePickWithArrowInput" }}
        name="day"
        type="date"
        value={value}
        onChange={onChange}
      />
      <IconButton onClick={increaseDay}>
        <ArrowRightIcon />
      </IconButton>
      {displayWeek && <Typography>W: {moment(value).weekday(1).format("W")}</Typography>}
    </Box>
  );
};
