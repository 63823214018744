import { useState, ChangeEvent } from "react";

export const useTableFilters = (
  defaultSort: string,
  availableSort: string[],
  externalRowsPerPage?: number,
  externalSetRowsPerPage?: (data: number) => void,
) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(externalRowsPerPage || 10);
  const [sort, setSort] = useState(`-${defaultSort}`);

  const onPageChange = (_: unknown, value: number) => {
    setPage(value);
  };

  const onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    if (externalSetRowsPerPage) externalSetRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onSortChange = (value: string) => {
    if (availableSort.includes(value)) {
      if (value === sort) setSort(`-${value}`);
      else setSort(value);
    }
  };

  return {
    page,
    setPage: onPageChange,
    rowsPerPage,
    setRowsPerPage: onRowsPerPageChange,
    sort,
    setSort: onSortChange,
  };
};
