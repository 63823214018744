import { ReducersInterfaceAction } from "types/Reducers";
import { PerfomanceConstants } from "../constants/constants";

import { initialPerformanceState } from "store/initialStates";
import { PerfomanceState } from "types/Performance";

export const perfomanceReducer = (
  state = initialPerformanceState,
  action: ReducersInterfaceAction,
): PerfomanceState => {
  switch (action.type) {
    case PerfomanceConstants.PERFORMANCE_UPDATE_SETTINGS:
      return {
        ...state,
        perfomanceSettings: { ...state.perfomanceSettings, ...action.payload },
      };
    case PerfomanceConstants.PERFORMANCE_SET_DAY:
      return {
        ...state,
        perfomanceSettings: { ...state.perfomanceSettings, date: action.payload },
      };
    case PerfomanceConstants.PERFORMANCE_SET_SELECT_TYPE:
      return {
        ...state,
        perfomanceSettings: { ...state.perfomanceSettings, selectType: action.payload },
      };
    case PerfomanceConstants.PERFORMANCE_SET_CURRENT_STATION:
      return {
        ...state,
        perfomanceSettings: { ...state.perfomanceSettings, station: action.payload as string },
      };
    default:
      return state;
  }
};
