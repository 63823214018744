import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  IconButton,
  Icon,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import { openModal, closeModal } from "actions/modalActions";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDialogStyles } from "components/modals/styles";
import { useStyles } from "./styles";

interface ModalWindowProps {
  children: any;
  modal_name: string;
  buttonName?: string;
  modalTitle?: string;
  modalDescription?: string;
  modalText?: string;
  theme?: string;
  isIconButton?: boolean;
  hasIcon?: boolean;
  icon?: JSX.Element | undefined;
  isDisabled?: boolean;
  isModalButton?: boolean;
  noButton?: boolean;
}

interface TextModalButtonProps {
  buttonText: string | undefined;
  isModalButton?: boolean;
  onClick: () => void;
  isDisabled?: boolean | undefined;
  extraClass?: string;
}

interface IconModalButtonProps {
  icon: JSX.Element | undefined;
  onClick: () => void;
  modalParam: {
    colorClass: string;
    backgroundClass: string;
    severity: string;
  };
}

export const ModalWindow: FC<ModalWindowProps> = ({
  children,
  modal_name,
  buttonName,
  modalTitle,
  modalDescription,
  modalText,
  theme,
  isIconButton,
  hasIcon,
  icon,
  isDisabled,
  isModalButton,
  noButton,
}) => {
  const classes = useStyles();

  const [modalParam, setModalParam] = useState({
    colorClass: classes.blueColor,
    backgroundClass: classes.blueBackground,
    severity: "info",
  });

  useEffect(() => {
    switch (theme) {
      case "green":
        setModalParam({
          colorClass: classes.greenColor,
          backgroundClass: classes.greenBackground,
          severity: "success",
        });
        break;
      case "yellow":
        setModalParam({
          colorClass: classes.yellowColor,
          backgroundClass: classes.yellowBackground,
          severity: "wanring",
        });
        break;
      case "red":
        setModalParam({
          colorClass: classes.redColor,
          backgroundClass: classes.redBackground,
          severity: "danger",
        });
        break;
      default:
        setModalParam({
          colorClass: classes.blueColor,
          backgroundClass: classes.blueBackground,
          severity: "info",
        });
    }
  }, [
    theme,
    classes.blueBackground,
    classes.blueColor,
    classes.greenBackground,
    classes.greenColor,
    classes.redBackground,
    classes.redColor,
    classes.yellowBackground,
    classes.yellowColor,
  ]);

  const dispatch = useDispatch();

  const { isOpen, modalName } = useTypeSelectors((state) => state.modalState);

  const triggerOpenModal = () => dispatch(openModal(modal_name));

  return (
    <>
      {!noButton &&
        (!isIconButton ? (
          <TextModalButton
            buttonText={buttonName || modalTitle}
            isModalButton={isModalButton}
            onClick={triggerOpenModal}
            isDisabled={isDisabled}
            extraClass={theme === "red" ? classes.deleteBtn : ""}
          />
        ) : (
          <IconModalButton icon={icon} onClick={triggerOpenModal} modalParam={modalParam} />
        ))}
      <Dialog
        className={classes.root}
        open={isOpen && modalName === modal_name}
        onClose={() => dispatch(closeModal())}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle}>
          {hasIcon && (
            <Alert
              severity={modalParam.severity as Color}
              icon={false}
              className={`${classes.iconBox} ${modalParam.colorClass} ${modalParam.backgroundClass}`}
            >
              <Icon style={{ fontSize: 60 }} className={classes.modalIcon}>
                {icon}
              </Icon>
            </Alert>
          )}
          <Typography
            className={`${classes.title} ${classes.iconBtn} ${modalParam.colorClass}`}
            variant="h4"
            id="alert-dialog-title"
          >
            {modalTitle}
          </Typography>
          <Typography
            className={`${classes.subTitle} ${modalParam.colorClass}`}
            variant="h5"
            id="alert-dialog-description"
          >
            {modalDescription}
          </Typography>
        </DialogTitle>

        {modalText && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{modalText}</DialogContentText>
          </DialogContent>
        )}

        {children}
      </Dialog>
    </>
  );
};

export const TextModalButton: FC<TextModalButtonProps> = ({
  buttonText,
  isModalButton,
  onClick,
  isDisabled,
  extraClass,
}) => {
  const dialogClasses = useDialogStyles();
  const classes = useStyles();

  return (
    <Button
      data-testid="triggerTextButtonToOpenModal"
      className={`${classes.button} ${isModalButton && dialogClasses.actionButton} ${extraClass}`}
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
};

export const IconModalButton: FC<IconModalButtonProps> = ({ icon, onClick, modalParam }) => {
  const classes = useStyles();

  return (
    <IconButton
      data-testid="triggerIconButtonToOpenModal"
      className={`${classes.iconBtn} ${modalParam?.colorClass} ${modalParam?.backgroundClass}`}
      onClick={onClick}
      color="primary"
    >
      <Icon>{icon}</Icon>
    </IconButton>
  );
};
