import { FC, useState } from "react";
import { ButtonProps } from "@material-ui/core";
import { useStyles } from "./styles";
import { OpenButton } from "./Buttons";
import { ImportFileDrawer } from "components/drawers/ImportFileDrawer/ImportFileDrawer";

interface ImportFileButtonType extends ButtonProps {}

export const ImportFileButton: FC<ImportFileButtonType> = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <>
      <OpenButton
        buttonText={"Upload File"}
        variant={"contained"}
        className={classes.buttonClass}
        color={"primary"}
        onClick={onOpen}
      />
      <ImportFileDrawer open={open} onClose={onClose}/>
    </>
  );
};
