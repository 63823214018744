import { CheckIkeaTableConstants } from "constants/constants";
import { IkeaCheckState } from "types/IkeaCheck";
import { initialIkeaCheckState } from "store/initialStates";
import { ReducersInterfaceAction } from "types/Reducers";

export const checkIkeaReducer = (
  state = initialIkeaCheckState,
  action: ReducersInterfaceAction,
): IkeaCheckState => {
  switch (action.type) {
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload?.results,
        dataListCount: action.payload?.count,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEKLY_INFO_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        dataTableRowsPerPage: action.payload,
      };

    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_LOADING:
      return {
        ...state,
        currentWeekLoading: true,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_SUCCESS:
      return {
        ...state,
        currentWeekLoading: false,
        currentWeekData: action.payload,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_GET_WEEK_FULL_INFO_ERROR:
      return {
        ...state,
        currentWeekLoading: false,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_LOADING:
      return {
        ...state,
        changeDataLoading: true,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_SUCCESS:
      return {
        ...state,
        changeDataLoading: false,
        currentWeekData: action.payload,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_WEEKLY_INFO_ERROR:
      return {
        ...state,
        changeDataLoading: false,
      };
    case CheckIkeaTableConstants.IKEA_CHECK_TABLE_UPDATE_CAS_ES:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload,
      };
    case CheckIkeaTableConstants.IKEA_UPDATE_CHECK_TABLE_AVERAGE_SCRORE_DATA:
      return {
        ...state,
        avergeScoreData: action.payload,
      };
    case CheckIkeaTableConstants.IKEA_SET_CHECK_TABLE_FILTERS:
      return {
        ...state,
        reportListFilters: { ...state.reportListFilters, ...action.payload },
      };
    default:
      return state;
  }
};
