import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box, Typography, Tooltip as TooltipMui } from "@material-ui/core";
import { Colors, ScorecardConstants } from "constants/constants";
import { handleGetVans } from "../requests";
import { StationSelect } from "components/elements/StationSelect";
import { SearchTypeSelect } from "components/elements/SearchTypeSelect";
import { MonthSelect } from "components/elements/MonthSelect";
import { WeekSelect } from "components/elements/WeekSelect";
import { useDateWeekSelector } from "hooks/useDateWeekSelector";
import { useStyles } from "./styles";
import { ScorecardOpenButton } from "components/buttons/ScorecardOpenButton";
import { ScorecardCompareButton } from "components/buttons/ScorecardCompareButton";
import { useTypeSelectors } from "hooks/useTypeSelectors";
import { useDispatch } from "react-redux";
import {
  ScorecardExternalSetDate,
  ScorecardSetSelectType,
  ScorecardSetStation,
} from "actions/scorecardActions";
import { YearSelect } from "components/elements/YearSelect";
import { DatePickWithArrow } from "components/elements/DatePickWithArrow";

import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import { FleetTableModalTooltip } from "components/modals/FleetTableModalTooltip";

interface Data {
  vans_data: {
    van__number: number;
    counter: number;
    van__van_group__fleet_ownership: number | null;
  }[];
  number_rental_vans: string;
  number_owned_vans: string;
  number_shifts_for_rental_vans: string;
  number_shifts_for_owned_vans: string;
  number_unregister_vans: string;
  unregister_vans: { number: number; counter: number }[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const TopTenVans = ({ withModal = true, withCompare = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useTypeSelectors((state) => state.auth);
  const { externalSettingActive, externalSettings, vansSettings } = useTypeSelectors(
    (state) => state.scoreCardSettings
  );
  const defaultStationCondition = userData?.station_spots.some(
    (el: { station: { title: string; id: number } }) => el.station.id === 1
  )
    ? 1
    : userData?.station_spots[0]?.station?.id;
  const [compareStation, setCompareStation] = useState(`${defaultStationCondition}`);
  const [requestData, setRequestData] = useState<Data | null>(null);

  const handleChangeStation = (data: string) =>
    !withCompare
      ? setCompareStation(data)
      : dispatch(ScorecardSetStation(data, ScorecardConstants.VANS_SET_CURRENT_STATION));
  const handleChangeSelectType = (select: string) =>
    dispatch(ScorecardSetSelectType(select, ScorecardConstants.VANS_SET_SELECT_TYPE));
  const handleChangeWeek = (week: string) =>
    dispatch(
      ScorecardExternalSetDate({ ...vansSettings?.date, week }, ScorecardConstants.VANS_SET_DATE)
    );
  const handleChangeMonth = (month: string) =>
    dispatch(
      ScorecardExternalSetDate({ ...vansSettings?.date, month }, ScorecardConstants.VANS_SET_DATE)
    );
  const handleChangeYear = (year: string) =>
    dispatch(
      ScorecardExternalSetDate({ ...vansSettings?.date, year }, ScorecardConstants.VANS_SET_DATE)
    );

  const handleChangeDayDate = (date: string) =>
    dispatch(
      ScorecardExternalSetDate(
        { ...vansSettings?.date, day: date },
        ScorecardConstants.VANS_SET_DATE
      )
    );

  const conditionLineColor = () => {
    if (selectType === "month") return 20;
    if (selectType === "week") return 5;
    if (selectType === "annual") return 240;
  };

  const {
    week,
    month,
    year,
    date,
    selectType,
    onTypeChange,
    dateCondition,
    dateConditionMonth,
    onWeekReduce,
    onWeekIncrease,
    onMonthReduce,
    onMonthIncrease,
    onYearReduce,
    onYearIncrease,
    dateConditionYear,
    onDateChange,
    onDateChangeByDay,
  } = useDateWeekSelector({
    defaultWeek: vansSettings?.date?.week,
    defaultMonth: vansSettings?.date?.month,
    defaultYear: vansSettings?.date?.year,
    defaultSelectType: vansSettings?.selectType,
    onSelectTypeSet: handleChangeSelectType,
    onWeekSet: handleChangeWeek,
    onMonthSet: handleChangeMonth,
    onYearSet: handleChangeYear,
    onDateSet: handleChangeDayDate,
  });

  const conditionLocalStation = !withCompare ? compareStation : vansSettings?.station;

  useEffect(() => {
    if (
      (conditionLocalStation && (month || week || year || date)) ||
      (externalSettings?.station && externalSettings?.selectType && externalSettings?.date)
    ) {
      const params = externalSettingActive
        ? {
            station: externalSettings?.station,
            ...(externalSettings?.selectType === "month" && {
              month: +externalSettings?.date?.month,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "week" && {
              week: +externalSettings?.date?.week,
              year: +externalSettings?.date?.year,
            }),
            ...(externalSettings?.selectType === "day" && { day: externalSettings?.date?.day }),
          }
        : {
            station: conditionLocalStation,
            year: year,
            ...(selectType === "month" && { year, month }),
            ...(selectType === "week" && { year, week }),
            ...(selectType === "annual" && { year }),
            ...(selectType === "day" && { day: date }),
          };
      handleGetVans(params, setRequestData);
    }
  }, [
    vansSettings?.station,
    year,
    month,
    week,
    date,
    selectType,
    externalSettingActive,
    externalSettings?.station,
    externalSettings?.selectType,
    externalSettings?.date,
    conditionLocalStation,
  ]);

  const labelsData =
    requestData?.vans_data && requestData?.vans_data?.length > 20
      ? requestData?.vans_data
          .filter((el: { van__number: string | number }, index) =>
            index < 10 || index >= requestData?.vans_data.length - 11 ? el.van__number : null
          )
          .reverse()
      : requestData?.vans_data
          .filter((el: { van__number: string | number }, index) =>
            index < Math.round(requestData?.vans_data?.length / 2) ||
            index >
              requestData?.vans_data?.length - (Math.round(requestData?.vans_data?.length / 2) + 1)
              ? el.van__number
              : null
          )
          .reverse();
  const labels = labelsData
    ? labelsData.map((el: { van__number: string | number }) => el.van__number)
    : [];

  const checkVanOwner = (val: number | null) => {
    switch (val) {
      case null:
        return Colors.PASTEL_YELLOW;
      case 1:
        return Colors.PASTEL_PINE_TREE;
      case 2:
        return Colors.BLUE;
    }
  };

  const dataChart = {
    labels,
    datasets: [
      {
        label: `Counter`,
        data: labelsData
          ? labelsData.map(
              (
                el: {
                  counter: number;
                  van__number: string | number;
                },
                index
              ) => (el.van__number === labels[index] ? el.counter : null)
            )
          : [],
        backgroundColor:
          labelsData &&
          labelsData.map(
            (
              el: {
                van__number: string | number;
                van__van_group__fleet_ownership: number | null;
              },
              index
            ) =>
              el.van__number === labels[index]
                ? checkVanOwner(el.van__van_group__fleet_ownership)
                : Colors.LIGHT_BLUE
          ),
        minBarLength: 3,
        barThickness: 15,
        borderRadius: 25,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: false,
          text: "Value",
        },
        min: 0,
        grid: {
          //@ts-ignore
          color: (context) => {
            if (context.tick.value === conditionLineColor()) return `${Colors.RED}`;
            else return "lightgrey";
          },
          borderColor: "transparent",
          tickColor: "transparent",
        },
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
      x: {
        ticks: {
          color: `${Colors.MAIN}`,
          font: {
            weight: "700",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: "Fleet Utilization",
      },
      quadrants: {
        topLeft: "rgb(207,226,243, 0.7)",
        topRight: "rgb(255,186,186, 0.3)",
      },
    },
  };

  return (
    <Box className={classes.root}>
      {!externalSettingActive && (
        <Box className={classes.head}>
          <Box className={classes.box}>
            <StationSelect
              value={!withCompare ? compareStation : vansSettings?.station}
              onChange={handleChangeStation}
              stations={userData?.station_spots}
            />
            <SearchTypeSelect
              value={selectType}
              onChange={onTypeChange}
              options={[
                { id: "week", title: "Week" },
                { id: "month", title: "Month" },
                { id: "annual", title: "Annual" },
                { id: "day", title: "Day" },
              ]}
            />
            {selectType === "month" && (
              <MonthSelect
                month={month}
                year={year}
                onNext={onMonthIncrease}
                onPrev={onMonthReduce}
                disabled={dateConditionMonth()}
              />
            )}
            {selectType === "week" && (
              <WeekSelect
                week={week}
                year={year}
                onNext={onWeekIncrease}
                onPrev={onWeekReduce}
                disabled={dateCondition()}
              />
            )}
            {selectType === "annual" && (
              <YearSelect
                year={year}
                onNext={onYearIncrease}
                onPrev={onYearReduce}
                disabled={dateConditionYear()}
              />
            )}
            {selectType === "day" && (
              <DatePickWithArrow
                value={date}
                onChange={onDateChange}
                changeDay={onDateChangeByDay}
                displayWeek
              />
            )}
          </Box>
          <Box>
            {withCompare && (
              <ScorecardCompareButton
                children={<TopTenVans withModal={false} withCompare={false} />}
                children2={<TopTenVans withModal={false} withCompare={false} />}
              />
            )}
            {withModal && <ScorecardOpenButton children={<TopTenVans withModal={false} />} />}
          </Box>
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Typography variant="body2">Fleet Utilization</Typography>
        <Box style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "14px",
                height: "14px",
                background: Colors.PASTEL_YELLOW,
                display: "block",
                borderRadius: "5px",
              }}
            />
            <Typography variant="body2">Unregister</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "14px",
                height: "14px",
                background: Colors.PASTEL_PINE_TREE,
                display: "block",
                borderRadius: "5px",
              }}
            />
            <Typography variant="body2">Rental</Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "14px",
                height: "14px",
                background: Colors.BLUE,
                display: "block",
                borderRadius: "5px",
              }}
            />
            <Typography variant="body2">Owned</Typography>
          </Box>
        </Box>
        <FleetTableModalTooltip
          requestDataTooltip={requestData}
          selectType={selectType}
          station={conditionLocalStation}
          week={week}
          month={month}
          year={year}
        />
        <TooltipMui
          title={
            <Box>
              <Typography>Unregister Vans List</Typography>
              {requestData?.unregister_vans.map((el) => (
                <Typography>
                  {el?.number}: <b>{el?.counter}</b>
                </Typography>
              ))}
            </Box>
          }
        >
          <LocalShippingOutlinedIcon style={{ width: "20px", height: "20px" }} />
        </TooltipMui>
      </Box>
      <Bar options={options} data={dataChart} />
    </Box>
  );
};
