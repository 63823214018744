import { initialIkeaActState } from "store/initialStates";
import { IkeaActState } from "types/IkeaAct";
import { ReducersInterfaceAction } from "types/Reducers";
import { IkeaActTableConstants } from "constants/constants";

export const actIkeaReducer = (
  state = initialIkeaActState,
  action: ReducersInterfaceAction,
): IkeaActState => {
  switch (action.type) {
    case IkeaActTableConstants.IKEA_ACT_TABLE_GET_INFO_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload?.results,
        dataListCount: action.payload?.count,
      };
    case IkeaActTableConstants.IKEA_ACT_TABLE_GET_INFO_LOADING:
      return {
        ...state,
        loadingData: true,
      };
    case IkeaActTableConstants.IKEA_ACT_TABLE_GET_INFO_ERROR:
      return {
        ...state,
        loadingData: false,
      };
    case IkeaActTableConstants.IKEA_ACT_TABLE_UPDATE_INFO_SUCCESS:
      return {
        ...state,
        loadingData: false,
        dataList: action.payload,
      };
    case IkeaActTableConstants.IKEA_ACT_TABLE_CHANGE_ROWS_PER_PAGE:
      return {
        ...state,
        dataTableRowsPerPage: action.payload,
      };
    case IkeaActTableConstants.SET_IKEA_ACT_TABLE_FILTERS:
      return {
        ...state,
        reportListFilters: { ...state.reportListFilters, ...action.payload },
      };
    default:
      return state;
  }
};
